import {
  ADD_LIBRARY_DETAIL_REQUEST,
  ADD_LIBRARY_DETAIL_SUCCESS,
  ADD_LIBRARY_DETAIL_FAILURE,
} from "../../action/actionType";
import { db, auth } from "../../firebase/firebase.auth.config";
import { doc, setDoc, getDoc } from "firebase/firestore";
import toast from "react-hot-toast";

const addLibraryDetailRequest = () => ({
  type: ADD_LIBRARY_DETAIL_REQUEST,
});

const addLibraryDetailSuccess = (libraryDetails) => ({
  type: ADD_LIBRARY_DETAIL_SUCCESS,
  payload: libraryDetails,
});

const addLibraryDetailFailure = (error) => ({
  type: ADD_LIBRARY_DETAIL_FAILURE,
  payload: error,
});

export const addLibraryDetailAction =
  (libraryDetails, successCb, errorCb) => async (dispatch) => {
    dispatch(addLibraryDetailRequest());
    try {
      const user_uid = localStorage.getItem("user_uid");
      const userDoc = doc(db, "owner", user_uid);
      const docSnap = await getDoc(userDoc);
      var docData = docSnap.data();
      docData.library = { ...libraryDetails, status: true };
      await setDoc(userDoc, docData);
      successCb(libraryDetails);
      dispatch(addLibraryDetailSuccess(libraryDetails));
     } catch (error) {
      errorCb(error);
      dispatch(addLibraryDetailFailure(error.message));
      toast.error("Failed to add details");
    }
  };
