import {
  SEND_OTP_REQUEST,
  SEND_OTP_SUCCESS,
  SEND_OTP_FAILURE,
  VERIFY_OTP_REQUEST,
  VERIFY_OTP_SUCCESS,
  VERIFY_OTP_FAILURE,
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
  LOGOUT_FAILURE,
  AUTH_CHECK_REQUEST,
} from "../actionType";
import { db, auth } from "../../firebase/firebase.auth.config";
import {
  doc,
  setDoc,
  getDoc,
  updateDoc,
  arrayUnion,
  serverTimestamp,
} from "firebase/firestore";
import {
  RecaptchaVerifier,
  signInWithPhoneNumber,
  signOut,
} from "firebase/auth";
import { toast } from "react-hot-toast";
import { v4 as uuidv4 } from "uuid";
import { libraryPreDefinedTerms } from "../../util/utils";

const drt = {
  display: true,
  required: true,
};
const drf = {
  display: true,
  required: false,
};
const dfr = {
  display: false,
  required: false,
};

// Step 1: Send OTP
export const sendOtp = (phoneNumber) => async (dispatch) => {
  dispatch({ type: SEND_OTP_REQUEST });

  try {
    // Check if reCAPTCHA has already been rendered, if so, use the existing one.
    if (!window.recaptchaVerifier) {
      window.recaptchaVerifier = new RecaptchaVerifier(
        "recaptcha-container",
        { size: "invisible" },
        auth
      );
    }

    const appVerifier = window.recaptchaVerifier;
    const confirmationResult = await signInWithPhoneNumber(
      auth,
      phoneNumber,
      appVerifier
    );
    window.confirmationResult = confirmationResult;

    dispatch({ type: SEND_OTP_SUCCESS });
    toast.success("OTP sent successfully!");
  } catch (error) {
    dispatch({ type: SEND_OTP_FAILURE, payload: error.message });
    toast.error("Failed to send OTP!");
  }
};

// Step 2: Verify OTP and handle `userAuth` and `owner` tables
export const verifyOtp = (otp, callback) => async (dispatch) => {
  dispatch({ type: VERIFY_OTP_REQUEST });

  try {
    const result = await window.confirmationResult.confirm(otp);
    const user = result.user;
    const auth_uid = user.uid;

    // Set `auth_uid` in session storage immediately
    localStorage.setItem("auth_uid", auth_uid);

    const userAuthRef = doc(db, "userAuth", auth_uid);
    const userAuthSnap = await getDoc(userAuthRef);

    let ownerUUIDs = [];
    let isFirstOwner = false;

    if (userAuthSnap.exists()) {
      // User exists in `userAuth`
      ownerUUIDs = userAuthSnap.data().branches || [];
    } else {
      // Create new userAuth entry
      await setDoc(userAuthRef, { branches: [] });
    }

    if (ownerUUIDs.length === 0) {
      // If no branches exist, create a new owner
      const newOwnerUUID = uuidv4();
      isFirstOwner = true;
      // Update userAuth with new owner UUID
      await updateDoc(userAuthRef, {
        branches: arrayUnion(newOwnerUUID),
      });

      const ownerRef = doc(db, "owner", newOwnerUUID);
      const defaultOwnerData = {
        user: { status: false, phoneNumber: user.phoneNumber || "" },
        library: { status: false },
        members: [],
        pending: [],
        approval: false,
        reject: false,
        id: newOwnerUUID,
        counter: 0,
        options: [],
        auth_uid: auth_uid,
        timestamps: {
          firstAdded: serverTimestamp(),
          lastLogin: serverTimestamp(),
          lastLogout: null,
        },
        formFields: {
          name: drt,
          contactNum: drt,
          whatsappNum: drt,
          dateOfBirth: dfr,
          fatherName: dfr,
          qualification: dfr,
          emergencyContact: dfr,
          profielPic: dfr,
          email: dfr,
          aadharNumber: dfr,
          aadharImage: dfr,
          gender: drt,
          subscriptionStartDate: drt,
          subscriptionEndDate: drt,
          shift: drt,
          seatNo: drt,
          fee: drt,
          address: drf,
          goal: drf,
          paymentMethod: dfr,
          securityCharge: dfr,
          remarks: dfr,
        },
        libraryTerms: libraryPreDefinedTerms,
      };

      await setDoc(ownerRef, defaultOwnerData);

      ownerUUIDs.push(newOwnerUUID);
    } else {
      // Update last login for existing owner
      const ownerRef = doc(db, "owner", ownerUUIDs[0]);
      await updateDoc(ownerRef, {
        "timestamps.lastLogin": serverTimestamp(),
      });
    }

    // Store data in session storage after ensuring it's ready
    localStorage.setItem("user_uid", ownerUUIDs[0]);
    localStorage.setItem("branches", JSON.stringify(ownerUUIDs));

    dispatch({ type: VERIFY_OTP_SUCCESS, payload: user });
    toast.success(
      isFirstOwner
        ? "New library added successfully!"
        : "OTP verified successfully!"
    );

    // Callback to signal completion
    callback(ownerUUIDs);
  } catch (error) {
    dispatch({ type: VERIFY_OTP_FAILURE, payload: error.message });
    toast.error("Failed to verify OTP!");
  }
};

// Step 3: Logout
export const logoutAction = (callback) => async (dispatch) => {
  dispatch({ type: LOGOUT_REQUEST });
  try {
    const user_uid = localStorage.getItem("user_uid");

    if (user_uid.length > 0) {
      const ownerRef = doc(db, "owner", user_uid);
      await updateDoc(ownerRef, {
        "timestamps.lastLogout": serverTimestamp(),
      });
    }

    await signOut(auth);
    dispatch({ type: LOGOUT_SUCCESS });
    localStorage.clear();
    if (callback) callback();
    toast.success("Logged out successfully!");
  } catch (error) {
    dispatch({ type: LOGOUT_FAILURE, payload: error.message });
    toast.error("Failed to log out!");
  }
};

// Step 4: Check Authentication Status
export const verifyAuthStatus = () => (dispatch) => {
  dispatch({ type: AUTH_CHECK_REQUEST });

  auth.onAuthStateChanged((user) => {
    if (user) {
      dispatch({ type: VERIFY_OTP_SUCCESS, payload: user });
    } else {
      dispatch({ type: LOGOUT_SUCCESS });
    }
  });
};
