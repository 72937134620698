import React, { useState, useRef, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { useDispatch } from "react-redux";
import { fetchUserDetailAction } from "../../action/newUser/newUserDetailAction";
import { fetchAllMemberDetailAction } from "../../action/newMember/newMemberDetailAction";
import { fetchAllSubscriptionAction } from "../../action/newSubscription/newSubscriptionDetailAction";
import { addNewBranchAction } from "../../action/branch/addBranchAction";
import toast from "react-hot-toast";

const HeaderProfileComponent = ({ handleLogout, branchData }) => {
  const user_uid = localStorage.getItem("user_uid");
  const library_name = localStorage.getItem("library_name");
  const popoverRef = useRef(null);
  const dispatch = useDispatch();

  const [isOpen, setIsOpen] = useState(false);
  const [isSwitchModalOpen, setIsSwitchModalOpen] = useState(false);
  const [activeBranchUid, setActiveBranchUid] = useState(user_uid);
  const [newBranchLoader, setNewBranchLoader] = useState(false);

  // Fetch branches from localStorage
  const allBranchesUids = JSON.parse(localStorage.getItem("branches")) || [];

  // Set the initial active branch (e.g., the first branch in the list)
  useEffect(() => {
    if (allBranchesUids.length > 0 && !activeBranchUid) {
      setActiveBranchUid(allBranchesUids[0]);
    }
  }, [allBranchesUids, activeBranchUid]);

  // Handle outside clicks for the popover
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        popoverRef.current &&
        !popoverRef.current.contains(event.target) &&
        !isSwitchModalOpen
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [isSwitchModalOpen]);

  // Toggle popover
  const toggleMenu = () => setIsOpen((prev) => !prev);

  // Handle branch selection
  const handleBranchSelect = (branchUid, isNewBranch) => {
    setIsOpen(false);
    setActiveBranchUid(branchUid);
    setIsSwitchModalOpen(false);
    localStorage.setItem("user_uid", branchUid);
    if (user_uid !== branchUid) {
      dispatch(fetchUserDetailAction());
      dispatch(fetchAllMemberDetailAction());
      dispatch(fetchAllSubscriptionAction());
      toast.success(
        isNewBranch ? "New branch added successfully!" : "Library changed!"
      );
    }
  };

  // handle new branch
  const handleNewBranch = () => {
    const successCb = (newBranchUid) => {
      dispatch(fetchUserDetailAction());
      dispatch(fetchAllMemberDetailAction());
      dispatch(fetchAllSubscriptionAction());
      handleBranchSelect(newBranchUid, true);
      setIsOpen(false);
      setActiveBranchUid(newBranchUid);
      setIsSwitchModalOpen(false);
      localStorage.setItem("user_uid", newBranchUid);
      setNewBranchLoader(false);
    };

    const errorCb = (error) => {
      console.log(error);
      toast.error("Failed to add a new branch.");
    };

    setNewBranchLoader(true);

    dispatch(
      addNewBranchAction({
        branchData,
        successCb,
        errorCb,
      })
    );
  };

  return (
    <div className="profile-menu-container" ref={popoverRef}>
      {/* Profile Button */}
      <button
        className="profile-btn"
        onClick={toggleMenu}
        aria-expanded={isOpen}
        aria-label="Toggle Profile Menu"
      >
        <div className="profile-avatar">
          {library_name !== "undefined" || !library_name
            ? library_name.charAt(0)
            : "L"}{" "}
        </div>
      </button>

      {/* Popover Menu */}
      {isOpen && (
        <div className="popover-menu">
          <div className="popover-arrow"></div>
          <div className="popover-content">
            {/* Switch Branch Button */}
            <button
              className="text_gray menu-item"
              onClick={() => setIsSwitchModalOpen(true)}
            >
              <span className="material-symbols-outlined icon_dimension me-2">
                sync_alt
              </span>
              <span>Switch Branch</span>
            </button>
            <div className="menu-divider"></div>

            {/* Logout Button */}
            <button
              className="text_gray menu-item logout"
              onClick={() => {
                handleLogout();
                setIsOpen(false);
              }}
            >
              <span className="material-symbols-outlined icon_dimension me-2">
                move_item
              </span>
              <span>Logout</span>
            </button>
          </div>
        </div>
      )}

      {/* Switch Modal */}
      <Modal
        show={isSwitchModalOpen}
        onHide={() => setIsSwitchModalOpen(false)}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header className="switchAccountModalLabel-header">
          <h5 className="modal-title" id="switchAccountModalLabel">
            Switch Account
          </h5>
          <button
            type="button"
            className="btn-close"
            aria-label="Close"
            onClick={() => setIsSwitchModalOpen(false)}
          ></button>
        </Modal.Header>
        <Modal.Body>
          <ul className="list-group">
            {allBranchesUids.map((branchUid, index) => (
              <li
                key={branchUid}
                type="button"
                className={`list-group-item d-flex justify-content-between align-items-center list-group-item-switch `}
                onClick={() => handleBranchSelect(branchUid)}
                disabled={allBranchesUids.length === 1}
              >
                {library_name !== "undefined" ? library_name : "Library"}{" "}
                {index + 1}
                {branchUid === activeBranchUid && (
                  <span className="badge bg-primary rounded-pill">Active</span>
                )}
              </li>
            ))}
          </ul>
          <div className="d-flex justify-content-end align-items-center mt-2">
            <button
              type="button"
              className="border-0 bg-transparent d-flex align-items-center gap-1 px-0 "
              onClick={handleNewBranch}
              disabled={newBranchLoader}
            >
              {newBranchLoader ? (
                <div class="spinner-border spinner-border-btn" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              ) : (
                <>
                  <span className="material-symbols-outlined icon_dimention">
                    add
                  </span>
                  <p className="table_row_cell text-nowrap mb-0">
                    Add new library branch
                  </p>
                </>
              )}
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default HeaderProfileComponent;
