import { shiftOptions } from "./utils";

export function isActive(sub) {
  const currentDate = new Date();
  let currentDateMinusOne = new Date();
  currentDateMinusOne = currentDateMinusOne.setDate(
    currentDateMinusOne.getDate() - 1
  );
  return (
    new Date(sub?.endDate) >= currentDateMinusOne &&
    new Date(sub?.startDate) <= currentDate
  );
}

export const calculateShiftDuration = (
  startHour,
  startMinute,
  startPeriod,
  endHour,
  endMinute,
  endPeriod
) => {
  const convertTo24HourFormat = (hour, period) => {
    if (period === "PM" && hour !== 12) return hour + 12;
    if (period === "AM" && hour === 12) return 0;
    return hour;
  };

  const startHour24 = convertTo24HourFormat(startHour, startPeriod);
  const endHour24 = convertTo24HourFormat(endHour, endPeriod);

  const startTimeInMinutes = startHour24 * 60 + startMinute;
  const endTimeInMinutes = endHour24 * 60 + endMinute;

  const durationInMinutes = endTimeInMinutes - startTimeInMinutes;
  const durationInHours =
    durationInMinutes > 0
      ? durationInMinutes / 60
      : (1440 + durationInMinutes) / 60;

  return durationInHours.toFixed(1);
};

// duration student subscription calculation
export function calculateDuration(startDate, endDate) {
  const start = new Date(startDate);
  const end = new Date(endDate);

  const options = { day: "numeric", month: "short" };
  const startFormatted = start.toLocaleDateString("en-GB", options);
  const endFormatted = end.toLocaleDateString("en-GB", options);

  return `${startFormatted} - ${endFormatted}`;
}

// Active user finding function
export const getActiveUserData = (students) => {
  if (!students || !Array.isArray(students)) {
    // Handle edge case if the students list is null, undefined, or not an array
    return 0;
  }

  const today = new Date(); // Get today's date in local time
  const todayUTC = new Date(
    Date.UTC(today.getUTCFullYear(), today.getUTCMonth(), today.getUTCDate())
  ); // Set the time to 00:00:00 UTC

  // Function to check if the current date is between the start and end dates in UTC
  const isActive = (startDate, endDate) => {
    if (!startDate || !endDate) return false; // If either date is null or undefined, return false

    const start = new Date(startDate); // Convert startDate to a Date object
    const end = new Date(endDate); // Convert endDate to a Date object
    const startUTC = new Date(
      Date.UTC(start.getUTCFullYear(), start.getUTCMonth(), start.getUTCDate())
    );
    const endUTC = new Date(
      Date.UTC(end.getUTCFullYear(), end.getUTCMonth(), end.getUTCDate())
    );

    return todayUTC >= startUTC && todayUTC <= endUTC;
  };

  let activeStudentCount = 0;

  // Iterate over each student
  for (const student of students) {
    if (
      !student ||
      !student.subscription ||
      !Array.isArray(student.subscription)
    )
      continue; // Skip students with invalid or missing subscription data

    let isStudentActive = false;

    // Check each subscription of the student
    for (const sub of student.subscription) {
      if (isActive(sub?.startDate, sub?.endDate)) {
        isStudentActive = true;
        break; // Early exit as soon as we find an active subscription
      }
    }

    // If any subscription is active, count the student
    if (isStudentActive) {
      activeStudentCount++;
    }
  }

  return activeStudentCount;
};

// 3 and 7 days students data
export const getUpcomingExpireData = (days, data) => {
  const currentDate = new Date();
  const targetDate = new Date(currentDate);
  targetDate?.setDate(currentDate?.getDate() + days);

  const filteredData = [];

  data?.forEach((item) => {
    if (item?.subscription?.length) {
      let lastSubscription = item?.subscription[item?.subscription?.length - 1];
      for (const sub of item?.subscription) {
        if (isActive(sub)) {
          lastSubscription = sub;
          break;
        }
      }
      let isLast =
        lastSubscription === item?.subscription[item?.subscription?.length - 1];

      if (lastSubscription) {
        let endDate = new Date(lastSubscription?.endDate);
        endDate = endDate.setDate(endDate?.getDate() + 1);
        if (
          // !isNaN(endDate.getTime()) &&
          isLast &&
          endDate >= currentDate &&
          endDate <= targetDate
        ) {
          filteredData.push(item);
        }
      }
    }
  });

  return filteredData;
};

export const getExpiredData = (data) => {
  const currentDate = new Date();
  // const targetDate = new Date(currentDate);
  // targetDate?.setDate(currentDate?.getDate() + days);

  const filteredData = [];

  data?.forEach((item) => {
    if (item?.subscription?.length) {
      let lastSubscription = item?.subscription[item?.subscription?.length - 1];
      for (const sub of item?.subscription) {
        if (isActive(sub)) {
          lastSubscription = sub;
          break;
        }
      }
      let isLast =
        lastSubscription === item?.subscription[item?.subscription?.length - 1];

      if (lastSubscription) {
        let endDate = new Date(lastSubscription?.endDate);
        endDate = endDate.setDate(endDate?.getDate() + 1);
        if (endDate < currentDate) filteredData.push(item);
      }
    }
  });

  return filteredData;
};

// get gender details all
export function getGenderCount(data) {
  const genderCount = {};

  data.forEach((student) => {
    const gender = student.gender;
    if (genderCount[gender]) {
      genderCount[gender] += 1;
    } else {
      genderCount[gender] = 1;
    }
  });

  return genderCount;
}

// active user gender detail
export const getActiveUserGenderData = (students) => {
  // Validate if students is an array
  if (!Array.isArray(students)) {
    return {};
  }

  const today = new Date().toISOString().split("T")[0];
  const activeUsersCountByGender = {};

  const Today = new Date();
  const yesterday = new Date(Today);
  yesterday.setDate(Today.getDate() - 1);

  const formattedYesterday = yesterday.toISOString().split("T")[0];

  for (const student of students) {
    if (student?.subscription?.length) {
      let latestSubscription = student.subscription[0];

      for (const sub of student.subscription) {
        if (isActive(sub)) {
          latestSubscription = sub;
          break;
        }
      }

      const isActivebool =
        today >= latestSubscription?.startDate.split("T")[0] &&
        formattedYesterday <= latestSubscription?.endDate.split("T")[0];

      if (isActivebool) {
        const gender = student.gender;
        if (activeUsersCountByGender[gender]) {
          activeUsersCountByGender[gender]++;
        } else {
          activeUsersCountByGender[gender] = 1;
        }
      }
    }
  }

  return activeUsersCountByGender;
};

// get base url
export function getBaseURL(url) {
  try {
    const { origin } = new URL(url);
    return origin + "/";
  } catch (error) {
    console.error("Invalid URL:", error);
    return null;
  }
}

// date formater
export const formatDate = (dateStr) => {
  const options = { day: "numeric", month: "long" };
  return new Date(dateStr).toLocaleDateString("en-GB", options);
};

// student detail page shift wise filter option converter
export function convertShiftsToOptions(data) {
  const shiftsCategory = data?.find((item) => item?.category === "Shifts");

  if (!shiftsCategory || !shiftsCategory?.options) return [];

  const options = shiftsCategory.options?.map((shift) => {
    const [label] = shift.split(" (");
    return { value: label, label: label };
  });

  return [{ value: "All", label: "All Shifts" }, ...options];
}

// shift option in library form
export const getAvailableShiftOptions = (currentIndex, shifts) => {
  const selectedShifts = shifts?.map((shift) => shift.shiftName);
  return shiftOptions?.filter(
    (option) =>
      !selectedShifts.includes(option) ||
      shifts[currentIndex].shiftName === option
  );
};

export const getAvailableFloorOptions = (currentIndex, floors) => {
  const selectedFloors = floors?.map((floor) => floor.floorName);
  return ["A", "B", "C", "D", "E"]?.filter(
    (option) =>
      !selectedFloors.includes(option) ||
      floors[currentIndex].floorName === option
  );
};
// Analysing seat data to foned the seat status booked or not or empty
export function analyzeSeatBookings(seatsData) {
  if (!Array.isArray(seatsData)) {
    return {
      total_booked: 0,
      total_empty: 0,
      partial_booked: 0,
      error: "Invalid input data",
    };
  }

  return seatsData.reduce(
    (acc, seat) => {
      if (!seat?.shifts?.length) return acc;

      const shifts = seat.shifts;
      const twentyFourHourShift = shifts.find(
        (s) => s?.shiftType === "Full Day Shift"
      );

      if (twentyFourHourShift?.bookingStatus === "Booked") {
        acc.total_booked++;
        return acc;
      }

      if (twentyFourHourShift?.bookingStatus === "Available") {
        acc.total_empty++;
        return acc;
      }

      const regularShifts = shifts?.filter(
        (s) => s?.shiftType !== "Full Day Shift"
      );
      if (!regularShifts.length) return acc;

      const bookingStatuses = {
        Booked: 0,
        Available: 0,
      };

      regularShifts.forEach((shift) => {
        if (shift?.bookingStatus in bookingStatuses) {
          bookingStatuses[shift.bookingStatus]++;
        }
      });

      if (bookingStatuses.Booked === regularShifts.length) {
        acc.total_booked++;
      } else if (bookingStatuses.Available === regularShifts.length) {
        acc.total_empty++;
      } else {
        acc.partial_booked++;
      }

      return acc;
    },
    {
      total_booked: 0,
      total_empty: 0,
      partial_booked: 0,
    }
  );
}
