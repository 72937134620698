import { doc, getDoc, setDoc } from "firebase/firestore";
import { db } from "../../firebase/firebase.auth.config";

export const updateLibraryTermsAction = (libraryTerms, successCb, errorCb) => {
  return async (dispatch) => {
    dispatch({ type: "UPDATE_LIBRARY_RULE_REQUEST" });
    try {
      const user_uid = localStorage.getItem("user_uid");

      const userDoc = doc(db, "owner", user_uid);
      const docSnap = await getDoc(userDoc);
      var docData = docSnap.data();
      docData.libraryTerms = libraryTerms;
      await setDoc(userDoc, docData);
      dispatch({
        type: "UPDATE_LIBRARY_RULE_SUCCESS",
        payload: libraryTerms,
      });
      successCb();
    } catch (error) {
      dispatch({
        type: "UPDATE_LIBRARY_RULE_ERROR",
        error: error.message || "Something went wrong",
      });
      errorCb(error);
    }
  };
};
