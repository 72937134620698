import { initializeApp } from "firebase/app";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { doc, getDoc, getFirestore } from "firebase/firestore";
import {
  getStorage,
  ref,
  uploadBytes,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

const app = initializeApp(firebaseConfig);

const storage = getStorage();

export const uploadImage = async (file, folder = "images") => {
  return new Promise((resolve, reject) => {
    try {
      // Create a reference to the storage location
      const storageRef = ref(storage, `${folder}/${file?.name}`);

      // Initialize the upload task with `uploadBytesResumable`
      const uploadTask = uploadBytesResumable(storageRef, file);

      // Monitor the upload process
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          // Progress tracking
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log(`Upload is ${progress}% done`);
          switch (snapshot.state) {
            case "paused":
              console.log("Upload is paused");
              break;
            case "running":
              console.log("Upload is running");
              break;
            default:
              break;
          }
        },
        (error) => {
          // Handle upload errors
          console.error("Upload failed:", error);
          reject(error);
        },
        async () => {
          // Handle successful uploads
          try {
            const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
            console.log("File available at:", downloadURL);
            resolve(downloadURL); // Return the URL
          } catch (error) {
            console.error("Failed to get download URL:", error);
            reject(error);
          }
        }
      );
    } catch (error) {
      console.error("Upload failed:", error);
      reject(error);
    }
  });
};

export const auth = getAuth(app);
// export const auth = {
//   currentUser: {
//     uid: "xlQG6b7c0TR3jDRJqhnsw85lg1A3",
//   },
// };
export const db = getFirestore(app);
onAuthStateChanged(auth, (user) => {
  if (user) {
    const user_uid = localStorage.getItem("user_uid");
    const branches = JSON.parse(localStorage.getItem("branches"));
    if (!user_uid && branches?.length > 0) {
      const firstBranchUid = branches[0];
      localStorage.setItem("user_uid", firstBranchUid);
    }
  } else {
    localStorage.removeItem("user_uid");
  }
});
export default app;
