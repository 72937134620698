import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import MobSideBar from "../mobSideBar";

const SidebarScreen = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const navigate = useNavigate();
  const location = useLocation();

  const handleNavigation = (route) => {
    navigate(route);
  };

  const getActiveClass = (route) => {
    const pathname = location.pathname;
    switch (pathname) {
      case "/dashboard":
        return route === "/dashboard" ? "active_btn_sidebar" : "";
        break;
      case "/students":
        return route === "/students" ? "active_btn_sidebar" : "";
        break;
      case "/profile":
        return route === "/profile" ? "active_btn_sidebar" : "";
        break;
      case "/seats":
        return route === "/seats" ? "active_btn_sidebar" : "";
        break;
      case "/message":
        return route === "/message" ? "active_btn_sidebar" : "";
        break;
      case "/help":
        return route === "/help" ? "active_btn_sidebar" : "";
        break;
      default:
        return "";
    }
  };

  return (
    <>
      <button
        className="button_menu_mob display_none display_block_900 button_design text_gray"
        onClick={handleShow}
      >
        <span className="material-symbols-outlined ">menu</span>
      </button>
      <MobSideBar
        show={show}
        handleClose={handleClose}
        getActiveClass={getActiveClass}
        navigate={navigate}
      />
      <div className="display_none_900">
        <div className="d-flex flex-column gap-3 ">
          <button
            className={` border-0 outline-none button_design_withoutWeight btn_sidebar ${getActiveClass(
              "/dashboard"
            )} d-flex gap-2 align-items-center `}
            onClick={() => handleNavigation("/dashboard")}
          >
            <span className="material-symbols-outlined icon_dimention">
              dashboard
            </span>
            <span>Dashboard</span>
          </button>
          <button
            className={`button_design_withoutWeight btn_sidebar ${getActiveClass(
              "/students"
            )} d-flex gap-2 align-items-center`}
            onClick={() => handleNavigation("/students")}
          >
            <span className="material-symbols-outlined icon_dimention">
              manage_accounts
            </span>
            <span>Students</span>
          </button>
          <button
            className={`button_design_withoutWeight btn_sidebar ${getActiveClass(
              "/seats"
            )} d-flex gap-2 align-items-center`}
            onClick={() => handleNavigation("/seats")}
          >
            <span className="material-symbols-outlined icon_dimention">
              chair
            </span>
            <span>Seats </span>
          </button>
          <button
            className={`button_design_withoutWeight btn_sidebar ${getActiveClass(
              "/message"
            )} d-flex gap-2 align-items-center`}
            onClick={() => handleNavigation("/message")}
          >
            <span className="material-symbols-outlined icon_dimention">
              message
            </span>
            <span>Message</span>
          </button>
          <button
            className={`button_design_withoutWeight btn_sidebar ${getActiveClass(
              "/help"
            )} d-flex gap-2 align-items-center`}
            onClick={() => handleNavigation("/help")}
          >
            <span className="material-symbols-outlined icon_dimention">
              help
            </span>
            <span>Help & Support</span>
          </button>
          <button
            className={`button_design_withoutWeight btn_sidebar ${getActiveClass(
              "/profile"
            )} d-flex gap-2 align-items-center`}
            onClick={() => handleNavigation("/profile")}
          >
            <span className="material-symbols-outlined icon_dimention">
              account_circle
            </span>
            <span>Profile</span>
          </button>
        </div>
      </div>
    </>
  );
};

export default SidebarScreen;
