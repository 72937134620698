import React, { useEffect, useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import FormCheck from "react-bootstrap/FormCheck";
import FormGroup from "react-bootstrap/FormGroup";
import FormLabel from "react-bootstrap/FormLabel";
import makeAnimated from "react-select/animated";
import Select from "react-select";
import {
  customStyles,
  getFilterShifts,
  transformShiftsOptions,
} from "../../util/utils";
import { useDispatch, useSelector } from "react-redux";
import {
  addSubscriptionDetailAction,
  fetchAllSubscriptionAction,
} from "../../action/newSubscription/newSubscriptionDetailAction";
import moment from "moment";
import { multipleShifts } from "../../util/checkSeatAvaFunction";
import { generateSeatNames } from "../../util/checkSeatAvaFunction";
import { fetchUserDetailAction } from "../../action/newUser/newUserDetailAction";
import { fetchAllMemberDetailAction } from "../../action/newMember/newMemberDetailAction";

const ReNewSubModalComponent = ({ show, setShowModalRenewSub, data }) => {
  const dispatch = useDispatch();
  const newUserDetail = useSelector((state) => state.newUserDetail);
  const newSubsDetail = useSelector((state) => state.newSubscriptionDetail);
  const [usePreviousData, setUsePreviousData] = useState(false);
  const [loader, setLoader] = useState(false);
  const [seatOptions, setSeatOptions] = useState([]);
  const [selectedSeat, setSelectedSeat] = useState(null);
  const [isSeatInvalid, setIsSeatInvalid] = useState(false);

  const shifts = transformShiftsOptions(newUserDetail?.data?.library?.shifts);

  const lastSubscription =
    data?.subscription?.[data?.subscription?.length - 1] || {};
  const seats = generateSeatNames(newUserDetail?.data?.library?.floors);
  const animatedComponents = makeAnimated();
  const shiftOptions = shifts;

  const initialValues = {
    startDate: "",
    endDate: "",
    seatNo: usePreviousData ? lastSubscription?.seatNo : "",
    shift: usePreviousData ? lastSubscription?.shift || [] : [],
    fee: usePreviousData ? lastSubscription?.fee : "",
    library: lastSubscription?.library,
    member: lastSubscription?.member,
  };

  useEffect(() => {
    const updateSeatOptions = (values) => {
      if (values.startDate && values.endDate && values.shift.length > 0) {
        const formattedShifts = values.shift;
        const formattedStartDate = new Date(values.startDate);
        const formattedEndDate = new Date(values.endDate);

        const allotSeats = multipleShifts(
          newUserDetail?.data?.library,
          newUserDetail?.data?.members,
          seats,
          formattedStartDate,
          formattedEndDate,
          formattedShifts,
          newSubsDetail?.data
        );

        const newAllotSeat = allotSeats?.map(
          ({ seatNo: label, seatNo: value, ...rest }) => ({
            label,
            value,
            ...rest,
          })
        );

        setSeatOptions(newAllotSeat);

        if (
          values.seatNo &&
          !newAllotSeat.some((option) => option.value === values.seatNo)
        ) {
          setSelectedSeat(null);
          setIsSeatInvalid(true);
        } else {
          setIsSeatInvalid(false);
        }
      } else {
        setSeatOptions([]);
        setIsSeatInvalid(false);
      }
    };

    window.updateSeatOptions = updateSeatOptions;
  }, [
    newUserDetail?.data?.library,
    newUserDetail?.data?.members,
    seats,
    newSubsDetail?.data,
  ]);

  const validationSchema = Yup.object({
    startDate: Yup.date().required("Start Date is required"),
    endDate: Yup.date().required("End Date is required"),
    seatNo: Yup.string().required("Required"),
    shift: Yup.array().required("Required"),
    fee: Yup.string().required("Required"),
  });

  const successCb = () => {
    dispatch(fetchUserDetailAction());
    dispatch(fetchAllMemberDetailAction());
    dispatch(fetchAllSubscriptionAction());
    setShowModalRenewSub(false);
    setLoader(false);
  };

  const errorCb = (error) => {
    console.error(error);
    setLoader(false);
  };

  const handleSubmit = (values, { resetForm }) => {
    const formattedValues = {
      ...values,
      startDate: new Date(values.startDate).toISOString(),
      endDate: new Date(values.endDate).toISOString(),
    };
    // dispatch(addSubscriptionDetailAction(formattedValues, data?.id));
    dispatch(
      addSubscriptionDetailAction(formattedValues, data?.id, successCb, errorCb)
    );
    setLoader(true);
  };

  const handleCheckboxChange = (e, setFieldValue) => {
    const checked = e.target.checked;
    setUsePreviousData(checked);

    if (checked) {
      const values = {
        startDate: lastSubscription?.endDate,
        endDate: lastSubscription?.endDate,
        shift: lastSubscription?.shift || [],
        seatNo: lastSubscription?.seatNo || "",
      };
      setFieldValue("shift", lastSubscription?.shift || []);
      setFieldValue("fee", lastSubscription?.fee || "");
      window.updateSeatOptions({
        ...values,
        shift: getFilterShifts(lastSubscription.shift, shifts),
        seatNo: "",
      });
    } else {
      setFieldValue("seatNo", "");
      setFieldValue("shift", []);
      setFieldValue("fee", "");
    }
  };

  const handleContinue = (setFieldValue) => {
    if (lastSubscription.endDate) {
      const newStartDate = new Date(lastSubscription.endDate);
      newStartDate.setDate(newStartDate.getDate() + 1);

      const newEndDate = new Date(newStartDate);
      newEndDate.setMonth(newEndDate.getMonth() + 1);

      const values = {
        startDate: newStartDate.toISOString(),
        endDate: newEndDate.toISOString(),
        shift: lastSubscription?.shift || [],
        seatNo: lastSubscription?.seatNo || "",
      };

      setFieldValue("startDate", newStartDate.toISOString());
      setFieldValue("endDate", newEndDate.toISOString());
      setFieldValue("seatNo", lastSubscription?.seatNo || "");
      setFieldValue("shift", lastSubscription?.shift || []);
      setFieldValue("fee", lastSubscription?.fee || "");
      window.updateSeatOptions({
        ...values,
        shift: getFilterShifts(lastSubscription?.shift, shifts),
      });
    }
  };

  return (
    <Modal
      show={show}
      onHide={() => setShowModalRenewSub(false)}
      backdrop="static"
      keyboard={false}
      className="renew-subscription-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title className="heading_four">
          {`Renew Subscription - ${data?.name || ""}`}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ setFieldValue, values, errors, touched, resetForm }) => (
            <Form className="d-flex flex-column gap-3">
              <FormGroup controlId="dateRange">
                <FormLabel className="label_text">
                  Select duration of subscription
                </FormLabel>
                <div className="d-flex flex-md-row flex-column gap-3">
                  <DatePicker
                    selected={
                      values.startDate ? new Date(values.startDate) : null
                    }
                    onChange={(date) => {
                      setFieldValue("startDate", date.toISOString());
                      window.updateSeatOptions({
                        ...values,
                        startDate: date.toISOString(),
                      });
                    }}
                    selectsStart
                    startDate={
                      values.startDate ? new Date(values.startDate) : null
                    }
                    endDate={values.endDate ? new Date(values.endDate) : null}
                    minDate={
                      lastSubscription.endDate
                        ? new Date(
                            new Date(lastSubscription.endDate).setDate(
                              new Date(lastSubscription.endDate).getDate() + 1
                            )
                          )
                        : null
                    }
                    dateFormat="dd/MM/yyyy"
                    placeholderText="Start Date"
                    className={`form-control input_feild ${
                      errors.startDate && touched.startDate ? "is-invalid" : ""
                    }`}
                    value={
                      values.startDate
                        ? moment(values.startDate).format("DD/MM/YYYY")
                        : ""
                    }
                  />
                  <DatePicker
                    selected={values.endDate ? new Date(values.endDate) : null}
                    onChange={(date) => {
                      setFieldValue("endDate", date.toISOString());
                      window.updateSeatOptions({
                        ...values,
                        endDate: date.toISOString(),
                      });
                    }}
                    selectsEnd
                    startDate={
                      values.startDate ? new Date(values.startDate) : null
                    }
                    endDate={values.endDate ? new Date(values.endDate) : null}
                    minDate={
                      values.startDate ? new Date(values.startDate) : null
                    }
                    dateFormat="dd/MM/yyyy"
                    placeholderText="End Date"
                    className={`form-control input_feild ${
                      errors.endDate && touched.endDate ? "is-invalid" : ""
                    }`}
                    value={
                      values.endDate
                        ? moment(values.endDate).format("DD/MM/YYYY")
                        : ""
                    }
                  />
                </div>
              </FormGroup>

              <FormGroup>
                <FormLabel className="label_text">Shift</FormLabel>
                <Select
                  options={shiftOptions}
                  value={
                    values.shift
                      ? shiftOptions?.filter((option) =>
                          values.shift.includes(option.value.split(" (")[0])
                        )
                      : []
                  }
                  isMulti
                  className="formBodyControlModal"
                  placeholder="Select Shift"
                  onChange={(selectedOptions) => {
                    if (selectedOptions[0]?.shift === "Full Day Shift")
                      selectedOptions?.splice(1, selectedOptions?.length);
                    if (
                      selectedOptions[selectedOptions.length - 1]?.shift ===
                      "Full Day Shift"
                    )
                      selectedOptions?.splice(0, selectedOptions?.length - 1);
                    const formattedOptions = selectedOptions?.map((option) =>
                      option.value.split(" (")[0].trim()
                    );
                    setFieldValue("shift", formattedOptions);
                    window.updateSeatOptions({
                      ...values,
                      shift: selectedOptions,
                    });
                  }}
                  styles={customStyles}
                />
                {errors.shift && touched.shift && (
                  <div className="text-danger">{errors.shift}</div>
                )}
              </FormGroup>

              <FormGroup>
                <FormLabel className="label_text">Seat No.</FormLabel>
                <Select
                  options={seatOptions}
                  value={seatOptions.find(
                    (option) => option.value === values.seatNo
                  )}
                  className="formBodyControlModal"
                  components={animatedComponents}
                  placeholder={
                    !values.startDate || !values.endDate || !values.shift.length
                      ? "Select Shift & dates to show seat options"
                      : "Select Seat"
                  }
                  isDisabled={
                    !values.startDate || !values.endDate || !values.shift.length
                  }
                  onChange={(option) => {
                    setFieldValue("seatNo", option ? option.value : "");
                    setSelectedSeat(option ? option.value : null);
                    setIsSeatInvalid(false);
                  }}
                  styles={customStyles}
                />
                {isSeatInvalid && (
                  <div className="text-danger">
                    This seat is reserved by someone else in this period
                  </div>
                )}
              </FormGroup>

              <FormGroup controlId="fee">
                <FormLabel className="label_text">Fee</FormLabel>
                <Field
                  name="fee"
                  type="text"
                  className={`form-control input_feild ${
                    errors.fee && touched.fee ? "is-invalid" : ""
                  }`}
                />
                {errors.fee && touched.fee && (
                  <div className="text-danger">{errors.fee}</div>
                )}
              </FormGroup>

              <div className="d-flex gap-3 align-items-center justify-content-between my-1 py-3 border-top border-bottom">
                <FormGroup controlId="usePreviousData">
                  <FormCheck
                    type="checkbox"
                    label="Same as previous data"
                    checked={usePreviousData}
                    onChange={(e) => handleCheckboxChange(e, setFieldValue)}
                    disabled={loader}
                  />
                </FormGroup>
                <Button
                  className="pri_radious btn-success border-none border-0 mt-0 me-2 select_duration_btn"
                  onClick={() => handleContinue(setFieldValue)}
                  disabled={loader}
                >
                  Continue
                </Button>
              </div>

              <div className="col-12 d-flex border-0 gap-2 outline-0 justify-content-between my-2">
                <Button
                  className="button_design w-50"
                  variant="outline"
                  disabled={loader}
                  onClick={() => {
                    setShowModalRenewSub(false);
                    resetForm();
                  }}
                >
                  Cancel
                </Button>

                <Button
                  className="pri_bg button_design w-50 shadow-none"
                  variant="secondary"
                  type="submit"
                  disabled={loader}
                >
                  {loader ? (
                    <div
                      className="spinner-border spinner-border-btn"
                      role="status"
                    >
                      <span className="sr-only">Loading...</span>
                    </div>
                  ) : (
                    "Renew Student"
                  )}{" "}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

export default ReNewSubModalComponent;
