import React from "react";
import ProfileScreen from "./profileScreen";
import { useSelector } from "react-redux";
import { libraryPreDefinedTerms } from "../../util/utils";
import { db } from "../../firebase/firebase.auth.config";
import {
  collection,
  getDoc,
  doc,
  setDoc,
  getDocs,
  updateDoc,
  deleteField,
} from "firebase/firestore";

const drt = {
  display: true,
  required: true,
};
const drf = {
  display: true,
  required: false,
};
const dfr = {
  display: false,
  required: false,
};
const ProfilePage = () => {
  const newUserDetail = useSelector((state) => state.newUserDetail);
  const loaderFormLibrary = useSelector(
    (state) => state.newLibraryDetail?.loading
  );

  // const handleClick = async () => {
  //   try {
  //     const querySnapshot = await getDocs(collection(db, "owner"));
  //     querySnapshot.forEach(async (docu) => {
  //       // doc.data() is never undefined for query doc snapshots
  //       console.log(docu.id, " => ", docu.data());
  //       await updateDoc(doc(db, "owner", docu.id), {
  //         formFields: {
  //           name: drt,
  //           contactNum: drt,
  //           whatsappNum: drt,
  //           dateOfBirth: dfr,
  //           fatherName: dfr,
  //           qualification: dfr,
  //           emergencyContact: dfr,
  //           profielPic: dfr,
  //           email: dfr,
  //           aadharNumber: dfr,
  //           aadharImage: dfr,
  //           gender: drt,
  //           subscriptionStartDate: drt,
  //           subscriptionEndDate: drt,
  //           shift: drt,
  //           seatNo: drt,
  //           fee: drt,
  //           address: drf,
  //           goal: drf,
  //           paymentMethod: dfr,
  //           securityCharge: dfr,
  //           remarks: dfr,
  //         },
  //       });
  //     });
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };

  // const handleClick = async () => {
  //   // const docu = await getDoc(doc(db, "member", "04YDojieEf3nMSRhYOQj"));
  //   const querySnapshot = await getDocs(collection(db, "member"));
  //   querySnapshot.forEach(async (docu) => {
  //     // if (docu.exists()) {
  //     let subscription = [];
  //     let libraryIds = [];
  //     // doc.data() is never undefined for query doc snapshots
  //     console.log(docu.id, " => ", docu.data());
  //     subscription = docu.data().subscription;
  //     console.log(subscription);
  //     await Promise.all(
  //       subscription.map(async (sub) => {
  //         const docRef = doc(db, "subscription", sub);
  //         const docSnap = await getDoc(docRef);
  //         if (docSnap.exists()) {
  //           console.log("Document data:", docSnap.data());
  //           libraryIds.push(docSnap.data().library);
  //         } else {
  //           console.log("No such document!");
  //         }
  //       })
  //     );

  //     // if (libraryIds.length) {
  //     // console.log(libraryIds);
  //     libraryIds = libraryIds.filter(
  //       (item, index) => libraryIds.indexOf(item) === index
  //     );
  //     await updateDoc(doc(db, "member", docu.id), {
  //       libraryIds: libraryIds,
  //     });
  //     // }
  //   });
  // };

  return (
    <>
      {/* <button onClick={handleClick}>Click me</button> */}
      <ProfileScreen
        owner_details={newUserDetail.data.user}
        terms={newUserDetail.data.libraryTerms || libraryPreDefinedTerms}
        loading={newUserDetail.loading}
        error={newUserDetail.error}
        library_details={newUserDetail.data.library}
        newUserDetail={newUserDetail.data}
        loaderFormLibrary={loaderFormLibrary}
        loaderFormPerson={newUserDetail?.loading}
      />
    </>
  );
};

export default ProfilePage;
