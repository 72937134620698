import React from "react";
import { Modal, Button } from "react-bootstrap";

const ModalComponent = ({
  show,
  handleClose,
  title,
  body,
  handleSubmit,
  isSubmitting,
  selectedRowData,
}) => {
  return (
    <Modal show={show} onHide={handleClose} backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title className="heading_four ">{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="bodyModal">{body}</Modal.Body>
      <Modal.Footer>
        <ModalFooter
          handleSubmit={handleSubmit}
          isSubmitting={isSubmitting}
          handleClose={handleClose}
          selectedRowData={selectedRowData}
        />
      </Modal.Footer>
    </Modal>
  );
};

const ModalFooter = ({
  handleSubmit,
  isSubmitting,
  handleClose,
  selectedRowData,
}) => {
  return (
    <div className="row w-100 border-0 outline-0 justify-content-between">
      <Button
        className="button_design col-4 w-25"
        variant="outline"
        onClick={handleClose}
        disabled={isSubmitting}
      >
        Cancel
      </Button>
      <Button
        className="pri_bg button_design col-4 w-25"
        variant="secondary"
        onClick={handleSubmit}
        disabled={isSubmitting}
      >
        {isSubmitting ? (
          <div class="spinner-border spinner-border-sm" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        ) : selectedRowData ? (
          "Add"
        ) : (
          "Update"
        )}
      </Button>
    </div>
  );
};

export default ModalComponent;
