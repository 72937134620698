import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { deleteMemberDetailAction } from "../../action/newMember/newMemberDetailAction";
import { fetchUserDetailAction } from "../../action/newUser/newUserDetailAction";
import ReceiptButtons from "./DownloadButtonComponent";

const ModalStudentDetailComponent = ({
  student,
  onClose,
  modalDelete,
  handleApprove,
  approveLoader,
}) => {
  const {
    memberID,
    name,
    mobile,
    gender,
    goal,
    email,
    emergencyContact,
    profileImage,
    fatherName,
    dateOfBirth,
    aadharCardDoc,
    whatsapp,
    address,
    subscription,
    id,
    aadhar_number,
    paymentMethod,
    remarks,
  } = student;

  const user_uid = localStorage.getItem("user_uid");
  const dispatch = useDispatch();
  const [denyLoader, setDenyLoader] = useState(false);

  function formatDate(inputDate) {
    const date = new Date(inputDate);
    const day = String(date.getDate()).padStart(2, "0"); // Get day and pad with leading zero
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Get month (0-based index) and pad with leading zero
    const year = date.getFullYear(); // Get the full year
    return `${day}/${month}/${year}`;
  }

  const handleDeny = () => {
    setDenyLoader(true);
    const successCb = () => {
      toast.success("Deleted successfully!");
      onClose();
      dispatch(fetchUserDetailAction());
      setDenyLoader(false);
    };
    const errorCb = (error) => {
      toast.error(error);
      setDenyLoader(false);
    };
    dispatch(deleteMemberDetailAction(id, user_uid, successCb, errorCb));
  };
  return (
    <Modal show={true} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title className="d-flex align-items-center justify-content-between gap-2">
          <span className="heading_four">Details</span>
          {modalDelete === "detail-view" ? (
            <>
              <button
                className="pri_radious pri_bg border-none mt-0 ms-3 text-white select_duration_btn"
                disabled={denyLoader || approveLoader}
                onClick={() => handleApprove(id)}
              >
                Approve
              </button>
              <button
                className="pri_radious border-none mt-0 me-2 select_duration_btn"
                disabled={denyLoader || approveLoader}
                onClick={handleDeny}
              >
                Deny
              </button>
            </>
          ) : (
            <button
              className="border-0 bg-transparent d-flex align-items-center gap-1"
              onClick={handleDeny}
            >
              <span className="material-symbols-outlined icon_dimention">
                Delete
              </span>
              <p className="table_row_cell text-nowrap mb-0">Delete</p>
            </button>
          )}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className="sec_radious border p-3 mb-3">
          <h5 className="heading_five mb-0 pri_border_bottom pb-2 mb-3 text_gray">
            Basic Details
          </h5>
          <div>
            <div className="d-flex justify-md-content-start justify-content-between image-setele gap-3">
              <div>
                <p className="text_lightGray label_text mb-2 pb-1">
                  Student ID - <br />
                  <span className="para_text text_gray">{memberID}</span>
                </p>
                <p className="text_lightGray label_text mb-2 pb-1">
                  Name - <br />
                  <span className="para_text text_gray">{name}</span>
                </p>
                {fatherName && (
                  <p className="text_lightGray label_text mb-2 pb-1">
                    Father's Name - <br />
                    <span className="para_text text_gray">{fatherName}</span>
                  </p>
                )}
                <p className="text_lightGray label_text mb-2 pb-1">
                  Contact Number - <br />
                  <span className="para_text text_gray">{mobile}</span>
                </p>
                <p className="text_lightGray label_text mb-2 pb-1">
                  WhatsApp Number - <br />
                  <span className="para_text text_gray">{whatsapp}</span>
                </p>

                {email && (
                  <p className="text_lightGray label_text mb-2 pb-1">
                    Email - <br />
                    <span className="para_text text_gray">{email}</span>
                  </p>
                )}
                <p className="text_lightGray label_text mb-2 pb-1">
                  Gender - <br />
                  <span className="para_text text_gray">{gender}</span>
                </p>
                {dateOfBirth && (
                  <p className="text_lightGray label_text mb-2 pb-1">
                    Date of Birth - <br />
                    <span className="para_text text_gray">
                      {formatDate(dateOfBirth)}
                    </span>
                  </p>
                )}
                {goal && (
                  <p className="text_lightGray label_text mb-2 pb-1">
                    Goal - <br />
                    <span className="para_text text_gray">{goal}</span>
                  </p>
                )}
                {address && (
                  <p className="text_lightGray label_text">
                    Address - <br />
                    <span className="para_text text_gray">{address}</span>
                  </p>
                )}
                {paymentMethod && (
                  <p className="text_lightGray label_text mb-2 pb-1">
                    Payment Mode - <br />
                    <span className="para_text text_gray">{paymentMethod}</span>
                  </p>
                )}
                {remarks && (
                  <p className="text_lightGray label_text mb-2 pb-1">
                    Remarks - <br />
                    <span className="para_text text_gray">{remarks}</span>
                  </p>
                )}
                {emergencyContact && (
                  <p className="text_lightGray label_text mb-2 pb-1">
                    Emergency Contact Number - <br />
                    <span className="para_text text_gray">
                      {emergencyContact}
                    </span>
                  </p>
                )}
                {aadhar_number && (
                  <p className="text_lightGray label_text mb-2 pb-1">
                    Aadhar Number - <br />
                    <span className="para_text text_gray">{aadhar_number}</span>
                  </p>
                )}
              </div>
              <div style={{ display: "grid" }}></div>
              <div className="d-flex flex-column gap-3 align-items-center">
                {profileImage && (
                  <img
                    src={profileImage}
                    className="rounded h-auto profile-image-view"
                  />
                )}
                {aadharCardDoc && (
                  <img
                    src={aadharCardDoc}
                    className="rounded h-25 profile-aadhar-view"
                  />
                  // <a
                  //   href={aadharCardDoc}
                  //   className="view-adhar-btn d-flex align-items-center gap-1 pri_radious border-none select_duration_btn"
                  //   target="_blank"
                  // >
                  //   View Aadhar
                  //   <span className="material-symbols-outlined ">
                  //     north_east
                  //   </span>
                  // </a>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="sec_radious border p-3 mb-3">
          <h5 className="heading_five mb-0 pri_border_bottom pb-2 mb-3 text_gray">
            Subscription Details
          </h5>
          <div className="d-flex flex-column gap-3">
            {Array.isArray(subscription) && subscription?.length > 0 ? (
              subscription?.map((sub, index) =>
                sub.library === user_uid ? (
                  <div key={index} className="qr-code-container">
                    <p className="text_lightGray label_text mb-2 pb-1">
                      Start and End Date - <br />
                      <span className="para_text text_gray d-flex gap-2">
                        {new Date(sub.startDate).toLocaleDateString("en-GB", {
                          day: "numeric",
                          month: "short",
                          year: "numeric",
                        })}
                        <span className="material-symbols-outlined icon_dimention">
                          arrow_forward
                        </span>
                        {new Date(sub.endDate).toLocaleDateString("en-GB", {
                          day: "numeric",
                          month: "short",
                          year: "numeric",
                        })}
                      </span>
                    </p>
                    {sub.shift[0] === "Custom Shift" && (
                      <p className="text_lightGray label_text pb-1 mb-2">
                        Start and end time - <br />
                        <span className="para_text text_gray d-flex gap-2">
                          {sub?.startHour}:{sub?.startMinute} {sub?.startPeriod}{" "}
                          - {sub?.endHour}:{sub?.endMinute} {sub?.endPeriod}
                        </span>
                      </p>
                    )}
                    <p className="text_lightGray label_text pb-1 mb-2">
                      Seat No. & Shift - <br />
                      <span className="para_text text_gray d-flex gap-2">
                        {sub.seatNo} (
                        {Array.isArray(sub.shift)
                          ? sub.shift.sort().join(", ")
                          : sub.shift}
                        )
                      </span>
                    </p>
                    <p className="text_lightGray label_text pb-1">
                      Fee for this Duration - <br />
                      <span className="para_text text_gray d-flex gap-2">
                        ₹ {sub.fee} /-
                      </span>
                    </p>
                    <ReceiptButtons student={student} subscription={sub} />
                  </div>
                ) : null
              )
            ) : (
              <p className="text_gray">No subscription details available</p>
            )}
          </div>
        </div>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalStudentDetailComponent;
