import React, { useEffect, useState } from "react";
import { Provider, useDispatch, useSelector } from "react-redux";
import {
  BrowserRouter,
  Routes,
  Route,
  useLocation,
  useNavigate,
  Navigate,
} from "react-router-dom";
import store from "./store/store";
import { auth } from "./firebase/firebase.auth.config";
import HeaderComponent from "./components/landingPage/headerComponent";
import HeaderComponentDashboard from "./components/sidebar/headerComponentDashboard";
import { router } from "./router/router";
import SideBar from "./components/sidebar";
import Loader from "./sharedCOmponent/loader";
import { logoutAction, verifyAuthStatus } from "./action/login/loginAction";
import { fetchUserDetailAction } from "./action/newUser/newUserDetailAction";
import { fetchAllMemberDetailAction } from "./action/newMember/newMemberDetailAction";
import { fetchAllSubscriptionAction } from "./action/newSubscription/newSubscriptionDetailAction";
import { dashboardLocation } from "./util/utils";
import { Toaster } from "react-hot-toast";

// ProtectedRoute component
const ProtectedRoute = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const auth_uid = localStorage.getItem("auth_uid");
  const user_uid = localStorage.getItem("user_uid");
  const { isAuthChecked } = useSelector((state) => state.login);

  useEffect(() => {
    if (!auth_uid || !user_uid) {
      navigate("/login", { replace: true });
    }
  }, [auth_uid, user_uid, navigate]);

  if (!isAuthChecked) {
    return <Loader />;
  }

  return auth_uid && user_uid ? children : <Navigate to="/login" state={{ from: location }} replace />;
};

// LoginRoute component
const LoginRoute = ({ children }) => {
  const navigate = useNavigate();
  const auth_uid = localStorage.getItem("auth_uid");
  const user_uid = localStorage.getItem("user_uid");

  useEffect(() => {
    if (auth_uid && user_uid) {
      navigate("/dashboard", { replace: true });
    }
  }, [auth_uid, user_uid, navigate]);

  return children;
};

const Layout = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user_uid = localStorage.getItem("user_uid");
  const isDashboardPage = dashboardLocation?.includes(location.pathname);

  let Header;
  if (location.pathname === "/" || location.pathname === "/about") {
    Header = HeaderComponent;
  } else if (isDashboardPage) {
    if (!user_uid) {
      dispatch(logoutAction(() => navigate("/login")));
    } else {
      Header = SideBar;
    }
  }

  const renderRoutes = () => {
    return router.routes?.map((route) => {
      const RouteElement = route.protected ? (
        <ProtectedRoute>
          {route.element}
        </ProtectedRoute>
      ) : route.path === "/login" ? (
        <LoginRoute>
          {route.element}
        </LoginRoute>
      ) : route.element;

      return (
        <Route
          key={route.path}
          path={route.path}
          element={RouteElement}
        />
      );
    });
  };

  return (
    <>
      <Toaster />
      {isDashboardPage ? (
        <>
          <HeaderComponentDashboard />
          <div className="side_nav_container">
            <div className="side_nav p-0 border-right pt-md-4">
              <SideBar />
            </div>
            <div className="side_nav_containt p-md-0 p-2 ms-md-5 ms-md-4 pt-4">
              <Routes>{renderRoutes()}</Routes>
            </div>
          </div>
        </>
      ) : location.pathname === "/login" ? (
        <Routes>{renderRoutes()}</Routes>
      ) : (
        <>
          <HeaderComponent />
          <Routes>{renderRoutes()}</Routes>
        </>
      )}
    </>
  );
};

// Rest of the code remains the same...

const AuthProvider = ({ children }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const { isAuthChecked } = useSelector((state) => state.login);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((firebaseUser) => {
      setUser(firebaseUser);
      dispatch(verifyAuthStatus());
    });

    return () => unsubscribe();
  }, [dispatch]);

  const user_uid = localStorage.getItem("user_uid");
  const isDashboardPage = dashboardLocation?.includes(location.pathname);

  useEffect(() => {
    if (isAuthChecked && user && user_uid) {
      dispatch(fetchUserDetailAction());
      dispatch(fetchAllMemberDetailAction());
      dispatch(fetchAllSubscriptionAction());
    }
  }, [isAuthChecked, user, dispatch, user_uid]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2500);

    return () => clearTimeout(timer);
  }, []);

  if (
    (loading &&
      !isAuthChecked &&
      location.pathname !== "/" &&
      location.pathname !== "/about" &&
      location.pathname !== "/login" &&
      !location.pathname.startsWith("/student-form")) ||
    (user && !user_uid && isDashboardPage)
  ) {
    return <Loader />;
  }

  return children;
};

function App() {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <AuthProvider>
          <Layout />
        </AuthProvider>
      </BrowserRouter>
    </Provider>
  );
}

export default App; 