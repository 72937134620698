import React from "react";
import ProfileScreen from "./profileScreen";
import { useSelector } from "react-redux";
import { libraryPreDefinedTerms } from "../../util/utils";
import { db } from "../../firebase/firebase.auth.config";
import {
  collection,
  getDoc,
  doc,
  updateDoc,
  deleteField,
} from "firebase/firestore";

const drt = {
  display: true,
  required: true,
};
const drf = {
  display: true,
  required: false,
};
const dfr = {
  display: false,
  required: false,
};
const ProfilePage = () => {
  const newUserDetail = useSelector((state) => state.newUserDetail);
  const loaderFormLibrary = useSelector(
    (state) => state.newLibraryDetail?.loading
  );

  // const handleClick = async () => {
  //   try {
  //     const snapshot = await getDocs(collection(db, "owner"));
  //     const updatePromises = [];
  //     snapshot.forEach((document) => {
  //       const docRef = doc(db, "owner", document.id);
  //       updatePromises.push(
  //         updateDoc(docRef, {
  //           formFields: {
  //             name: drt,
  //             contactNum: drt,
  //             whatsappNum: drt,
  //             dateOfBirth: dfr,
  //             fatherName: dfr,
  //             qualification: dfr,
  //             emergencyContact: dfr,
  //             profielPic: dfr,
  //             email: dfr,
  //             aadharNumber: dfr,
  //             aadharImage: dfr,
  //             gender: drt,
  //             subscriptionStartDate: drt,
  //             subscriptionEndDate: drt,
  //             shift: drt,
  //             seatNo: drt,
  //             fee: drt,
  //             address: drf,
  //             goal: drf,
  //             paymentMethod: dfr,
  //             securityCharge: dfr,
  //             remarks: dfr,
  //           },
  //         })
  //       );
  //     });
  //     await Promise.all(updatePromises);
  //     console.log("All documents updated successfully!");
  //   } catch (error) {
  //     console.error("Error updating documents:", error);
  //   }
  // };
  // const handleClick = async () => {
  //   try {
  //     let docData = {};
  //     const docRef = doc(db, "owner", "0bd9bcc2-6eba-4315-a31e-2fdff4eff4dc");
  //     const docSnap = await getDoc(docRef);

  //     if (docSnap.exists()) {
  //       console.log("Document data:", docSnap.data());
  //       docData = docSnap.data();
  //     } else {
  //       // docSnap.data() will be undefined in this case
  //       console.log("No such document!");
  //     }
  //     let formFields = docData.library.formFields;
  //     delete docData.library.formFields;
  //     console.log("fff", formFields);
  //     await updateDoc(docRef, {
  //       // library[formFields]:deleteField(),
  //       // library: {
  //       //   ...docData.library,
  //       //   for/mFields: deleteField(),
  //       // },
  //       library: docData.library,
  //       formFields: formFields,
  //     });
  //   } catch (err) {}
  // };
  return (
    <>
      {/* <button onClick={handleClick}>Click me</button> */}
      <ProfileScreen
        owner_details={newUserDetail.data.user}
        terms={newUserDetail.data.libraryTerms || libraryPreDefinedTerms}
        loading={newUserDetail.loading}
        error={newUserDetail.error}
        library_details={newUserDetail.data.library}
        newUserDetail={newUserDetail.data}
        loaderFormLibrary={loaderFormLibrary}
        loaderFormPerson={newUserDetail?.loading}
      />
    </>
  );
};

export default ProfilePage;
