import { doc, updateDoc, setDoc, arrayUnion } from "firebase/firestore";
import { v4 as uuidv4 } from "uuid";
import { db, auth } from "../../firebase/firebase.auth.config";
import {
  ADD_BRANCH_REQUEST,
  ADD_BRANCH_SUCCESS,
  ADD_BRANCH_FAILURE,
} from "../actionType";

export const addNewBranchAction =
  ({ branchData, successCb, errorCb }) =>
  async (dispatch) => {
    dispatch({ type: ADD_BRANCH_REQUEST });

    try {
      const allBranchesUids =
        JSON.parse(localStorage.getItem("branches")) || [];
      // Generate a new UID for the new branch
      const newBranchUid = uuidv4();
      const auth_uid = localStorage.getItem("auth_uid");
      const ownerDoc = doc(db, "userAuth", auth_uid);

      // Update the `branches` array in the `userAuth` table
      await updateDoc(ownerDoc, {
        branches: arrayUnion(newBranchUid),
      });

      // Create a new branch in the `owner` table
      const defaultBranchData = {
        ...branchData,
        id: newBranchUid,
        timestamps: {
          firstAdded: new Date(),
          lastLogin: new Date(),
          lastLogout: null,
        },
        members: [],
        pending: [],
        approval: false,
        reject: false,
        library: {
          status: false,
          libraryName: branchData.library.libraryName || "",
        },
        counter: 0,
        options: [],
      };

      const newBranchDoc = doc(db, "owner", newBranchUid);
      await setDoc(newBranchDoc, defaultBranchData);

      dispatch({ type: ADD_BRANCH_SUCCESS, payload: defaultBranchData });

      allBranchesUids.push(newBranchUid);
      localStorage.setItem("branches", JSON.stringify(allBranchesUids));
      successCb(newBranchUid);
    } catch (error) {
      dispatch({ type: ADD_BRANCH_FAILURE, payload: error.message });
      errorCb(error);
    }
  };
