import React, { useState, useEffect, useCallback } from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart, ArcElement, Tooltip, Legend } from "chart.js";
Chart.register(ArcElement, Tooltip, Legend);

const PieChartComponent = ({
  data,
  mainColor,
  show,
  desc,
  cutout,
  pie_char_con_class,
}) => {
  const [selectedSegment, setSelectedSegment] = useState(null);
  const [total, setTotal] = useState(0);
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });

  const colors = mainColor || [];

  const chartData = {
    labels: Object.keys(data),
    datasets: [
      {
        data: Object.values(data),
        backgroundColor: colors,
        borderColor: ["#fff"],
        borderWidth: 1,
        hoverOffset: 10,
      },
    ],
  };

  // Only calculate the total when data changes, and don't call setState unnecessarily
  useEffect(() => {
    if (data) {
      const sum = Object.values(data).reduce((acc, value) => acc + value, 0);
      if (total !== sum) {
        setTotal(sum);
      }
    }
  }, [data, total]);

  // Memoize the hover handler and ensure state updates only when there's an actual change
  const handleHover = useCallback(
    (event, elements) => {
      if (elements?.length > 0) {
        const elementIndex = elements[0].index;
        const element = elements[0].element;
        const startAngle = element.startAngle;
        const endAngle = element.endAngle;
        const angle = (startAngle + endAngle) / 2;

        const radius = element.outerRadius;
        const x = element.x + radius * Math.cos(angle);
        const y = element.y + radius * Math.sin(angle);

        // Only update selectedSegment and tooltipPosition if the values have changed
        setSelectedSegment((prevSegment) => {
          const newSegment = {
            label: chartData.labels[elementIndex],
            value: chartData.datasets[0].data[elementIndex],
            color: colors[elementIndex],
          };

          if (
            prevSegment &&
            prevSegment.label === newSegment.label &&
            prevSegment.value === newSegment.value &&
            prevSegment.color === newSegment.color
          ) {
            return prevSegment; // No need to update if nothing changed
          }

          return newSegment;
        });

        setTooltipPosition((prevPosition) => {
          if (prevPosition.x === x && prevPosition.y === y) {
            return prevPosition; // No need to update if the position hasn't changed
          }

          return { x, y };
        });
      } else {
        setSelectedSegment(null);
      }
    },
    [chartData, colors]
  );

  if (!data) {
    return <div>No data available</div>;
  }

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    cutout: cutout || "75%",
    plugins: {
      tooltip: {
        enabled: false,
      },
      legend: {
        display: false,
      },
    },
    onHover: handleHover, // Use memoized hover function
    animation: {
      animateRotate: true,
      animateScale: true,
    },
  };

  return (
    <div
      className={`animate__animated animate__fadeIn h-75 ${pie_char_con_class}`}
    >
      <Doughnut data={chartData} options={options} />
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          textAlign: "center",
          fontSize: "24px",
          color: selectedSegment ? selectedSegment.color : mainColor,
        }}
      >
        {show
          ? selectedSegment
            ? selectedSegment.value.toLocaleString()
            : total.toLocaleString()
          : ""}
        {show && selectedSegment && (
          <span
            style={{
              marginTop: "8px",
              fontSize: "16px",
            }}
          >
            {`${selectedSegment.label} `}
          </span>
        )}
      </div>
      {selectedSegment && (
        <div
          style={{
            position: "absolute",
            top: tooltipPosition.y,
            left: tooltipPosition.x,
            transform: "translate(-50%, -50%)",
            backgroundColor: "rgba(255, 255, 255, 0.8)",
            padding: "4px 8px",
            borderRadius: "10px",
            backdropFilter: "blur(10px)",
            boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
            zIndex: 10,
            whiteSpace: "nowrap",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "5px",
            }}
          >
            <span
              style={{
                display: "inline-block",
                width: "12px",
                height: "12px",
                backgroundColor: selectedSegment.color,
                borderRadius: "50%",
              }}
            ></span>
            <span
              className="tool_pie"
              style={{
                fontSize: "12px",
                color: "#1f1f1f",
              }}
            >
              {selectedSegment.label} {selectedSegment.value.toLocaleString()}
            </span>
          </div>
        </div>
      )}
      {desc && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "20px",
          }}
        >
          {chartData.labels?.map((label, index) => (
            <div
              key={index}
              style={{
                display: "flex",
                alignItems: "center",
                marginRight: "10px",
                fontSize: "14px",
              }}
            >
              <span
                style={{
                  display: "inline-block",
                  width: "12px",
                  height: "12px",
                  backgroundColor: colors[index],
                  borderRadius: "50%",
                  marginRight: "5px",
                }}
              ></span>
              {label}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default PieChartComponent;
