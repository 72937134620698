import React, { useEffect, useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Button, Form as BootstrapForm } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { useDispatch } from "react-redux";
import { addMemberDetailAction } from "../../action/newMember/newMemberDetailAction";
import { fetchUserDetailAction } from "../../action/newUser/newUserDetailAction";
import {
  customStyles,
  getOptions,
  hours,
  minutes,
  periods,
} from "../../util/utils";
import { formSeats2 } from "../../util/checkSeatAvaFunction";

const CustomShiftAddStudent = ({
  onClose,
  dropdownData,
  seats,
  library_details,
  members,
}) => {
  const userUid = localStorage.getItem("user_uid");
  const dispatch = useDispatch();
  const [sameAsMobile, setSameAsMobile] = useState(false);
  const [loader, setLoader] = useState(false);

  // State hooks for start date & end date
  const [startDateSeatCal, setStartDateSeatCal] = useState();
  const [endDateSeatCal, setEndDateSeatCal] = useState();

  // State hooks for start time
  const [startHourCustomSeat, setStartHourCustomSeat] = useState(7);
  const [startMinuteCustomSeat, setStartMinuteCustomSeat] = useState(15);
  const [startPeriodCustomSeat, setStartPeriodCustomSeat] = useState("PM");

  // State hooks for end time
  const [endHourCustomSeat, setEndHourCustomSeat] = useState(11);
  const [endMinuteCustomSeat, setEndMinuteCustomSeat] = useState(45);
  const [endPeriodCustomSeat, setEndPeriodCustomSeat] = useState("AM");

  // seat options
  const [seatOption, setSeatOption] = useState();

  const goalOptions = getOptions("Goals", dropdownData);

  const animatedComponents = makeAnimated();

  useEffect(() => {
    if (
      startDateSeatCal &&
      endDateSeatCal &&
      startHourCustomSeat &&
      startMinuteCustomSeat &&
      startPeriodCustomSeat &&
      endHourCustomSeat &&
      endMinuteCustomSeat &&
      endPeriodCustomSeat
    ) {
      const freeSeats = formSeats2(
        library_details,
        members,
        seats,
        startDateSeatCal,
        endDateSeatCal,
        startHourCustomSeat,
        startMinuteCustomSeat,
        startPeriodCustomSeat,
        endHourCustomSeat,
        endMinuteCustomSeat,
        endPeriodCustomSeat
      );
      setSeatOption(freeSeats);
    }
  }, [
    startDateSeatCal,
    endDateSeatCal,
    startHourCustomSeat,
    startMinuteCustomSeat,
    startPeriodCustomSeat,
    endHourCustomSeat,
    endMinuteCustomSeat,
    endPeriodCustomSeat,
  ]);

  // Formik initial values
  const initialValues = {
    name: "",
    mobile: "",
    whatsapp: "",
    email: "",
    gender: "",
    subscription: [
      {
        seatNo: "",
        shift: ["Custom Shift"],
        startDate: null,
        endDate: null,
        fee: "",
        startHour: 7,
        startMinute: 15,
        startPeriod: "AM",
        endHour: 11,
        endMinute: 45,
        endPeriod: "AM",
      },
    ],
    address: "",
    goal: "",
    approved: true,
    aadhar_number: "",
  };

  // Yup validation schema
  const validationSchema = Yup.object({
    name: Yup.string().required("Name is required"),
    mobile: Yup.string()
      .matches(/^\d+$/, "Mobile number must be numeric")
      ?.length(10, "Mobile number must be exactly 10 digits")
      .required("Mobile number is required"),
    whatsapp: Yup.string()
      .matches(/^\d+$/, "Mobile number must be numeric")
      ?.length(10, "Mobile number must be exactly 10 digits")
      .required("Mobile number is required"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    gender: Yup.string().required("Gender is required"),
    subscription: Yup.array().of(
      Yup.object().shape({
        seatNo: Yup.string().required("Seat number is required"),
        startDate: Yup.date().required("Start date is required"),
        endDate: Yup.date()
          .required("End date is required")
          .min(Yup.ref("startDate"), "End date must be after start date"),
        fee: Yup.number().required("Fee is required"),
        startHour: Yup.number().required("Start hour is required"),
        startMinute: Yup.number().required("Start minute is required"),
        startPeriod: Yup.string().required("Start period is required"),
        endHour: Yup.number().required("End hour is required"),
        endMinute: Yup.number().required("End minute is required"),
        endPeriod: Yup.string().required("End period is required"),
      })
    ),
    address: Yup.string().required("Address is required"),
    goal: Yup.string(),
  });

  // Convert the date to ISO format string for backend submission
  const formatDateToISO = (date) => {
    return date ? new Date(date).toISOString() : "";
  };

  const successCb = (data) => {
    dispatch(fetchUserDetailAction());
    setLoader(false);
    onClose();
  };
  const errorCb = (error) => {
    console.log(error);
    setLoader(false);
  };

  const handleFormSubmit = (values) => {
    const formattedValues = {
      ...values,
      subscription: values.subscription?.map((sub) => ({
        ...sub,
        startDate: formatDateToISO(sub.startDate),
        endDate: formatDateToISO(sub.endDate),
      })),
    };
    dispatch(
      addMemberDetailAction(formattedValues, successCb, errorCb, userUid)
    );
    setLoader(true);
  };
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleFormSubmit}
    >
      {({ setFieldValue, errors, touched, values }) => (
        <Form className="row gap-3">
          <BootstrapForm.Group className="col-12">
            <BootstrapForm.Label className="label_text">
              Name
            </BootstrapForm.Label>
            <Field
              name="name"
              type="text"
              className="formBodyControlModal form-control input_feild"
              placeholder="Enter your name"
            />
            {errors.name && touched.name ? (
              <div className="text-danger">{errors.name}</div>
            ) : null}
          </BootstrapForm.Group>
          <BootstrapForm.Group className="col-12">
            <BootstrapForm.Label className="label_text">
              Mobile
            </BootstrapForm.Label>
            <Field
              name="mobile"
              type="text"
              className="formBodyControlModal form-control input_feild"
              placeholder="Enter your mobile number"
              onChange={(e) => {
                setFieldValue("mobile", e.target.value);
                if (sameAsMobile) setFieldValue("whatsapp", e.target.value);
              }}
            />
            {errors.mobile && touched.mobile ? (
              <div className="text-danger">{errors.mobile}</div>
            ) : null}
          </BootstrapForm.Group>
          <BootstrapForm.Group className="col-12">
            <BootstrapForm.Label className="label_text">
              WhatsApp
            </BootstrapForm.Label>
            <Field
              name="whatsapp"
              type="text"
              className="formBodyControlModal form-control input_feild"
              placeholder="Enter your WhatsApp number"
            />
            <BootstrapForm.Check
              type="checkbox"
              label="Same as mobile"
              onChange={() => {
                setSameAsMobile(!sameAsMobile);
                if (!sameAsMobile) {
                  setFieldValue("whatsapp", values.mobile);
                }
              }}
              checked={sameAsMobile}
            />
            {errors.whatsapp && touched.whatsapp ? (
              <div className="text-danger">{errors.whatsapp}</div>
            ) : null}
          </BootstrapForm.Group>
          <BootstrapForm.Group className="col-12">
            <BootstrapForm.Label className="label_text">
              Aadhar number <span className="pri_text"> (Optional) </span>
            </BootstrapForm.Label>
            <Field
              name="aadhar_number"
              type="number"
              className="formBodyControlModal form-control input_feild"
              placeholder="Enter aadhar"
            />
          </BootstrapForm.Group>
          <BootstrapForm.Group className="col-12">
            <BootstrapForm.Label className="label_text">
              Email
            </BootstrapForm.Label>
            <Field
              name="email"
              type="email"
              className="formBodyControlModal form-control input_feild"
              placeholder="Enter your email"
            />
            {errors.email && touched.email ? (
              <div className="text-danger">{errors.email}</div>
            ) : null}
          </BootstrapForm.Group>
          <BootstrapForm.Group className="col-12">
            <BootstrapForm.Label className="label_text">
              Gender
            </BootstrapForm.Label>
            <div className="d-flex gap-4">
              <label className="mb-0 d-flex gap-2">
                <Field type="radio" name="gender" value="Male" />
                Male
              </label>
              <label className="mb-0 d-flex gap-2">
                <Field type="radio" name="gender" value="Female" />
                Female
              </label>
            </div>
            {errors.gender && touched.gender ? (
              <div className="text-danger">{errors.gender}</div>
            ) : null}
          </BootstrapForm.Group>

          <BootstrapForm.Group className="col-12 px-3">
            <BootstrapForm.Label className="label_text w-100">
              Subscription start date
            </BootstrapForm.Label>
            <DatePicker
              selected={values.subscription[0].startDate}
              onChange={(date) => (
                setFieldValue("subscription[0].startDate", date),
                setStartDateSeatCal(date)
              )}
              selectsStart
              startDate={values.subscription[0].startDate}
              endDate={values.subscription[0].endDate}
              dateFormat="dd/MM/yyyy"
              placeholderText="Start Date"
              className={`form-control input_feild ${
                errors.subscription?.[0]?.startDate &&
                touched.subscription?.[0]?.startDate
                  ? "is-invalid"
                  : ""
              }`}
            />
          </BootstrapForm.Group>
          <BootstrapForm.Group className="col-12 px-3">
            <BootstrapForm.Label className="label_text w-100">
              Subscription end date
            </BootstrapForm.Label>
            <DatePicker
              selected={values.subscription[0].endDate}
              onChange={(date) => (
                setFieldValue("subscription[0].endDate", date),
                setEndDateSeatCal(date)
              )}
              selectsEnd
              startDate={values.subscription[0].startDate}
              endDate={values.subscription[0].endDate}
              minDate={values.subscription[0].startDate}
              dateFormat="dd/MM/yyyy"
              placeholderText="End Date"
              className={`form-control input_feild ${
                errors.subscription?.[0]?.endDate &&
                touched.subscription?.[0]?.endDate
                  ? "is-invalid"
                  : ""
              }`}
            />
          </BootstrapForm.Group>

          <div className="d-flex flex-wrap px-3 ">
            {/* Start Time */}
            <div className="w-50 pe-3">
              <label className="label_text">Start time</label>
              <div className="d-flex">
                <Field
                  as="select"
                  name="subscription[0].startHour"
                  className="form-control me-2"
                  value={values.subscription[0]?.startHour}
                  onChange={(e) => {
                    const val = e.target.value;
                    setStartHourCustomSeat(val);
                    setFieldValue("subscription[0].startHour", val);
                  }}
                >
                  {hours?.map((hour) => (
                    <option key={hour} value={hour}>
                      {hour}
                    </option>
                  ))}
                </Field>

                <Field
                  as="select"
                  name="subscription[0].startMinute"
                  className="form-control me-2"
                  value={values.subscription[0].startMinute}
                  onChange={(e) => {
                    const val = e.target.value;
                    setStartMinuteCustomSeat(val);
                    setFieldValue("subscription[0].startMinute", val);
                  }}
                >
                  {minutes?.map((minute) => (
                    <option key={minute} value={minute}>
                      {minute.toString().padStart(2, "0")}
                    </option>
                  ))}
                </Field>

                <Field
                  as="select"
                  name="subscription[0].startPeriod"
                  className="form-control me-0"
                  value={values.subscription[0].startPeriod}
                  onChange={(e) => {
                    const val = e.target.value;
                    setStartPeriodCustomSeat(val);
                    setFieldValue("subscription[0].startPeriod", val);
                  }}
                >
                  {periods?.map((period) => (
                    <option key={period} value={period}>
                      {period}
                    </option>
                  ))}
                </Field>
              </div>
              <ErrorMessage
                name="subscription[0].startHour"
                component="div"
                className="text-danger"
              />
              <ErrorMessage
                name="subscription[0].startMinute"
                component="div"
                className="text-danger"
              />
              <ErrorMessage
                name="subscription[0].startPeriod"
                component="div"
                className="text-danger"
              />
            </div>

            {/* End Time */}
            <div className="w-50 ps-3">
              <label className="label_text">End time</label>
              <div className="d-flex">
                <Field
                  as="select"
                  name="subscription[0].endHour"
                  className="form-control me-2"
                  value={values.subscription[0].endHour}
                  onChange={(e) => {
                    const val = e.target.value;
                    setEndHourCustomSeat(val);
                    setFieldValue("subscription[0].endHour", val);
                  }}
                >
                  {hours?.map((hour) => (
                    <option key={hour} value={hour}>
                      {hour}
                    </option>
                  ))}
                </Field>

                <Field
                  as="select"
                  name="subscription[0].endMinute"
                  className="form-control me-2"
                  value={values.subscription[0].endMinute}
                  onChange={(e) => {
                    const val = e.target.value;
                    setEndMinuteCustomSeat(val);
                    setFieldValue("subscription[0].endMinute", val);
                  }}
                >
                  {minutes?.map((minute) => (
                    <option key={minute} value={minute}>
                      {minute.toString().padStart(2, "0")}
                    </option>
                  ))}
                </Field>

                <Field
                  as="select"
                  name="subscription[0].endPeriod"
                  className="form-control me-0"
                  value={values.subscription[0].endPeriod}
                  onChange={(e) => {
                    const val = e.target.value;
                    setEndPeriodCustomSeat(val);
                    setFieldValue("subscription[0].endPeriod", val);
                  }}
                >
                  {periods?.map((period) => (
                    <option key={period} value={period}>
                      {period}
                    </option>
                  ))}
                </Field>
              </div>
              <ErrorMessage
                name="subscription[0].endHour"
                component="div"
                className="text-danger"
              />
              <ErrorMessage
                name="subscription[0].endMinute"
                component="div"
                className="text-danger"
              />
              <ErrorMessage
                name="subscription[0].endPeriod"
                component="div"
                className="text-danger"
              />
            </div>
          </div>

          <BootstrapForm.Group className="col-12">
            <BootstrapForm.Label className="label_text">
              Fee
            </BootstrapForm.Label>
            <Field
              name="subscription[0].fee"
              type="text"
              className="formBodyControlModal form-control input_feild"
              placeholder="Enter Fee Amount"
            />
            {errors.subscription?.[0]?.fee && touched.subscription?.[0]?.fee ? (
              <div className="text-danger">{errors.subscription[0].fee}</div>
            ) : null}
          </BootstrapForm.Group>

          <BootstrapForm.Group className="col-12">
            <BootstrapForm.Label className="label_text">
              Seat No.
            </BootstrapForm.Label>
            <Select
              options={seatOption?.map((seat) => ({
                value: seat.seatNo,
                label: seat.seatNo,
              }))}
              className="formBodyControlModal"
              components={animatedComponents}
              placeholder="Select seat no."
              onChange={(e) => {
                const val = e.value;
                setFieldValue("subscription[0].seatNo", val);
              }}
              styles={customStyles}
            />
            <ErrorMessage
              name="subscription[0].seatNo"
              component="div"
              className="text-danger"
            />
          </BootstrapForm.Group>

          <BootstrapForm.Group className="col-12">
            <BootstrapForm.Label className="label_text">
              Address
            </BootstrapForm.Label>
            <Field
              name="address"
              as="textarea"
              className="formBodyControlModal form-control input_feild"
              placeholder="Enter your address"
            />
            {errors.address && touched.address ? (
              <div className="text-danger">{errors.address}</div>
            ) : null}
          </BootstrapForm.Group>
          <BootstrapForm.Group className="col-12">
            <BootstrapForm.Label className="label_text">
              Goal
            </BootstrapForm.Label>
            <Select
              options={goalOptions}
              className="formBodyControlModal"
              components={animatedComponents}
              placeholder="Select Goal"
              onChange={(option) => setFieldValue("goal", option.value)}
              styles={customStyles}
            />
          </BootstrapForm.Group>

          <div className="col-12 d-flex border-0 gap-2 outline-0 justify-content-between my-2 ">
            <Button
              className="button_design w-50"
              variant="outline"
              onClick={onClose}
            >
              Cancel
            </Button>

            <Button
              className="pri_bg button_design w-50"
              variant="secondary"
              type="submit"
            >
              {loader ? (
                <>
                  <div class="spinner-border spinner-border-btn" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                </>
              ) : (
                "Add Student"
              )}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default CustomShiftAddStudent;
