import React from 'react';
import MessageNotoficationScreen from './messagenotificationScreen';

const IndexMessageNotification = () => {
    return (
        <div>
            <MessageNotoficationScreen/>
        </div>
    );
}

export default IndexMessageNotification;
