import React from "react";

const WhyChooseUs = () => {
  const cardsData = [
    {
      title: "Free to Use",
      description:
        "Enjoy all features without any cost. Our software is designed to help everyone easily.",
    },
    {
      title: "Track Student Activity",
      description:
        "Easily monitor how many students are active each day to stay informed and organized.",
    },
    {
      title: "User-Friendly Interface",
      description:
        "Navigate the software with ease. Our simple design makes it accessible for everyone.",
    },
    {
      title: "Manage Subscriptions",
      description:
        "View upcoming subscription renewals and easily send reminders to students.",
    },
    {
      title: "24/7 Support",
      description: "Our dedicated team is here to help you around the clock.",
    },
    {
      title: "Simple Sign-Up",
      description:
        "Students can quickly join the library using a QR code for hassle-free access.",
    },
  ];

  return (
    <div className="why-choose-us pt-5 pb-5">
      <div className="container">
        <div className="section-head text-center mb-4">
          <h4>
            <span>Why Choose</span> Us?
          </h4>
          <p className="section-subtitle">
            Choose us for user-friendly software, expert support, and free
            access, ensuring an easy experience for managing your library
            effectively.
          </p>
        </div>
        <div className="ag-format-container m-auto">
          <div className="ag-courses_box p-0 row m-auto">
            {cardsData?.map((card, index) => (
              <div
                key={index}
                className="ag-courses_item animate__animated animate__fadeInUp animate__delay-1s col-md-4 col-12 p-0 "
              >
                <div className="ag-courses-item_link mx-0 my-2 m-md-2 ">
                  <div className="ag-courses-item_bg"></div>
                  <div className="ag-courses-item_title">{card.title}</div>
                  <div className="ag-courses-item_date-box">
                    {card.description}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhyChooseUs;
