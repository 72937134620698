import React from 'react';
import HelpAndSupport from './helpScreen';

const IndexHelp = () => {
    return (
        <div>
            <HelpAndSupport/>
        </div>
    );
}

export default IndexHelp;
