import React, { useEffect, useState } from "react";
import { createBrowserRouter, Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import LoginForm from "../screen/login";
import Dashboard from "../screen/dashboard/index";
import LandingPage from "../screen/landingPage";
import AboutPageComponent from "../components/landingPage/aboutPageComponent";
import Loader from "../sharedCOmponent/loader";
import ProfilePage from "../screen/profile";
import AddStudentQr from "../components/addStudentQr";
import StudentsDetails from "../screen/studentDetail/index";
import { verifyAuthStatus } from "../action/login/loginAction";
import { StudentFormSubmit } from "../components/studentFormSubmit";
import SeatMapIndex from "../screen/seatMap";
import IndexMessageNotification from "../screen/messageNotificaion";
import IndexHelp from "../screen/help";

const PrivateRoute = ({ children }) => {
  const dispatch = useDispatch();
  const { user, isAuthChecked, loading } = useSelector((state) => state.login);

  useEffect(() => {
    if (!isAuthChecked) {
      dispatch(verifyAuthStatus());
    }
  }, [dispatch, isAuthChecked]);

  if (loading) {
    return <Loader />;
  }

  return isAuthChecked && user ? children : <Navigate to="/login" />;
};

export const router = createBrowserRouter([
  {
    path: "/",
    element: <LandingPage />,
  },
  {
    path: "/student-form/:library_name/:user_uid",
    element: <AddStudentQr />,
  },
  {
    path: "/student-form-submitted/:library_name/:data_name",
    element: <StudentFormSubmit />,
  },
  {
    path: "/about",
    element: <AboutPageComponent />,
  },
  {
    path: "/login",
    element: <LoginForm />,
  },
  {
    path: "/dashboard",
    element: (
      <PrivateRoute>
        <Dashboard />
      </PrivateRoute>
    ),
  },
  {
    path: "/students",
    element: (
      <PrivateRoute>
        <StudentsDetails />
      </PrivateRoute>
    ),
  },
  {
    path: "/profile",
    element: (
      <PrivateRoute>
        <ProfilePage />
      </PrivateRoute>
    ),
  },
  {
    path: "/seats",
    element: (
      <PrivateRoute>
        <SeatMapIndex />
      </PrivateRoute>
    ),
  },
  {
    path: "/message",
    element: (
      <PrivateRoute>
        <IndexMessageNotification />
      </PrivateRoute>
    ),
  },
  {
    path: "/help",
    element: (
      <PrivateRoute>
        <IndexHelp />
      </PrivateRoute>
    ),
  },
]);
