import React from "react";
import StudentScreen from "./studentScreen";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

function StudentsDetails() {
  const newUserDetail = useSelector((state) => state.newUserDetail);
  const newSubsDetail = useSelector((state) => state.newSubscriptionDetail);
  return (
    <div>
      <StudentScreen
        loading={newUserDetail.loading}
        error={newUserDetail.error}
        student_details={newUserDetail.data.members}
        newUserDetail={newUserDetail}
        newSubsDetail={newSubsDetail}
      />
    </div>
  );
}

export default StudentsDetails;
