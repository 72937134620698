import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

function TermsModalExample({ terms }) {
  const [fullscreen, setFullscreen] = useState(false);
  const [show, setShow] = useState(false); 
  // Open modal on button click
  const handleShow = () => {
    setFullscreen(false);
    setShow(true);
  };

  return (
    <>
      {/* Button that triggers the modal */}
      <button
        onClick={handleShow}
        className="terms-btn text-primary bg-transparent px-0"
      >
        terms and conditions
      </button>

      {/* Modal to display terms */}
      <Modal
        show={show}
        fullscreen={fullscreen}
        onHide={() => setShow(false)}
        centered
        size="lg"
      >
        <Modal.Header closeButton className="modal-header">
          <Modal.Title className="modal-title">
            <i className="bi bi-file-earmark-lock"></i> Terms and Conditions
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-body">
          <div
            className="terms-content"
            dangerouslySetInnerHTML={{
              __html: terms, // Show terms as HTML content
            }}
          />
        </Modal.Body>
        <Modal.Footer className="modal-footer">
          <Button variant="secondary" onClick={() => setShow(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default TermsModalExample;
