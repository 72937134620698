import {
  ADD_SUBSCRIPTION_DETAIL_REQUEST,
  ADD_SUBSCRIPTION_DETAIL_SUCCESS,
  ADD_SUBSCRIPTION_DETAIL_FAILURE,
  FETCH_ALL_SUBSCRIPTION_REQUEST,
  FETCH_ALL_SUBSCRIPTION_SUCCESS,
  FETCH_ALL_SUBSCRIPTION_FAILURE,
} from "../../action/actionType";

const initialState = {
  loading: false,
  data: {},
  error: "",
};

export const newSubscriptionDetailReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_SUBSCRIPTION_DETAIL_REQUEST:
    case FETCH_ALL_SUBSCRIPTION_REQUEST:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case ADD_SUBSCRIPTION_DETAIL_SUCCESS:
    case FETCH_ALL_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: "",
      };
    case ADD_SUBSCRIPTION_DETAIL_FAILURE:
    case FETCH_ALL_SUBSCRIPTION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
