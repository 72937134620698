import React from "react";
import { useSelector } from "react-redux";
import DashboardScreen from "./dashboardScreen";

function Dashboard() {
  const newUserDetail = useSelector((state) => state.newUserDetail);

  return (
    <div className="dashboard_all_container me-2 ms-2 ms-md-0 me-md-0">
      <DashboardScreen
        loading={newUserDetail.loading}
        error={newUserDetail.error}
        student_details={newUserDetail.data.members}
        owner_details={newUserDetail.data.user}
        library_details={newUserDetail.data.library}
        pending_students={newUserDetail.data.pending}
        newUserDetail={newUserDetail}
        newOption={newUserDetail?.data?.options}
      />
    </div>
  );
}

export default Dashboard;
