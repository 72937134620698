import React, { useState } from "react";

const HelpAndSupport = () => {
  const [expandedFaq, setExpandedFaq] = useState(null);

  const faqs = [
    {
      id: 1,
      question: "What is this software about?",
      answer:
        "This is a free library dashboard management software designed to help library owners manage student details, subscriptions, seat availability, and more in an efficient and user-friendly way.",
    },
    {
      id: 2,
      question: "Is this software free to use?",
      answer: "Yes, the software is completely free to use.",
    },
    {
      id: 3,
      question: "What do I need to do to get started?",
      answer:
        "To get started, enter your personal details and basic library information, such as shift durations and the number of seats. Once submitted, your details will be sent to us for verification. After we verify your information, you can begin using the dashboard's features. If verification is pending for an extended period, please contact us via the provided email or phone number.",
    },
    {
      id: 4,
      question: "How can I add student details to the library system?",
      answer:
        "You can either add student details manually through the dashboard or allow students to scan a QR code, fill out a form, and submit their details for approval.",
    },
    {
      id: 5,
      question:
        "What happens when a student submits their details using the QR code?",
      answer:
        "The student's request will appear on the library owner's dashboard, where it can be approved or declined.",
    },
    {
      id: 6,
      question: "Can I see which students’ subscriptions are about to expire?",
      answer:
        "Yes, the dashboard shows a list of students whose subscriptions are nearing expiration, allowing you to take proactive actions.",
    },
    {
      id: 7,
      question: "How does the seat management system work?",
      answer:
        "The software provides a seat-wise availability overview, showing the total seats, booked seats, and vacant seats in real-time.",
    },
    {
      id: 8,
      question: "Do I need any special technical skills to use the software?",
      answer:
        "No, the software is designed to be intuitive and easy to use, even for non-technical users.",
    },
    {
      id: 9,
      question: "Is the software mobile-friendly?",
      answer:
        "Yes, the software is optimized to work seamlessly across desktop, tablet, and mobile devices.",
    },
    {
      id: 10,
      question: "How secure is the student data stored in the system?",
      answer:
        "We prioritize data security and use encryption to ensure that all personal and subscription details are securely stored.",
    },
    {
      id: 11,
      question: "Can I update my library details after signing up?",
      answer:
        "Yes, you can update your library details, such as shift durations or the number of seats, at any time through the settings menu.",
    },
    {
      id: 12,
      question: "Can I export or back up the student details?",
      answer:
        "Currently, exporting data is not available, but we are working to include this feature in future updates.",
    },
    {
      id: 13,
      question: "How do I contact support if I face any issues?",
      answer:
        "You can reach out to our support team through the 'Contact Us' section of the website or via email.",
    },
    {
      id: 14,
      question:
        "Are there any limits on the number of students or seats I can manage?",
      answer:
        "No, there are no limits as of now. You can manage as many students and seats as your library requires.",
    },
  ];

  const contactMethods = [
    {
      id: 2,
      title: "Chat to support",
      description: "We're here to help.",
      contact: "librifyin@gmail.com",
      icon: "mail",
    },
    {
      id: 3,
      title: "Whatsapp Support",
      description: "Chat on WhatsApp.",
      contact: "+91-9910564550",
      icon: "support_agent",
    },
    {
      id: 4,
      title: "Call Support",
      description: "Contact us in emergency situation",
      contact: "+91-9050076625",
      icon: "call",
    },
  ];

  return (
    <div className="me-2 ms-2 ms-md-0 me-md-5">
      <div className="d-flex align-items-center justify-content-between gap-md-0 gap-3 mb-2 mt-md-4 flex-wrap">
        <h1 className="heading_three text-nowrap">
          Contact our friendly team{" "}
        </h1>
      </div>

      <div className="help-support-container">
        <div className="bg-pattern"></div>
        <div className="content-wrapper">
          <div className="contact-grid">
            {contactMethods.map((method) => (
              <div key={method.id} className="contact-card">
                <span className="material-symbols-outlined card-icon">
                  {method.icon}
                </span>
                <h3>{method.title}</h3>
                <p>{method.description}</p>
                <a
                  href="#"
                  className="d-flex align-items-center justify-center"
                >
                  {method.contact}
                  <span className="material-symbols-outlined link-icon">
                    chevron_right
                  </span>
                </a>
              </div>
            ))}
          </div>

          <div className="faq-section">
            <div className="d-flex align-items-center justify-content-between gap-md-0 gap-3 mb-2 mt-md-4 flex-wrap">
              <h5 className="heading_four text-nowrap">
                Frequently asked questions
              </h5>
            </div>
            <div className="faq-list">
              {faqs.map((faq) => (
                <div
                  key={faq.id}
                  className={`faq-item ${
                    expandedFaq === faq.id ? "expanded" : ""
                  }`}
                  onClick={() =>
                    setExpandedFaq(expandedFaq === faq.id ? null : faq.id)
                  }
                >
                  <div className="faq-question">
                    <h3>{faq.question}</h3>
                    <span className="material-symbols-outlined">
                      {expandedFaq === faq.id ? "remove" : "add"}
                    </span>
                  </div>
                  <div className="faq-answer">
                    <p>{faq.answer}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HelpAndSupport;
