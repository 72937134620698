import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import SeatMapScreen from "./seatMapScreen";
import { getSeatStatuses } from "../../util/seatDetailsCalculationFunction";
import { useSelector } from "react-redux";

const SeatMapIndex = () => {
  const newUserDetailAPI = useSelector((state) => state.newUserDetail);
  const newUserDetail = newUserDetailAPI?.data;
  const libraryData = newUserDetail?.library;
  const memberData = newUserDetail?.members;
  const seatUsage = JSON.parse(getSeatStatuses(libraryData, memberData)); 
  
  return (
    <div className="App">
      <SeatMapScreen
        seats={seatUsage}
        loading={newUserDetailAPI?.loading}
        error={newUserDetailAPI?.error}
        approval={newUserDetail?.approval}
      />
    </div>
  );
};

export default SeatMapIndex;
