import React from "react";
import FAQ from "react-faq-component";

const data = {
  rows: [
    {
      title: "Is this free?",
      content:
        "Yes, our software is completely free to use with all its features.",
    },
    {
      title: "What is the QR-related system?",
      content:
        "The QR system allows students to easily join your library by scanning a QR code. It simplifies registration and approval.",
    },
    {
      title: "Can I download my student list?",
      content:
        "Yes, you can easily download the full list of your students anytime from the dashboard.",
    },
    {
      title: "Is my data secure?",
      content:
        "Yes, we ensure your data is protected with strong security measures.",
    },
    {
      title: "Can I add multiple shifts?",
      content:
        "Yes, you can easily manage multiple shifts and assign students accordingly.",
    },
    {
      title: "How do I access the application?",
      content:
        "It's easy! Just follow these three steps:\n1. Login with OTP\n2. Fill in library and owner details, then wait for approval\n3. Once approved, start using the app",
    },
    // {
    //   title: "How do I manage payments?",
    //   content:
    //     "Payments are tracked and managed directly through the platform, ensuring you receive your earnings securely and on time.",
    // },
    // {
    //   title: "Can I manage more than one reading room?",
    //   content:
    //     "Yes, you can manage multiple reading rooms under one account, making it easy to oversee all locations.",
    // },
    {
      title: "What happens if I need help?",
      content:
        "Our 24/7 support team is always available to assist you with any questions or issues.",
    },
  ],
};

const CustomFAQ = () => {
  return (
    <section className="faq-container container bg-white py-5 animate__animated animate__fadeIn">
      <div className="section-head text-center mb-4">
        <h4 className="font_family">Frequently Asked Questions</h4>
        <p className="section-subtitle font_family">
          When you choose our software, you benefit from a user-friendly
          platform, designed to make managing your library simple and efficient.
        </p>
      </div>
      <FAQ
        data={data}
        className="font_family"
        styles={{
          bgColor: "white",
          rowTitleColor: "#484848",
          rowContentColor: "#787878",
          arrowColor: "#5cb85c",
        }}
        config={{
          animate: true,
          arrowIcon: "V",
        }}
      />
    </section>
  );
};

export default CustomFAQ;
