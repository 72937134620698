import React from "react";
import SidebarScreen from "./sidebarScreen";

const SideBar = () => {
  return (
    <div>
      <SidebarScreen />
    </div>
  );
};

export default SideBar;
