import toast from "react-hot-toast";
import { PDFDocument } from "pdf-lib";
import imageCompression from "browser-image-compression";

// Custom style for react-select dropdown
export const customStyles = {
  control: (provided) => ({
    ...provided,
    minHeight: "48px",
    height: "48px",
    borderRadius: "8px",
    borderColor: "#ced4da",
    boxShadow: "none",
    padding: "0px 8px",
  }),
  valueContainer: (provided) => ({
    ...provided,
    height: "48px",
    padding: "0 6px",
  }),
  input: (provided) => ({
    ...provided,
    margin: "0px",
    paddingTop: "0px",
    paddingBottom: "0px",
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    height: "48px",
  }),
};

// dashboard locations

export const dashboardLocation = [
  "/dashboard",
  "/students",
  "/profile",
  "/seats",
  "/message",
  "/help",
];

export const shiftOptions = [
  "Morning Shift",
  "Evening Shift",
  "Night Shift",
  "Full Day Shift",
];

export const hours = Array.from({ length: 12 }, (_, i) => i + 1);
export const minutes = Array.from({ length: 4 }, (_, i) => i * 15);
export const periods = ["AM", "PM"];

// Dropdown options for react-select
export const getOptions = (category, dropdownData) =>
  dropdownData
    ?.find((item) => item.category === category)
    ?.options?.map((option) => ({
      label: option,
      value: option,
    })) || [];

export const transformShiftsOptions = (data) => {
  return data?.map((item) => {
    const formatTime = (hour, minute, period) => {
      return `${hour}:${minute.toString().padStart(2, "0")} ${period}`;
    };

    const shiftKey = item?.shiftName?.replace(/\s+/g, "");
    const startTime = formatTime(
      item?.startHour,
      item?.startMinute,
      item?.startPeriod
    );
    const endTime = formatTime(item.endHour, item.endMinute, item.endPeriod);

    return {
      shift: shiftKey,
      label: `${item.shiftName} (${startTime} - ${endTime})`,
      value: `${item.shiftName} (${startTime} - ${endTime})`,
      startHour: parseInt(item.startHour, 10),
      startMinute: parseInt(item.startMinute, 10),
      startPeriod: item.startPeriod,
      endHour: parseInt(item.endHour, 10),
      endMinute: parseInt(item.endMinute, 10),
      endPeriod: item.endPeriod,
    };
  });
};

export function getFilterShifts(searchTerms, shiftData) {
  return shiftData?.filter((shift) =>
    searchTerms.some((term) => shift.label.includes(term.trim()))
  );
}

// Image compression options
export const imageOptions = {
  maxSizeMB: 0.01, // 10KB
  maxWidthOrHeight: 800,
  useWebWorker: true,
};

// Handle image compression
// export const handleImageCompress = async (
//   imageFile,
//   imageCompression,
//   setImagePreview
// ) => {
//   try {
//     // Validate file type
//     if (!imageFile.type.startsWith("image/")) {
//       toast.error("Please upload a valid image file");
//       return null;
//     }

//     const compressedFile = await imageCompression(imageFile, {
//       ...imageOptions,
//       onProgress: (progress) => {
//         console.log("Compression progress:", progress);
//       },
//     });

//     setImagePreview(URL.createObjectURL(compressedFile));
//     return compressedFile;
//   } catch (error) {
//     console.error("Error compressing image:", error);
//     toast.error(
//       "Error processing image. Please try again with a different image."
//     );
//     return null;
//   }
// };

export const handleImageCompress = async (file, setImagePreview) => {
  try {
    // Validate file type
    if (!file.type.startsWith("image/") && file.type !== "application/pdf") {
      toast.error("Please upload a valid image or PDF file");
      return null;
    }

    // Handle image files
    if (file.type.startsWith("image/")) {
      const compressedImage = await imageCompression(file, {
        ...imageOptions,
        onProgress: (progress) =>
          console.log("Image compression progress:", progress),
      });

      setImagePreview(URL.createObjectURL(compressedImage)); // Show preview for images
      return compressedImage;
    }

    // Handle PDF files
    if (file.type === "application/pdf") {
      const pdfArrayBuffer = await file.arrayBuffer();
      const pdfDoc = await PDFDocument.load(pdfArrayBuffer);

      // Optional: Remove unnecessary metadata or reduce image quality in the PDF
      pdfDoc.setProducer("Compressed with Custom Script");
      pdfDoc.setCreator("Your App Name");

      const compressedPdfBytes = await pdfDoc.save({
        useObjectStreams: false, // This reduces size but sacrifices incremental updates
      });

      const compressedPdfBlob = new Blob([compressedPdfBytes], {
        type: "application/pdf",
      });
      // setPreview(URL.createObjectURL(compressedPdfBlob)); // Show preview for PDFs (if needed)
      return compressedPdfBlob;
    }
  } catch (error) {
    console.error("Error compressing file:", error);
    toast.error("Error processing file. Please try again.");
    return null;
  }
};

// Qualification options
export const qualificationOptions = [
  { value: "high_school", label: "High School" },
  { value: "intermediate", label: "Intermediate" },
  { value: "graduate", label: "Graduate" },
  { value: "post_graduate", label: "Post Graduate" },
  { value: "other", label: "Other" },
];

export const defaultFacilities = [
  "Select All",
  "Wifi",
  "AC",
  "Parking",
  "Water",
  "Printer",
  "Locker",
  "Book",
  "Coffee",
];

export const libraryPreDefinedTerms = {
  content:
    "<ol><li><b>Maintain Silence:</b> All users are required to maintain silence to ensure a peaceful study environment. </li><li><b>No Food or Drinks:</b> Food and beverages are strictly prohibited inside the library/study space. </li><li><b>Respect Property:</b> Users must handle books, furniture, and all library property with care. </li><li><b>Use Designated Areas:</b> Study spaces are assigned to specific users; please use only your designated area. </li><li><b>Keep Mobile Devices on Silent:</b> Mobile phones must be kept on silent mode to avoid disturbing others. </li><li><b>Fees Are Non-Refundable:</b> Once paid, the membership or usage fees will not be refunded under any circumstances. </li></ol>",
};

export const shiftWiseSeatColor = [
  {
    label: "seat_container_design_only_morning_booked",
    value: "Morning Shift",
  },
  {
    label: "seat_container_design_only_evening_booked",
    value: "Evening Shift",
  },
  { label: "seat_container_design_only_night_booked", value: "Night Shift" },
  { label: "seat_container_design_available", value: "2 or More Shifts" },
  { label: "seat_container_design_booked", value: "Full Day Shift" },
  { label: " ", value: "Full Day Available" },
];

export function getShiftTypes(shifts) {
  const shiftTypes = shifts?.map((shift) => shift?.shiftType);
  shiftTypes?.push("2 or More Shifts");
  shiftTypes?.push("Full Day Available");
  if (!shiftTypes?.includes("Full Day Shift")) {
    shiftTypes?.push("Full Day Shift");
  }

  return shiftTypes;
}

export const dynamicCssForSeats = (seatStatusOverAll, bookedArray) => {
  const finalStatus =
    seatStatusOverAll === "Booked"
      ? "seat_container_design_booked"
      : seatStatusOverAll === "Partial Available" &&
        bookedArray.length === 1 &&
        bookedArray.includes("Morning Shift")
      ? "seat_container_design_only_morning_booked"
      : seatStatusOverAll === "Partial Available" &&
        bookedArray.length === 1 &&
        bookedArray.includes("Evening Shift")
      ? "seat_container_design_only_evening_booked"
      : seatStatusOverAll === "Partial Available" &&
        bookedArray.length === 1 &&
        bookedArray.includes("Night Shift")
      ? "seat_container_design_only_night_booked"
      : seatStatusOverAll === "Partial Available"
      ? "seat_container_design_available"
      : "seat_container_design";

  return finalStatus;
};
