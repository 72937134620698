import React from "react";

export default function FooterLanding({ handleNavigateJoin }) {
  return (
    <footer className="text-center text-white footer-container-div">
      <div className="p-2 pb-0">
        <section className="d-flex justify-content-center align-items-center">
          <span className="me-3">Register for free</span>
          <button
            className="get-started-btn animate__animated animate__fadeInUp m-0 px-3 py-2 bg-white text-black"
            onClick={handleNavigateJoin}
          >
            Get Started
          </button>
        </section>
      </div>

      <div
        className="text-center p-2"
        style={{ backgroundColor: "rgba(0, 0, 0, 0.2)" }}
      >
        © 2024 Copyright:
        <a className="text-white" href="https://librify.in">
          Librify.in
        </a>
      </div>
    </footer>
  );
}
