export function checkBookingStatus(seatData) {
  const shifts = seatData?.shifts || [];
  const bookedArray = [];

  const twentyFourHourShift = shifts.find(
    (shift) => shift.shiftType === "Full Day Shift"
  );
  const nonFullDayShifts = shifts.filter(
    (shift) => shift.shiftType !== "Full Day Shift"
  );

  if (twentyFourHourShift?.bookingStatus === "Booked") {
    bookedArray.push("Full Day Shift");
    return { status: "Booked", bookedArray };
  }

  nonFullDayShifts.forEach(shift => {
    if (shift.bookingStatus === "Booked") {
      bookedArray.push(shift.shiftType);
    }
  });

  if (nonFullDayShifts.length > 0) {
    const allBooked = nonFullDayShifts.every(
      shift => shift.bookingStatus === "Booked"
    );
    const someBooked = bookedArray.length > 0;

    if (allBooked) return { status: "Booked", bookedArray };
    if (someBooked) return { status: "Partial Available", bookedArray };
  }

  return { status: "Available", bookedArray };
}