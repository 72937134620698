import React, { useEffect, useState } from "react";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import { Button, Form as BootstrapForm } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { useDispatch, useSelector } from "react-redux";
import {
  addMemberDetailAction,
  fetchAllMemberDetailAction,
} from "../../action/newMember/newMemberDetailAction";
import { fetchUserDetailAction } from "../../action/newUser/newUserDetailAction";
import {
  customStyles,
  getOptions,
  handleImageCompress,
  qualificationOptions,
  transformShiftsOptions,
} from "../../util/utils";
import { multipleShifts } from "../../util/checkSeatAvaFunction";
import { fetchAllSubscriptionAction } from "../../action/newSubscription/newSubscriptionDetailAction";
import { uploadImage } from "../../firebase/firebase.auth.config";

const CustomForm = ({ onClose, dropdownData, libraryData, seats }) => {
  const userUid = localStorage.getItem("user_uid");
  const dispatch = useDispatch();
  const [sameAsMobile, setSameAsMobile] = useState(false);
  const [loader, setLoader] = useState(false);

  const newUserDetail = useSelector((state) => state.newUserDetail);
  // console.log(newUserDetail?.data);
  let formFields = newUserDetail?.data?.formFields;

  // States for tracking shifts and dates
  const [selectedShifts, setSelectedShifts] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [seatOptions, setSeatOptions] = useState([]);
  const [selectedSeat, setSelectedSeat] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [aadharPreview, setAadharPreview] = useState(null);
  const [compressedImage] = useState(null);
  // const [profileImageUrl, setProfileImageUrl] = useState(null);
  const [otherGoal, setOtherGoal] = useState("");
  const [selectedGoal, setSelectedGoal] = useState("");
  const [otherQualificaton, setOtherQualificaton] = useState("");
  const [selectedQualificaton, setSelectedQualificaton] = useState();

  const newSubsDetail = useSelector((state) => state.newSubscriptionDetail);
  const shiftOptions = transformShiftsOptions(libraryData?.library?.shifts);
  const goalOptions = getOptions("Goals", dropdownData);
  const animatedComponents = makeAnimated();

  // Update seat options whenever shifts or dates change
  useEffect(() => {
    if (startDate && endDate && selectedShifts.length > 0) {
      const allotseats = multipleShifts(
        libraryData?.library,
        libraryData?.members,
        seats,
        startDate,
        endDate,
        selectedShifts,
        newSubsDetail?.data
      );
      const newAllotSeat = allotseats?.map(
        ({ seatNo: label, seatNo: value, ...rest }) => ({
          label,
          value,
          ...rest,
        })
      );
      setSeatOptions(newAllotSeat);

      // Check if currently selected seat is still valid
      if (
        selectedSeat &&
        !newAllotSeat.some((option) => option.value === selectedSeat)
      ) {
        setSelectedSeat(null);
      }
    } else {
      setSeatOptions([]);
      setSelectedSeat(null);
    }
  }, [
    startDate,
    endDate,
    selectedShifts,
    libraryData,
    seats,
    newSubsDetail?.data,
  ]);

  // Formik initial values
  const initialValues = {
    name: "",
    mobile: "",
    whatsapp: "",
    email: "",
    gender: "",
    dateOfBirth: null,
    fatherName: "",
    qualification: "",
    emergencyContact: "",
    profileImage: null,
    aadharCardDoc: null,
    subscription: [
      {
        seatNo: "",
        shift: [],
        startDate: null,
        endDate: null,
        fee: "",
      },
    ],
    address: "",
    goal: "",
    approved: true,
    aadhar_number: "",
    paymentMethod: "",
    remarks: "",
  };

  // Yup validation schema
  const validationSchema = Yup.object({
    name:
      formFields?.name?.required && Yup.string().required("Name is required"),
    mobile:
      formFields?.contactNum?.required &&
      Yup.string()
        .matches(/^\d+$/, "Mobile number must be numeric")
        .min(10, "Mobile number must be exactly 10 digits")
        .max(10, "Mobile number must be exactly 10 digits")
        .required("Mobile number is required"),
    whatsapp:
      formFields?.whatsappNum?.required &&
      Yup.string()
        .matches(/^\d+$/, "Whatsapp number must be numeric")
        .min(10, "Whatsapp number must be exactly 10 digits")
        .max(10, "Whatsapp number must be exactly 10 digits")
        .required("Whatsapp number is required"),
    email:
      formFields?.email?.required &&
      Yup.string().email("Invalid email address").required("Email is required"),
    gender:
      formFields?.gender?.required &&
      Yup.string().required("Gender is required"),
    subscription: Yup.array().of(
      Yup.object().shape({
        seatNo: Yup.string().required("Seat number is required"),
        shift: Yup.array()
          .required("Shift is required")
          .min(1, "At least one shift is required"),
        startDate: Yup.date()
          .nullable()
          .required("Start date is required")
          .min(
            new Date(new Date().setDate(new Date().getDate() - 61)),
            "Start date cannot be before 60 days ago"
          ),
        endDate: Yup.date()
          .nullable()
          .required("End date is required")
          .min(Yup.ref("startDate"), "End date must be later than start date"),
        fee: Yup.number().required("Fee is required"),
      })
    ),
    address:
      formFields?.address?.required &&
      Yup.string().required("Address is required"),
    goal: formFields?.goal?.required && Yup.string(),
    dateOfBirth:
      formFields?.dateOfBirth?.required &&
      Yup.date()
        .required("Date of birth is required")
        .max(new Date(), "Date of birth cannot be in the future"),
    fatherName:
      formFields?.fatherName?.required &&
      Yup.string().required("Father's name is required"),
    qualification:
      formFields?.qualification?.required &&
      Yup.string().required("Qualification is required"),
    emergencyContact:
      formFields?.emergencyContact?.required &&
      Yup.string()
        .matches(/^\d+$/, "Emergency contact must be numeric")
        .length(10, "Emergency contact must be exactly 10 digits")
        .required("Emergency contact is required"),
    profileImage:
      formFields?.profielPic?.required &&
      Yup.mixed().required("Profile image is required"),
    aadharCardDoc:
      formFields?.aadharImage?.required &&
      Yup.mixed().required("Aadhar card PDF is required"),
    paymentMethod:
      formFields?.paymentMethod?.required &&
      Yup.string().required("Payment Mode is required"),
    remarks:
      formFields?.remarks?.required &&
      Yup.string().required("Remarks are required"),
  });

  // Convert the date to ISO format string for backend submission
  const formatDateToISO = (date) => {
    return date ? new Date(date).toISOString() : "";
  };

  const successCb = (data) => {
    dispatch(fetchUserDetailAction());
    dispatch(fetchAllMemberDetailAction());
    dispatch(fetchAllSubscriptionAction());
    setLoader(false);
    onClose();
  };
  const errorCb = (error) => {
    console.log(error);
    setLoader(false);
  };

  const handleFormSubmit = async (values) => {
    try {
      setLoader(true);
      // Upload images with error handling
      let profileImageUrl = "";
      let aadharDocUrl = "";
      if (values?.profileImage != null || values?.profileImage != undefined) {
        profileImageUrl = await uploadImage(values.profileImage).catch(
          (err) => {
            console.error("Profile image upload failed:", err);
            return null; // Or a placeholder URL
          }
        );
      }

      if (values?.aadharCardDoc != null || values?.aadharCardDoc != undefined) {
        aadharDocUrl = await uploadImage(
          values.aadharCardDoc,
          "aadhar_docs"
        ).catch((err) => {
          console.error("Aadhar document upload failed:", err);
          return null; // Or a placeholder URL
        });
      }

      const formattedValues = {
        ...values,
        subscription: values.subscription?.map((sub) => ({
          ...sub,
          startDate: sub.startDate ? formatDateToISO(sub.startDate) : null,
          endDate: sub.endDate ? formatDateToISO(sub.endDate) : null,
        })),
        dateOfBirth: values.dateOfBirth
          ? formatDateToISO(values.dateOfBirth)
          : null,
        profileImage: profileImageUrl,
        aadharCardDoc: aadharDocUrl,
      };
      // console.log(formattedValues);
      dispatch(
        addMemberDetailAction(formattedValues, successCb, errorCb, userUid)
      );
    } catch (error) {
      console.error("Form submission error:", error);
      errorCb?.(error);
      setLoader(false);
    }
  };

  // Modified Field components for numeric inputs
  const NumericField = ({ field, form, ...props }) => (
    <input
      {...field}
      {...props}
      onChange={(e) => {
        const value = e.target.value?.replace(/[^0-9]/g, "");
        form.setFieldValue(field.name, value);
      }}
    />
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleFormSubmit}
    >
      {({ setFieldValue, errors, touched, values }) => (
        <Form className="row gap-3">
          {formFields?.name?.display && (
            <BootstrapForm.Group className="col-12">
              <BootstrapForm.Label className="label_text">
                Name
              </BootstrapForm.Label>
              <Field
                name="name"
                type="text"
                className="formBodyControlModal form-control input_feild"
                placeholder="Enter your name"
              />
              {errors.name && touched.name ? (
                <div className="text-danger">{errors.name}</div>
              ) : null}
            </BootstrapForm.Group>
          )}

          {formFields?.contactNum?.display && (
            <BootstrapForm.Group className="col-12">
              <BootstrapForm.Label className="label_text">
                Mobile
              </BootstrapForm.Label>
              <Field
                name="mobile"
                component={NumericField}
                className="formBodyControlModal form-control input_feild"
                placeholder="Enter your mobile number"
                maxLength="10"
                onChange={(e) => {
                  const value = e.target.value?.replace(/[^0-9]/g, "");
                  setFieldValue("mobile", value);
                  if (sameAsMobile) setFieldValue("whatsapp", value);
                }}
              />
              {errors.mobile && touched.mobile && (
                <div className="text-danger">{errors.mobile}</div>
              )}
            </BootstrapForm.Group>
          )}

          {formFields?.whatsappNum?.display && (
            <BootstrapForm.Group className="col-12">
              <BootstrapForm.Label className="label_text">
                WhatsApp
              </BootstrapForm.Label>
              <Field
                name="whatsapp"
                component={NumericField}
                className="formBodyControlModal form-control input_feild"
                placeholder="Enter your WhatsApp number"
                maxLength="10"
              />

              <BootstrapForm.Check
                type="checkbox"
                label="Same as mobile"
                onChange={() => {
                  setSameAsMobile(!sameAsMobile);
                  if (!sameAsMobile) {
                    setFieldValue("whatsapp", values.mobile);
                  }
                }}
                checked={sameAsMobile}
              />
              {errors.whatsapp && touched.whatsapp ? (
                <div className="text-danger">{errors.whatsapp}</div>
              ) : null}
            </BootstrapForm.Group>
          )}

          {formFields?.dateOfBirth?.display && (
            <BootstrapForm.Group className="col-12 px-3">
              <BootstrapForm.Label className="label_text">
                Date of Birth{" "}
                {!formFields?.dateOfBirth?.required && (
                  <span className="pri_text"> (Optional) </span>
                )}
              </BootstrapForm.Label>
              <DatePicker
                selected={values.dateOfBirth}
                onChange={(date) => setFieldValue("dateOfBirth", date)}
                dateFormat="dd/MM/yyyy"
                maxDate={new Date()}
                showYearDropdown
                scrollableYearDropdown
                yearDropdownItemNumber={100}
                placeholderText="Select Date of Birth"
                className={`form-control input_feild ${
                  errors.dateOfBirth && touched.dateOfBirth ? "is-invalid" : ""
                }`}
              />
              {errors.dateOfBirth && touched.dateOfBirth && (
                <div className="text-danger">{errors.dateOfBirth}</div>
              )}
            </BootstrapForm.Group>
          )}

          {formFields?.fatherName?.display && (
            <BootstrapForm.Group className="col-12">
              <BootstrapForm.Label className="label_text">
                Father's Name{" "}
                {!formFields?.fatherName?.required && (
                  <span className="pri_text"> (Optional) </span>
                )}
              </BootstrapForm.Label>
              <Field
                name="fatherName"
                type="text"
                className="formBodyControlModal form-control input_feild"
                placeholder="Enter father's name"
              />
              {errors.fatherName && touched.fatherName && (
                <div className="text-danger">{errors.fatherName}</div>
              )}
            </BootstrapForm.Group>
          )}

          {formFields?.address?.display && (
            <BootstrapForm.Group className="col-12">
              <BootstrapForm.Label className="label_text">
                Address
                {!formFields?.address?.required && (
                  <span className="pri_text"> (Optional) </span>
                )}
              </BootstrapForm.Label>
              <Field
                name="address"
                as="textarea"
                className="formBodyControlModal form-control input_feild"
                placeholder="Enter your address"
              />
              {errors.address && touched.address ? (
                <div className="text-danger">{errors.address}</div>
              ) : null}
            </BootstrapForm.Group>
          )}

          {/* Qualification */}
          {formFields?.qualification?.display && (
            <BootstrapForm.Group className="col-12">
              <BootstrapForm.Label className="label_text">
                Qualification{" "}
                {!formFields?.qualification?.required && (
                  <span className="pri_text"> (Optional) </span>
                )}
              </BootstrapForm.Label>
              <Select
                options={qualificationOptions}
                className="formBodyControlModal"
                components={animatedComponents}
                placeholder="Select Qualification"
                onChange={(option) => {
                  setSelectedQualificaton(option.value);
                  if (option.value !== "other") {
                    setOtherQualificaton("");
                    setFieldValue("qualification", option.value);
                  }
                }}
                styles={customStyles}
              />
              {errors.qualification && touched.qualification && (
                <div className="text-danger">{errors.qualification}</div>
              )}
              {selectedQualificaton === "other" && (
                <div>
                  <BootstrapForm.Label className="label_text">
                    Please Specify
                  </BootstrapForm.Label>
                  <BootstrapForm.Control
                    type="text"
                    placeholder="Enter your qualification"
                    value={otherQualificaton}
                    onChange={(e) => {
                      const value = e.target.value;
                      setOtherQualificaton(value);
                      setFieldValue("qualification", value);
                    }}
                  />
                </div>
              )}
            </BootstrapForm.Group>
          )}

          {/* Emergency Contact */}
          {formFields?.emergencyContact?.display && (
            <BootstrapForm.Group className="col-12">
              <BootstrapForm.Label className="label_text">
                Emergency Contact{" "}
                {!formFields?.emergencyContact?.required && (
                  <span className="pri_text"> (Optional) </span>
                )}
              </BootstrapForm.Label>
              <Field
                name="emergencyContact"
                component={NumericField}
                className="formBodyControlModal form-control input_feild"
                placeholder="Enter emergency contact number"
                maxLength="10"
              />
              {errors.emergencyContact && touched.emergencyContact && (
                <div className="text-danger">{errors.emergencyContact}</div>
              )}
            </BootstrapForm.Group>
          )}

          {formFields?.profielPic?.display && (
            <BootstrapForm.Group className="col-12">
              <BootstrapForm.Label className="label_text">
                Profile Image{" "}
                {!formFields?.profielPic?.required && (
                  <span className="pri_text"> (Optional) </span>
                )}
              </BootstrapForm.Label>
              <input
                type="file"
                accept="image/*"
                onChange={async (event) => {
                  const file = event.currentTarget.files[0];
                  if (file) {
                    const compressedFile = await handleImageCompress(
                      file,
                      setImagePreview
                    );
                    setFieldValue("profileImage", compressedFile);
                  }
                }}
                className="form-control"
              />
              {imagePreview && (
                <img
                  src={imagePreview}
                  alt="Preview"
                  className="mt-2"
                  style={{ maxWidth: "200px" }}
                />
              )}
              {errors.profileImage && touched.profileImage && (
                <div className="text-danger">{errors.profileImage}</div>
              )}
            </BootstrapForm.Group>
          )}

          {formFields?.aadharNumber?.display && (
            <BootstrapForm.Group className="col-12">
              <BootstrapForm.Label className="label_text">
                Aadhar number{" "}
                {!formFields?.aadharNumber?.required && (
                  <span className="pri_text"> (Optional) </span>
                )}
              </BootstrapForm.Label>
              <Field
                name="aadhar_number"
                // component={NumericField}
                className="formBodyControlModal form-control input_feild"
                placeholder="Enter aadhar"
                maxLength="14"
                onChange={(e) => {
                  const rvalue = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
                  const formattedValue =
                    rvalue
                      .match(/.{1,4}/g) // Split into groups of 1 to 4 digits
                      ?.join(" ") // Join groups with spaces
                      .trim() || ""; // Trim any trailing spaces
                  setFieldValue("aadhar_number", formattedValue);
                  e.target.value = formattedValue; // Update the input value
                }}
              />
            </BootstrapForm.Group>
          )}

          {formFields?.aadharImage?.display && (
            <BootstrapForm.Group className="col-12">
              <BootstrapForm.Label className="label_text">
                Aadhar Card Image{" "}
                {!formFields?.aadharImage?.required && (
                  <span className="pri_text"> (Optional) </span>
                )}
              </BootstrapForm.Label>
              <input
                type="file"
                accept="image/*"
                onChange={async (event) => {
                  const file = event.currentTarget.files[0];
                  if (file) {
                    const compressedFile = await handleImageCompress(
                      file,
                      setAadharPreview
                    );
                    setFieldValue("aadharCardDoc", compressedFile);
                  }
                }}
                className="form-control"
              />
              {errors.aadharCardDoc && touched.aadharCardDoc && (
                <div className="text-danger">{errors.aadharCardDoc}</div>
              )}
            </BootstrapForm.Group>
          )}
          {formFields?.email?.display && (
            <BootstrapForm.Group className="col-12">
              <BootstrapForm.Label className="label_text">
                Email{" "}
                {!formFields?.email?.required && (
                  <span className="pri_text"> (Optional) </span>
                )}
              </BootstrapForm.Label>
              <Field
                name="email"
                type="email"
                className="formBodyControlModal form-control input_feild"
                placeholder="Enter your email"
              />
              {errors.email && touched.email ? (
                <div className="text-danger">{errors.email}</div>
              ) : null}
            </BootstrapForm.Group>
          )}
          {formFields?.gender?.display && (
            <BootstrapForm.Group className="col-12">
              <BootstrapForm.Label className="label_text">
                Gender
              </BootstrapForm.Label>
              <div className="d-flex gap-4">
                <label className="mb-0 d-flex gap-2">
                  <Field type="radio" name="gender" value="Male" />
                  Male
                </label>
                <label className="mb-0 d-flex gap-2">
                  <Field type="radio" name="gender" value="Female" />
                  Female
                </label>
              </div>
              {errors.gender && touched.gender ? (
                <div className="text-danger">{errors.gender}</div>
              ) : null}
            </BootstrapForm.Group>
          )}

          <BootstrapForm.Group className="col-12 px-3">
            <BootstrapForm.Label className="label_text w-100">
              Subscription start date
            </BootstrapForm.Label>
            <DatePicker
              selected={values.subscription[0].startDate}
              onChange={(date) => {
                setFieldValue("subscription[0].startDate", date);
                setStartDate(date);
              }}
              selectsStart
              startDate={values.subscription[0].startDate}
              endDate={values.subscription[0].endDate}
              dateFormat="dd/MM/yyyy"
              placeholderText="Start Date"
              className={`form-control input_feild ${
                errors.subscription?.[0]?.startDate &&
                touched.subscription?.[0]?.startDate
                  ? "is-invalid"
                  : ""
              }`}
            />
          </BootstrapForm.Group>

          {/* ... other form groups remain same ... */}
          <BootstrapForm.Group className="col-12 px-3">
            <BootstrapForm.Label className="label_text w-100">
              Subscription end date
            </BootstrapForm.Label>
            <DatePicker
              selected={values.subscription[0].endDate}
              onChange={(date) => {
                setFieldValue("subscription[0].endDate", date);
                setEndDate(date);
              }}
              selectsEnd
              startDate={values.subscription[0].startDate}
              endDate={values.subscription[0].endDate}
              minDate={values.subscription[0].startDate}
              dateFormat="dd/MM/yyyy"
              placeholderText="End Date"
              className={`form-control input_feild ${
                errors.subscription?.[0]?.endDate &&
                touched.subscription?.[0]?.endDate
                  ? "is-invalid"
                  : ""
              }`}
            />
          </BootstrapForm.Group>

          <BootstrapForm.Group className="col-12">
            <BootstrapForm.Label className="label_text">
              Shift
            </BootstrapForm.Label>
            <Select
              options={shiftOptions}
              className="formBodyControlModal"
              components={animatedComponents}
              placeholder="Select Shift (Multiple Select)"
              isMulti
              onChange={(selectedOptions) => {
                if (selectedOptions[0]?.shift === "FullDayShift")
                  selectedOptions?.splice(1, selectedOptions?.length);
                if (
                  selectedOptions[selectedOptions.length - 1]?.shift ===
                  "FullDayShift"
                )
                  selectedOptions?.splice(0, selectedOptions?.length - 1);

                const formattedOptions = selectedOptions?.map((option) => {
                  const formattedValue = option.value.split("(")[0].trim();
                  return formattedValue;
                });

                setFieldValue("subscription[0].shift", formattedOptions);
                setSelectedShifts(selectedOptions);
              }}
              styles={customStyles}
            />
            {errors.subscription?.[0]?.shift &&
            touched.subscription?.[0]?.shift ? (
              <div className="text-danger">{errors.subscription[0].shift}</div>
            ) : null}
          </BootstrapForm.Group>

          <BootstrapForm.Group className="col-12">
            <BootstrapForm.Label className="label_text">
              Seat No.
            </BootstrapForm.Label>
            <Select
              options={seatOptions}
              className="formBodyControlModal"
              components={animatedComponents}
              placeholder={
                !startDate || !endDate || selectedShifts.length === 0
                  ? "Select Shift & dates to show seat options"
                  : "Select Seat No."
              }
              value={
                seatOptions.find((option) => option.value === selectedSeat) ||
                null
              }
              isDisabled={!startDate || !endDate || selectedShifts.length === 0}
              onChange={(option) => {
                const seatValue = option ? option.value : "";
                setSelectedSeat(seatValue);
                setFieldValue("subscription[0].seatNo", seatValue);
              }}
              styles={customStyles}
            />
          </BootstrapForm.Group>

          <BootstrapForm.Group className="col-12">
            <BootstrapForm.Label className="label_text">
              Fee
            </BootstrapForm.Label>
            <Field
              name="subscription[0].fee"
              type="number"
              min="0"
              className="formBodyControlModal form-control input_feild"
              placeholder="Enter Fee Amount"
              onChange={(e) => {
                const value = e.target.value;
                setFieldValue(
                  "subscription[0].fee",
                  value === "" ? "" : Number(value)
                );
              }}
            />
            {errors.subscription?.[0]?.fee &&
              touched.subscription?.[0]?.fee && (
                <div className="text-danger">{errors.subscription[0].fee}</div>
              )}
          </BootstrapForm.Group>

          {formFields?.paymentMethod?.display && (
            <BootstrapForm.Group className="col-12">
              <BootstrapForm.Label className="label_text">
                Payment Mode{" "}
                {!formFields?.paymentMethod?.required && (
                  <span className="pri_text"> (Optional) </span>
                )}
              </BootstrapForm.Label>
              <Field
                name="paymentMethod"
                type="text"
                className="formBodyControlModal form-control input_feild"
                placeholder="Payment Mode"
              />
              {errors?.paymentMethod && touched?.paymentMethod && (
                <div className="text-danger">{errors.paymentMethod}</div>
              )}
            </BootstrapForm.Group>
          )}

          {formFields?.remarks?.display && (
            <BootstrapForm.Group className="col-12">
              <BootstrapForm.Label className="label_text">
                Any Remarks{" "}
                {!formFields?.remarks?.required && (
                  <span className="pri_text"> (Optional) </span>
                )}
              </BootstrapForm.Label>
              <Field
                name="remarks"
                type="text"
                className="formBodyControlModal form-control input_feild"
                placeholder="Remarks"
              />
              {errors?.remarks && touched?.remarks && (
                <div className="text-danger">{errors.remarks}</div>
              )}
            </BootstrapForm.Group>
          )}

          {formFields?.goal?.display && (
            <BootstrapForm.Group className="col-12">
              <BootstrapForm.Label className="label_text">
                Goal{" "}
                {!formFields?.goal?.required && (
                  <span className="pri_text"> (Optional) </span>
                )}
              </BootstrapForm.Label>
              <Select
                options={goalOptions}
                className="formBodyControlModal"
                components={animatedComponents}
                placeholder="Select Goal"
                onChange={(option) => {
                  setSelectedGoal(option.value);
                  if (option.value !== "Others") {
                    setOtherGoal("");
                    setFieldValue("goal", option.value);
                  }
                }}
                styles={customStyles}
              />
              {selectedGoal === "Others" && (
                <div>
                  <BootstrapForm.Label className="label_text">
                    Please Specify
                  </BootstrapForm.Label>
                  <BootstrapForm.Control
                    type="text"
                    placeholder="Enter your goal"
                    value={otherGoal}
                    onChange={(e) => {
                      const value = e.target.value;
                      setOtherGoal(value);
                      setFieldValue("goal", value);
                    }}
                  />
                </div>
              )}
            </BootstrapForm.Group>
          )}

          <div className="col-12 d-flex border-0 gap-2 outline-0 justify-content-between my-2 ">
            <Button
              className="button_design w-50"
              variant="outline"
              onClick={onClose}
              disabled={loader}
            >
              Cancel
            </Button>

            <Button
              className="pri_bg button_design w-50"
              variant="secondary"
              type="submit"
              disabled={loader}
            >
              {loader ? (
                <>
                  <div class="spinner-border spinner-border-btn" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                </>
              ) : (
                "Add Student"
              )}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default CustomForm;
