import {
  ADD_SUBSCRIPTION_DETAIL_REQUEST,
  ADD_SUBSCRIPTION_DETAIL_SUCCESS,
  ADD_SUBSCRIPTION_DETAIL_FAILURE,
  FETCH_ALL_SUBSCRIPTION_REQUEST,
  FETCH_ALL_SUBSCRIPTION_SUCCESS,
  FETCH_ALL_SUBSCRIPTION_FAILURE,
} from "../../action/actionType";
import { db, auth } from "../../firebase/firebase.auth.config";
import {
  doc,
  setDoc,
  getDoc,
  addDoc,
  collection,
  query,
  where,
  getDocs,
} from "firebase/firestore";
import { toast } from "react-hot-toast";

const addSubscriptionDetailRequest = () => ({
  type: ADD_SUBSCRIPTION_DETAIL_REQUEST,
});

const addSubscriptionDetailSuccess = (subscriptionDetails) => ({
  type: ADD_SUBSCRIPTION_DETAIL_SUCCESS,
  payload: subscriptionDetails,
});

const addSubscriptionDetailFailure = (error) => ({
  type: ADD_SUBSCRIPTION_DETAIL_FAILURE,
  payload: error,
});

const fetchAllSubscriptionRequest = () => ({
  type: FETCH_ALL_SUBSCRIPTION_REQUEST,
});

const fetchAllSubscriptionSuccess = (allMemberDetails) => ({
  type: FETCH_ALL_SUBSCRIPTION_SUCCESS,
  payload: allMemberDetails,
});

const fetchAllSubscriptionFailure = (error) => ({
  type: FETCH_ALL_SUBSCRIPTION_FAILURE,
  payload: error,
});

export const addSubscriptionDetailAction =
  (subscriptionDetails, memberId, successCb, errorCb) => async (dispatch) => {
    dispatch(addSubscriptionDetailRequest());
    try {
      const user_uid = localStorage.getItem("user_uid");

      // Get member details
      const memberDoc = doc(db, "member", memberId);
      const memberDocSnap = await getDoc(memberDoc);
      var memberDocData = memberDocSnap.data();

      // Add new subscription
      subscriptionDetails.member = memberId;
      subscriptionDetails.library = user_uid;
      subscriptionDetails.active = true;
      const subDocRef = await addDoc(
        collection(db, "subscription"),
        subscriptionDetails
      );

      // Push this subscription to the member
      memberDocData.subscription.push(subDocRef.id);
      await setDoc(memberDoc, memberDocData);

      // Add subscription id to subscription
      const subDoc = doc(db, "subscription", subDocRef.id);
      const subDocSnap = await getDoc(subDoc);
      var subData = { ...subDocSnap.data() };
      subData.id = subDocRef.id;
      await setDoc(subDoc, subData);

      // Dispatch success
      successCb(subscriptionDetails);
      toast.success("Subscription renewed!");
      dispatch(addSubscriptionDetailSuccess(subscriptionDetails));
    } catch (error) {
      errorCb(error);
      dispatch(addSubscriptionDetailFailure(error.message));
      toast.error("Failed to renew subscription");
    }
  };

export const fetchAllSubscriptionAction = (uid) => async (dispatch) => {
  dispatch(fetchAllSubscriptionRequest());
  try {
    const user_uid = localStorage.getItem("user_uid");
    const q = query(
      collection(db, "subscription"),
      where("library", "==", user_uid || uid)
    );
    const querySnapshot = await getDocs(q);
    let res = [];
    querySnapshot.forEach((doc) => {
      let docu = doc.data();
      res.push(docu);
    });
    // const userDoc = doc(db, "owner", user_uid);
    // const docSnap = await getDoc(userDoc);
    // const data = docSnap.data();
    // const res = [];
    // for (let id of data.members) {
    //   const memberDoc = doc(db, "member", id);
    //   const memDocSnap = await getDoc(memberDoc);
    //   const memberData = memDocSnap.data();
    //   var sub = [];
    //   for (let subId of memberData.subscription) {
    //     const subDoc = doc(db, "subscription", subId);
    //     const subDocSnap = await getDoc(subDoc);
    //     sub.push(subDocSnap.data());
    //   }
    //   memberData.subscription = sub;
    //   res.push(memberData);
    // }
    dispatch(fetchAllSubscriptionSuccess(res));
  } catch (error) {
    dispatch(fetchAllSubscriptionFailure(error.message));
    toast.error("Failed to add details");
  }
};
