export function getSeatStatuses(libraryData, members) {
  const seats = [];
  const shiftsTemplate = libraryData?.shifts?.map((shift) => ({
    shiftType: shift.shiftName,
    bookingStatus: "Available",
    userName: null,
    startTime: `${shift.startHour}:${shift.startMinute < 10 ? "0" : ""}${
      shift.startMinute
    } ${shift.startPeriod}`,
    endTime: `${shift.endHour}:${shift.endMinute < 10 ? "0" : ""}${
      shift.endMinute
    } ${shift.endPeriod}`,
  }));

  // Create seat data for all floors
  libraryData?.floors?.forEach((floor) => {
    for (let i = 1; i <= floor.totalSeats; i++) {
      seats.push({
        seatNumber: `${floor.floorName}-${i}`,
        shifts: JSON.parse(JSON.stringify(shiftsTemplate)),
      });
    }
  });

  // Update seat statuses based on member subscriptions
  const today = new Date().toISOString();

  members?.forEach((member) => {
    member?.subscription?.forEach((subscription) => {
      const endDate = new Date(subscription.endDate);
      const updatedEndDate = new Date(
        endDate.getTime() + (libraryData.bufferTime || 0) * 24 * 60 * 60 * 1000
      );
      if (
        subscription.active &&
        new Date(subscription.startDate) <= new Date(today) &&
        new Date(today) <= updatedEndDate
      ) {
        const seat = seats.find((s) => s.seatNumber === subscription.seatNo);
        if (seat) {
          const standardizedShifts = subscription.shift?.map((shift) =>
            shift === "Full Day Shift" ? "Full Day Shift" : shift
          );

          if (standardizedShifts?.includes("Full Day Shift")) {
            // If "Full Day Shift" is booked, mark all other shifts as unavailable
            seat?.shifts?.forEach((shift) => {
              if (shift.shiftType === "Full Day Shift") {
                shift.bookingStatus = "Booked";
                shift.userName = member.name;
                shift.member = member;
              } else {
                shift.bookingStatus = "Unavailable";
              }
            });
          } else {
            // If other shifts are booked, mark "Full Day Shift" as unavailable
            standardizedShifts?.forEach((subShift) => {
              const shift = seat.shifts.find((s) => s.shiftType === subShift);
              if (shift) {
                shift.bookingStatus = "Booked";
                shift.userName = member.name;
                shift.member = member;
              }
            });

            const fullDayShift = seat.shifts.find(
              (s) => s.shiftType === "Full Day Shift"
            );
            if (fullDayShift) {
              fullDayShift.bookingStatus = "Unavailable";
            }
          }
        }
      }
    });
  });

  return JSON.stringify(seats, null, 2);
}
