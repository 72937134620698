import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { Formik, Field, ErrorMessage, Form as FormikForm } from "formik";
import * as Yup from "yup";
import ModalComponent from "./modalComponent";
import { useDispatch } from "react-redux";
import { addUserDetailAction } from "../../action/newUser/newUserDetailAction";

const PersonalDetailForm = ({
  show,
  handleClose,
  selectedPerson,
  successCb,
  errorCb,
  loaderFormPerson,
}) => {
  const dispatch = useDispatch();
  const [sameAsPhone, setSameAsPhone] = useState(false);

  const validationSchema = Yup.object({
    ownerName: Yup.string()
      .required("Owner Name is required")
      .min(2, "Must be at least 2 characters"),
    phoneNumber: Yup.string().required("Phone Number is required"),
    // .matches(/^\d{13}$/, "Phone Number must be exactly 10 digits"),
    email: Yup.string()
      .email("Invalid email format")
      .required("Email is required"),
    whatsappNumber: Yup.string()
      // .matches(/^\d{13}$/, "WhatsApp Number must be exactly 10 digits")
      .nullable(),
  });

  const handleCreate = (values, actions) => {
    handleClose();
    dispatch(addUserDetailAction(values, successCb, errorCb));
  };

  const initialValues = {
    ownerName: selectedPerson?.ownerName || "",
    phoneNumber: selectedPerson?.phoneNumber || "",
    email: selectedPerson?.email || "",
    whatsappNumber: selectedPerson?.whatsappNumber || "",
  };

  const handleCheckboxChange = (setFieldValue, values) => {
    if (sameAsPhone) {
      setFieldValue("whatsappNumber", "");
    } else {
      setFieldValue("whatsappNumber", values.phoneNumber);
    }
    setSameAsPhone(!sameAsPhone);
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleCreate}
      enableReinitialize
    >
      {({ handleSubmit, setFieldValue, values, isSubmitting }) => (
        <ModalComponent
          show={show}
          handleClose={handleClose}
          title={
            selectedPerson ? "Update Personal Details" : "Add Personal Details"
          }
          body={
            <ModalContent
              handleSubmit={handleSubmit}
              isSubmitting={isSubmitting}
              handleCheckboxChange={() =>
                handleCheckboxChange(setFieldValue, values)
              }
              sameAsPhone={sameAsPhone}
            />
          }
          handleSubmit={handleSubmit}
          isSubmitting={loaderFormPerson}
        />
      )}
    </Formik>
  );
};

const ModalContent = ({
  handleSubmit,
  isSubmitting,
  handleCheckboxChange,
  sameAsPhone,
}) => {
  return (
    <FormikForm className="row gap-3" onSubmit={handleSubmit}>
      <Form.Group
        controlId="formOwnerName"
        className="d-flex flex-column col-12"
      >
        <Form.Label className="label_text">Owner Name</Form.Label>
        <Field
          name="ownerName"
          type="text"
          className="formBodyControlModal form-control input_feild"
          placeholder="Enter owner name"
        />
        <ErrorMessage
          name="ownerName"
          component="div"
          className="text-danger"
        />
      </Form.Group>

      <Form.Group controlId="formPhoneNumber" className="col-12">
        <Form.Label className="label_text">Phone Number</Form.Label>
        <Field
          name="phoneNumber"
          type="text"
          className="formBodyControlModal form-control input_feild"
          placeholder="Enter phone number"
          disabled
        />
        <ErrorMessage
          name="phoneNumber"
          component="div"
          className="text-danger"
        />
      </Form.Group>

      <Form.Group controlId="formEmail" className="col-12">
        <Form.Label className="label_text">Email</Form.Label>
        <Field
          name="email"
          type="email"
          className="formBodyControlModal form-control input_feild"
          placeholder="Enter email address"
        />
        <ErrorMessage name="email" component="div" className="text-danger" />
      </Form.Group>

      <Form.Group controlId="formWhatsappNumber" className="col-12">
        <Form.Label className="label_text">WhatsApp Number</Form.Label>
        <Field
          name="whatsappNumber"
          type="text"
          className="formBodyControlModal form-control input_feild"
          placeholder="Enter WhatsApp number"
        />
        <Form.Check
          className="label_text mt-1"
          type="checkbox"
          label="Same as Phone Number"
          onChange={handleCheckboxChange}
          checked={sameAsPhone}
        />
        <ErrorMessage
          name="whatsappNumber"
          component="div"
          className="text-danger"
        />
      </Form.Group>
    </FormikForm>
  );
};

export default PersonalDetailForm;
