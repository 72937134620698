import React, { useEffect, useState } from "react";
import { jsPDF } from "jspdf";
import { format } from "date-fns";
import { encode, decode } from "js-base64";

// Secure data encoding function
export const generateSecureShareLink = (data) => {
  const jsonData = JSON.stringify(data);
  const encodedData = encode(jsonData);
  const checksum = calculateChecksum(encodedData);
  return `${window.location.origin}/r/${checksum}/${encodedData}`;
};

// Decode and validate data
export const extractReceiptData = (encodedPath) => {
  try {
    const pathParts = encodedPath.split("/");
    const checksum = pathParts[pathParts.length - 2];
    const encodedData = pathParts[pathParts.length - 1];

    if (checksum !== calculateChecksum(encodedData)) {
      throw new Error("Invalid data");
    }

    const jsonData = decode(encodedData);
    return JSON.parse(jsonData);
  } catch (error) {
    console.error("Data extraction failed:", error);
    return null;
  }
};

// Simple checksum calculation
const calculateChecksum = (data) => {
  let hash = 0;
  for (let i = 0; i < data.length; i++) {
    const char = data.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash = hash & hash;
  }
  return Math.abs(hash).toString(36).slice(0, 6);
};

const ReceiptTempleteComponent = () => {
  const [receiptData, setReceiptData] = useState({
    libraryName: "",
    studentName: "",
    studentId: "",
    duration: "",
    shift: "",
    seatNo: "",
    fee: "",
    startDate: "",
    endDate: "",
  });

  useEffect(() => {
    const path = window.location.pathname;
    if (path.startsWith("/r/")) {
      const extractedData = extractReceiptData(path);
      if (extractedData) {
        setReceiptData(extractedData);
      }
    }
  }, []);

  const downloadPDF = () => {
    const doc = new jsPDF({
      orientation: "portrait",
      unit: "mm",
      format: "a4",
    });

    // Colors
    const primaryColor = [0, 167, 111];
    const textColor = [44, 62, 80];

    // Page setup
    const pageWidth = 210;
    const margin = 25;
    const lineHeight = 10;

    // Card styling
    const cardWidth = 160;
    const cardX = (pageWidth - cardWidth) / 2;
    let currentY = 30;

    // White background
    doc.setFillColor(255, 255, 255);
    doc.roundedRect(cardX, currentY, cardWidth, 220, 3, 3, "F");

    // Header
    doc.setFillColor(...primaryColor);
    doc.rect(cardX, currentY, cardWidth, 15, "F");

    // Library name
    doc.setTextColor(255, 255, 255);
    doc.setFont("helvetica", "bold");
    doc.setFontSize(14);
    doc.text(
     `Librify x ${receiptData.libraryName}` || "Library",
      cardX + cardWidth / 2,
      currentY + 10,
      { align: "center" }
    );

    currentY += 25;

    // Student Details header
    doc.setTextColor(...primaryColor);
    doc.setFontSize(12);
    doc.text("Student Details", cardX + 10, currentY);

    // Blue line under section header
    doc.setDrawColor(...primaryColor);
    doc.setLineWidth(0.5);
    doc.line(cardX + 10, currentY + 2, cardX + cardWidth - 10, currentY + 2);

    currentY += 15;

    // Content
    doc.setTextColor(...textColor);
    doc.setFontSize(10);
    const content = [
      { label: "Name:", value: receiptData.studentName },
      { label: "Student ID:", value: receiptData.studentId },
      { label: "", value: "" }, // Spacing
      { label: "Subscription Details", value: "" },
      { label: "Start Date:", value: receiptData.startDate },
      { label: "End Date:", value: receiptData.endDate },
      { label: "Shift:", value: receiptData.shift },
      { label: "Seat No:", value: receiptData.seatNo },
      { label: "Fee:", value: `₹${receiptData.fee}` },
    ];

    content.forEach((item) => {
      if (item.label === "Subscription Details") {
        doc.setTextColor(...primaryColor);
        doc.setFont("helvetica", "bold");
        doc.text(item.label, cardX + 10, currentY);
        doc.line(
          cardX + 10,
          currentY + 2,
          cardX + cardWidth - 10,
          currentY + 2
        );
        doc.setTextColor(...textColor);
        doc.setFont("helvetica", "normal");
      } else {
        doc.setFont("helvetica", "bold");
        doc.text(item.label, cardX + 10, currentY);
        doc.setFont("helvetica", "normal");
        doc.text(item.value, cardX + cardWidth - 60, currentY);
      }
      currentY += lineHeight;
    });

    // Footer
    doc.setFontSize(8);
    doc.setTextColor(150);
    doc.text(
      "Generated on: " + format(new Date(), "dd/MM/yyyy HH:mm"),
      cardX + cardWidth / 2,
      currentY + 10,
      { align: "center" }
    );

    doc.save(`${receiptData.studentName}_receipt.pdf`);
  };

  return (
    <div className="custom-receipt-container">
      <div id="custom-receipt" className="custom-receipt">
        <div className="custom-receipt-header">
          <h1>Librify x {receiptData.libraryName}</h1>
        </div>

        <div className="custom-receipt-content">
          <div className="custom-receipt-section">
            <h2>Student Details</h2>
            <div className="custom-receipt-detail">
              <span>Name:</span>
              <p>{receiptData.studentName}</p>
            </div>
            <div className="custom-receipt-detail">
              <span>Student ID:</span>
              <p>{receiptData.studentId}</p>
            </div>
          </div>

          <div className="custom-receipt-section">
            <h2>Subscription Details</h2>
            <div className="custom-receipt-detail">
              <span>Start Date:</span>
              <p>{receiptData.startDate}</p>
            </div>
            <div className="custom-receipt-detail">
              <span>End Date:</span>
              <p>{receiptData.endDate}</p>
            </div>
            <div className="custom-receipt-detail">
              <span>Shift:</span>
              <p>{receiptData.shift}</p>
            </div>
            <div className="custom-receipt-detail">
              <span>Seat No:</span>
              <p>{receiptData.seatNo}</p>
            </div>
            <div className="custom-receipt-detail">
              <span>Fee:</span>
              <p>₹{receiptData.fee}</p>
            </div>
          </div>
        </div>
      </div>

      <div className="custom-receipt-footer">
        <button onClick={downloadPDF}>Download PDF</button>
      </div>
    </div>
  );
};

export default ReceiptTempleteComponent;
