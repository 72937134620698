import {
  ADD_USER_DETAIL_REQUEST,
  ADD_USER_DETAIL_SUCCESS,
  ADD_USER_DETAIL_FAILURE,
  FETCH_USER_DETAIL_REQUEST,
  FETCH_USER_DETAIL_SUCCESS,
  FETCH_USER_DETAIL_FAILURE,
  FETCH_OPTION_DETAIL_REQUEST,
  FETCH_OPTION_DETAIL_SUCCESS,
  FETCH_OPTION_DETAIL_FAILURE,
} from "../../action/actionType";
import { auth, db } from "../../firebase/firebase.auth.config";
import { doc, setDoc, getDoc } from "firebase/firestore";
import toast from "react-hot-toast";

const fetchUserDetailRequest = () => ({
  type: FETCH_USER_DETAIL_REQUEST,
});

const fetchUserDetailSuccess = (userDetails) => ({
  type: FETCH_USER_DETAIL_SUCCESS,
  payload: userDetails,
});

const fetchUserDetailFailure = (error) => ({
  type: FETCH_USER_DETAIL_FAILURE,
  payload: error,
});

const fetchOptionDetailRequest = () => ({
  type: FETCH_OPTION_DETAIL_REQUEST,
});

const fetchOptionDetailSuccess = (optionDetails) => ({
  type: FETCH_OPTION_DETAIL_SUCCESS,
  payload: optionDetails,
});

const fetchOptionDetailFailure = (error) => ({
  type: FETCH_OPTION_DETAIL_FAILURE,
  payload: error,
});

const addUserDetailRequest = () => ({
  type: ADD_USER_DETAIL_REQUEST,
});

const addUserDetailSuccess = (userDetails) => ({
  type: ADD_USER_DETAIL_SUCCESS,
  payload: userDetails,
});

const addUserDetailFailure = (error) => ({
  type: ADD_USER_DETAIL_FAILURE,
  payload: error,
});

export const fetchUserDetailAction = (userId) => async (dispatch) => {
  dispatch(fetchUserDetailRequest());
  try {
    const user_uid = userId || localStorage.getItem("user_uid");

    const userDoc = doc(db, "owner", user_uid);
    const docSnap = await getDoc(userDoc);

    const authUser = auth.currentUser;

    if (!docSnap.exists()) {
      const defaultData = {
        user: { status: false, phoneNumber: authUser.phoneNumber },
        library: { status: false },
        members: [],
        pending: [],
        approval: false,
        reject: false,
        id: user_uid,
        counter: 0,
        options: [],
      };

      await setDoc(userDoc, defaultData);

      dispatch(fetchUserDetailSuccess({ ...defaultData }));
    } else {
      let data = docSnap.data();

      // Create arrays of member and pending IDs
      const memberIds = [...data.members, ...data.pending];

      // Use Promise.all to fetch all member documents in parallel
      const memberDocs = await Promise.all(
        memberIds?.map((id) => getDoc(doc(db, "member", id)))
      );

      // Process member data and their subscriptions
      const processedMembers = await Promise.all(
        memberDocs?.map(async (memDocSnap) => {
          const memberData = memDocSnap.data();

          if (!memberData) return null;

          if (memberData.subscription) {
            // Use Promise.all to fetch all subscription documents in parallel
            const subDocs = await Promise.all(
              memberData.subscription?.map((subId) =>
                getDoc(doc(db, "subscription", subId))
              )
            );
            // Add subscription data to member
            memberData.subscription = subDocs?.map((subDocSnap) =>
              subDocSnap.data()
            );
          }

          return memberData;
        })
      );

      // Split processed members back into `members` and `pending`
      data.members = processedMembers.slice(0, data.members?.length);
      data.pending = processedMembers.slice(data.members?.length);

      // Process shifts only once outside the loop
      const shifts = data.library.shifts || [];

      const shiftOptions = shifts?.map((shift, index) => {
        const startMinute =
          shift.startMinute < 10 ? `0${shift.startMinute}` : shift.startMinute;
        const endMinute =
          shift.endMinute < 10 ? `0${shift.endMinute}` : shift.endMinute;

        return `${shift.shiftName} (${shift.startHour}:${startMinute} ${shift.startPeriod} - ${shift.endHour}:${endMinute} ${shift.endPeriod})`;
      });

      // Prepare dropdown options
      const resDropdown = [
        {
          category: "Shifts",
          options: shiftOptions,
        },
        {
          category: "Goals",
          options: [
            "UPSC",
            "PCS",
            "IIT JEE",
            "NEET",
            "Government Job",
            "Gate",
            "Others",
          ],
        },
        {
          category: "Facilities",
          options: data.library.libraryFacilities || [],
        },
      ];

      let shiftFees = {};
      for (let i = 0; i < data.library.shifts?.length; i++) {
        shiftFees[data.library.shifts[i].shiftName] =
          data.library.shifts[i].fee;
      }

      resDropdown.push(shiftFees);

      data.options = resDropdown;

      // Dispatch the final data with reduced API calls
      dispatch(fetchUserDetailSuccess({ ...data }));
    }
  } catch (error) {
    dispatch(fetchUserDetailFailure(error.message));
  }
};

export const fetchOptionDetailAction = (user_uid) => async (dispatch) => {
  dispatch(fetchOptionDetailRequest());
  try {
    const userDoc = doc(db, "owner", user_uid);
    const docSnap = await getDoc(userDoc);
    const data = docSnap.data();
    let resDropdown = new Array(3);
    let arr = [];
    let count = 0;

    while (count < data.library.shifts?.length) {
      const shift = data.library.shifts[count];
      if (shift.startMinute < 10) {
        shift.startMinute = "0" + String(shift.startMinute);
      }
      if (shift.endMinute < 10) {
        shift.endMinute = "0" + String(shift.endMinute);
      }
      arr.push(
        shift.shiftName +
          " (" +
          shift.startHour +
          ":" +
          shift.startMinute +
          " " +
          shift.startPeriod +
          " - " +
          shift.endHour +
          ":" +
          shift.endMinute +
          " " +
          shift.endPeriod +
          ")"
      );
      count++;
    }
    resDropdown[0] = { category: "Shifts", options: arr };
    resDropdown[1] = {
      category: "Goals",
      options: [
        "UPSC",
        "PCS",
        "IIT JEE",
        "NEET",
        "Government Job",
        "Gate",
        "Others",
      ],
    };
    resDropdown[2] = {
      category: "Facilities",
      options: data.library.libraryFacilities,
    };
    let shiftFees = {};
    for (let i = 0; i < data.library.shifts?.length; i++) {
      shiftFees[data.library.shifts[i].shiftName] = data.library.shifts[i].fee;
    }

    resDropdown.push(shiftFees);
    dispatch(fetchOptionDetailSuccess(resDropdown));
  } catch (error) {
    dispatch(fetchOptionDetailFailure(error.message));
  }
};

export const addUserDetailAction =
  (userDetails, successCb, errorCb) => async (dispatch) => {
    dispatch(addUserDetailRequest());
    try {
      const user_uid = localStorage.getItem("user_uid");

      const userDoc = doc(db, "owner", user_uid);
      const docSnap = await getDoc(userDoc);
      var docData = docSnap.data();
      docData.user = { ...userDetails, status: true };
      docData.reject = false;
      await setDoc(userDoc, docData);
      dispatch(addUserDetailSuccess(userDetails));
      successCb(userDetails);
     } catch (error) {
      errorCb(error);
      dispatch(addUserDetailFailure(error.message));
    }
  };
