import React, { useState } from "react";
import PopoverSeatDetail from "../../components/seatMap/popoverSeatDetailComponent";
import Loader from "../../sharedCOmponent/loader";
import { useNavigate } from "react-router-dom";
import { getShiftTypes, shiftWiseSeatColor } from "../../util/utils";

const SeatMapIndex = ({ seats, loading, error, approval }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();

  const allAviableShiftTypes = getShiftTypes(seats[0]?.shifts);

  const filteredSeats = seats?.filter((seat) => {
    if (seat?.seatNumber.toLowerCase()?.includes(searchTerm.toLowerCase())) {
      return true;
    }
    return seat?.shifts?.some(
      (shift) =>
        shift.userName?.toLowerCase()?.includes(searchTerm.toLowerCase()) ||
        shift.bookingStatus.toLowerCase()?.includes(searchTerm.toLowerCase())
    );
  });

  return (
    <>
      {loading ? (
        <Loader />
      ) : error ? (
        <p>{error}</p>
      ) : approval ? (
        <div className="me-2 ms-2 ms-md-0 me-md-5">
          <div className="d-flex align-items-center justify-content-between gap-md-0 gap-3 mb-3 mt-md-4 flex-wrap">
            <h1 className="heading_three text-nowrap">Seat details</h1>
            <input
              type="text"
              className="search_input"
              placeholder="Searce name, seat number, or booking status..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
          <div className="d-flex gap-3  mb-4 flex-wrap">
            {shiftWiseSeatColor.map(
              (shift) =>
                allAviableShiftTypes.includes(shift.value) && (
                  <div
                    key={shift.label}
                    className="d-flex gap-1 align-items-center "
                  >
                    <div
                      className={`small-box-seat-color ${shift.label}`}
                    ></div>
                    <span className="small-box-label-text text-nowrap">
                      {shift.value}{" "}
                      {shift.value !== "Full Day Available" && "Booked"}
                    </span>
                  </div>
                )
            )}
          </div>
          <div className="seat-mapping-container">
            {filteredSeats?.length > 0 ? (
              filteredSeats?.map((seat, index) => (
                <PopoverSeatDetail key={index} seat={seat} />
              ))
            ) : (
              <p>No results found</p>
            )}
          </div>
        </div>
      ) : (
        <>
          <div
            className="alert alert-warning text-center mt-5 me-md-5"
            role="alert"
          >
            Please submit your user and library details for verification. You
            will be able to access this screen once the admin approves your
            information.
          </div>
          <button
            className={`button_design_withoutWeight d-flex gap-2 align-items-center  justify-content-center m-auto`}
            onClick={() => navigate("/profile")}
          >
            <span className="material-symbols-outlined icon_dimention">
              arrow_back
            </span>
            <span>Move to Profile Page</span>
          </button>
        </>
      )}
    </>
  );
};

export default SeatMapIndex;
