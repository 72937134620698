import React, { useState, useEffect } from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Tooltip,
  Legend
);

const ActiveStudentsGraph = ({ studentsData }) => {
  const [graphData, setGraphData] = useState(null);

  // Utility function to check if the student was active on a given date
  const isStudentActiveOnDate = (student, date) => {
    return student.subscription.some((sub) => {
      const startDate = new Date(sub.startDate);
      let endDate = new Date(sub.endDate);
      endDate.setDate(endDate.getDate() + 1);
      return startDate <= date && endDate >= date;
    });
  };

  // Fetch current date and past 10 days
  const getPastDates = () => {
    const dates = [];
    const today = new Date();
    for (let i = 0; i < 10; i++) {
      const pastDate = new Date(today);
      pastDate.setDate(today.getDate() - i);
      dates.push(pastDate);
    }
    return dates.reverse();
  };

  useEffect(() => {
    if (!studentsData || studentsData?.length === 0) return;

    const dates = getPastDates();

    const activeCounts = dates?.map((date) => {
      let activeStudents = 0;
      studentsData.forEach((student) => {
        if (isStudentActiveOnDate(student, date)) {
          activeStudents++;
        }
      });
      return activeStudents;
    });

    // Prepare graph data
    const formattedData = {
      labels: dates?.map((date) => {
        return new Intl.DateTimeFormat("en-GB", {
          day: "numeric",
          month: "short",
        }).format(date);
      }),
      datasets: [
        {
          label: "Active students",
          data: activeCounts,
          borderColor: "rgba(75, 192, 192, 1)",
          backgroundColor: "rgba(75, 192, 192, 0.2)",
          fill: true,
          tension: 0.4,
        },
      ],
    };

    setGraphData(formattedData);
  }, [studentsData]);

  if (!graphData) {
    return <p className="text-center mt-4">No data available</p>;
  }

  return (
    <div className="graph-container" style={{ height: "280px", width: "100%" }}>
      <Line
        data={graphData}
        options={{
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            y: {
              ticks: {
                stepSize: 1,
                callback: function (value) {
                  return Number.isInteger(value) ? value : null;
                },
              },
              grid: {
                display: false,
              },
            },
            x: {
              grid: {
                display: false,
              },
            },
          },
          plugins: {
            legend: {
              display: true,
              position: "bottom",
            },
          },
        }}
      />
    </div>
  );
};

export default ActiveStudentsGraph;
