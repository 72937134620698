import React from "react";
import PieChartComponent from "../../sharedCOmponent/pieChartComponent";

const TextCardComponents = ({
  data,
  show,
  mainColor,
  desc,
  icon,
  cutout,
  icon_background,
  textPara,
  number,
  pie_char_con_class,
  icon_type,
}) => {
  return (
    <div className="text_card_container_dash_small d-flex justify-content-between align-items-center">
      <div className="d-flex flex-column">
        <p className="card_para_text_dash">{textPara}</p>
        <h3 className="card_heading_text_dash">{number}</h3>
      </div>
      {icon ? (
        <span
          style={{ background: icon_background }}
          className="material-symbols-outlined icon_card_dash"
        >
          {icon_type}
        </span>
      ) : (
        <div className=" col-3 p-0 pie_char_div_con">
          <PieChartComponent
            data={data}
            mainColor={mainColor}
            show={show}
            desc={desc}
            cutout={cutout}
            pie_char_con_class={pie_char_con_class}
          />
        </div>
      )}
    </div>
  );
};

export default TextCardComponents;
