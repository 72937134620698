import React, { useState, useEffect } from "react";
import { defaultFacilities } from "../../util/utils";

const LibraryFacilities = ({ setFieldValue, initialFacilities = [] }) => {
  // Initialize selected facilities with initialFacilities
  const [selectedFacilities, setSelectedFacilities] = useState(
    initialFacilities || []
  );

  // Initialize custom facilities by filtering initialFacilities that aren't in defaultFacilities
  const [customFacilities, setCustomFacilities] = useState(
    initialFacilities.filter(
      (facility) => !defaultFacilities.includes(facility)
    ) || []
  );

  const [showInput, setShowInput] = useState(false);
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    setFieldValue(
      "libraryFacilities",
      selectedFacilities.filter((f) => f !== "Select All")
    );
  }, [selectedFacilities, setFieldValue]);

  const toggleFacility = (facility) => {
    if (facility === "Select All") {
      if (selectedFacilities.includes("Select All")) {
        // If "Select All" is already selected, deselect everything
        setSelectedFacilities([]);
      } else {
        // Select all facilities including custom ones
        setSelectedFacilities([
          "Select All",
          ...defaultFacilities.filter((f) => f !== "Select All"),
          ...customFacilities,
        ]);
      }
      return;
    }

    if (selectedFacilities.includes(facility)) {
      // Remove facility and "Select All" if present
      setSelectedFacilities(
        selectedFacilities.filter((f) => f !== facility && f !== "Select All")
      );
    } else {
      const newSelected = [...selectedFacilities, facility];
      // Add "Select All" if all facilities are selected
      if (
        newSelected.length ===
        defaultFacilities.length - 1 + customFacilities.length
      ) {
        newSelected.push("Select All");
      }
      setSelectedFacilities(newSelected);
    }
  };

  const handleCustomFacilityAdd = () => {
    const newFacility = inputValue.trim();
    if (
      newFacility &&
      !customFacilities.includes(newFacility) &&
      !defaultFacilities.includes(newFacility)
    ) {
      setCustomFacilities([...customFacilities, newFacility]);
      setSelectedFacilities([...selectedFacilities, newFacility]);
    }
    setInputValue("");
    setShowInput(false);
  };

  const handleCustomFacilityAddKey = (event) => {
    const delimiterKeys = ["Enter", ","];
    if (delimiterKeys.includes(event.key) && inputValue.trim() !== "") {
      const newCustomFacility = inputValue.trim();
      if (
        !customFacilities.includes(newCustomFacility) &&
        !defaultFacilities.includes(newCustomFacility)
      ) {
        const updatedCustomFacilities = [
          ...customFacilities,
          newCustomFacility,
        ];
        const updatedSelectedFacilities = [
          ...selectedFacilities,
          newCustomFacility,
        ];

        setCustomFacilities(updatedCustomFacilities);
        setSelectedFacilities(updatedSelectedFacilities);
      }
      setInputValue("");
      setShowInput(false);
    }
  };

  const handleBlur = () => {
    if (inputValue.trim()) {
      handleCustomFacilityAdd();
    }
  };

  const toggleOtherInput = () => {
    setShowInput(true);
  };

  return (
    <div className="library-facilities-container">
      <div className="library-facilities-card">
        <div className="library-facilities-grid">
          {[...defaultFacilities, ...customFacilities].map((facility) => (
            <div
              key={facility}
              className={`facility-item ${
                selectedFacilities.includes(facility) ? "selected" : ""
              }`}
              onClick={() => toggleFacility(facility)}
            >
              {facility}
            </div>
          ))}
          <div className="facility-item add-other" onClick={toggleOtherInput}>
            {showInput ? (
              <input
                type="text"
                className="custom-input"
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                onBlur={handleBlur}
                placeholder="Type and click Enter/Next"
                autoFocus
                onKeyDown={handleCustomFacilityAddKey}
              />
            ) : (
              "+ Add Facility"
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LibraryFacilities;
