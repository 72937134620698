import React, { useState } from "react";
import { Modal, ListGroup } from "react-bootstrap";
import { Formik, Field, ErrorMessage, Form } from "formik";
import DatePicker from "react-datepicker";
import * as Yup from "yup";
import "react-datepicker/dist/react-datepicker.css";
import { formSeats2 } from "../../util/checkSeatAvaFunction";

// Validation schema using Yup
const validationSchema = Yup.object().shape({
  subscription: Yup.array().of(
    Yup.object().shape({
      startDate: Yup.date().required("Start date is required"),
      endDate: Yup.date()
        .min(Yup.ref("startDate"), "End date cannot be before start date")
        .required("End date is required"),
    })
  ),
  shifts: Yup.array().of(
    Yup.object().shape({
      startHour: Yup.number().required("Required"),
      startMinute: Yup.number().required("Required"),
      startPeriod: Yup.string().required("Required"),
      endHour: Yup.number().required("Required"),
      endMinute: Yup.number().required("Required"),
      endPeriod: Yup.string().required("Required"),
    })
  ),
});

function ModalSeatAvaComponent({
  handleOpenModalSeatAva,
  handleCloseModalSeatAva,
  showModalSeatAva,
  members,
  library_details,
  seats,
}) {
  // Define hours, minutes, and periods options
  const hours = Array.from({ length: 12 }, (_, i) => (i + 1).toString());
  const minutes = [0, 15, 30, 45];
  const periods = ["AM", "PM"];

  const [showDetails, setShowDetails] = useState(false);
  const [submittedData, setSubmittedData] = useState(null);
  const [checkSeats, setCheckSeats] = useState();

  const initialValues = {
    subscription: [
      {
        startDate: null,
        endDate: null,
      },
    ],
    shifts: [
      {
        startHour: 7,
        startMinute: 0,
        startPeriod: "AM",
        endHour: 2,
        endMinute: 0,
        endPeriod: "PM",
      },
    ],
  };

  // Handle form submission
  const handleSubmit = (values) => {
    const { subscription, shifts } = values;

    const formattedDataUI = {
      startDate: subscription[0].startDate,
      endDate: subscription[0].endDate,
      startTime: `${shifts[0].startHour}:${shifts[0].startMinute} ${shifts[0].startPeriod}`,
      endTime: `${shifts[0].endHour}:${shifts[0].endMinute} ${shifts[0].endPeriod}`,
    };

    const formattedData = {
      startDate: subscription[0].startDate,
      endDate: subscription[0].endDate,
      startHour: parseInt(shifts[0].startHour),
      startMinute: parseInt(shifts[0].startMinute),
      startPeriod: shifts[0].startPeriod,
      endHour: parseInt(shifts[0].endHour),
      endMinute: parseInt(shifts[0].endMinute),
      endPeriod: shifts[0].endPeriod,
    };
    const seatDataFinal = formSeats2(
      library_details,
      members,
      seats,
      formattedData?.startDate,
      formattedData?.endDate,
      formattedData?.startHour,
      formattedData?.startMinute,
      formattedData?.startPeriod,
      formattedData?.endHour,
      formattedData?.endMinute,
      formattedData?.endPeriod
    );

    setSubmittedData(formattedDataUI);
    setShowDetails(true);
    setCheckSeats(seatDataFinal);
  };

  // Utility function to format date
  const formatDate = (date) => {
    const options = { day: "2-digit", month: "short", year: "numeric" };
    return new Intl.DateTimeFormat("en-GB", options).format(date);
  };

  return (
    <Modal
      show={showModalSeatAva}
      onHide={handleCloseModalSeatAva}
      backdrop="static"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title className="heading_four">
          {showDetails ? "Available seats" : " Check seat availability"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!showDetails && (
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ values, errors, touched, setFieldValue }) => (
              <Form>
                <div className="row gap-3 px-md-3">
                  <div className="col-12 px-3">
                    <label className="label_text w-100">
                      Subscription start date
                    </label>
                    <DatePicker
                      selected={values.subscription[0].startDate}
                      onChange={(date) =>
                        setFieldValue("subscription[0].startDate", date)
                      }
                      selectsStart
                      startDate={values.subscription[0].startDate}
                      endDate={values.subscription[0].endDate}
                      dateFormat="dd/MM/yyyy"
                      placeholderText="Start Date"
                      className={`form-control input_feild ${
                        errors.subscription?.[0]?.startDate &&
                        touched.subscription?.[0]?.startDate
                          ? "is-invalid"
                          : ""
                      }`}
                    />
                    <ErrorMessage
                      name="subscription[0].startDate"
                      component="div"
                      className="text-danger"
                    />
                  </div>

                  <div className="col-12 px-3">
                    <label className="label_text w-100">
                      Subscription end date
                    </label>
                    <DatePicker
                      selected={values.subscription[0].endDate}
                      onChange={(date) =>
                        setFieldValue("subscription[0].endDate", date)
                      }
                      selectsEnd
                      startDate={values.subscription[0].startDate}
                      endDate={values.subscription[0].endDate}
                      minDate={values.subscription[0].startDate}
                      dateFormat="dd/MM/yyyy"
                      placeholderText="End Date"
                      className={`form-control input_feild ${
                        errors.subscription?.[0]?.endDate &&
                        touched.subscription?.[0]?.endDate
                          ? "is-invalid"
                          : ""
                      }`}
                    />
                    <ErrorMessage
                      name="subscription[0].endDate"
                      component="div"
                      className="text-danger"
                    />
                  </div>

                  <div className="d-flex flex-wrap px-3 ">
                    <div className="w-50 pe-3">
                      <label className="label_text">Start Time</label>
                      <div className="d-flex">
                        <Field
                          as="select"
                          name="shifts[0].startHour"
                          className="form-control me-2"
                        >
                          {hours?.map((hour) => (
                            <option key={hour} value={hour}>
                              {hour}
                            </option>
                          ))}
                        </Field>
                        <Field
                          as="select"
                          name="shifts[0].startMinute"
                          className="form-control me-2"
                        >
                          {minutes?.map((minute) => (
                            <option key={minute} value={minute}>
                              {minute.toString().padStart(2, "0")}
                            </option>
                          ))}
                        </Field>
                        <Field
                          as="select"
                          name="shifts[0].startPeriod"
                          className="form-control me-0"
                        >
                          {periods?.map((period) => (
                            <option key={period} value={period}>
                              {period}
                            </option>
                          ))}
                        </Field>
                      </div>
                      <ErrorMessage
                        name="shifts[0].startHour"
                        component="div"
                        className="text-danger"
                      />
                      <ErrorMessage
                        name="shifts[0].startMinute"
                        component="div"
                        className="text-danger"
                      />
                      <ErrorMessage
                        name="shifts[0].startPeriod"
                        component="div"
                        className="text-danger"
                      />
                    </div>

                    <div className="w-50 ps-3">
                      <label className="label_text">End Time</label>
                      <div className="d-flex">
                        <Field
                          as="select"
                          name="shifts[0].endHour"
                          className="form-control me-2"
                        >
                          {hours?.map((hour) => (
                            <option key={hour} value={hour}>
                              {hour}
                            </option>
                          ))}
                        </Field>
                        <Field
                          as="select"
                          name="shifts[0].endMinute"
                          className="form-control me-2"
                        >
                          {minutes?.map((minute) => (
                            <option key={minute} value={minute}>
                              {minute.toString().padStart(2, "0")}
                            </option>
                          ))}
                        </Field>
                        <Field
                          as="select"
                          name="shifts[0].endPeriod"
                          className="form-control me-0"
                        >
                          {periods?.map((period) => (
                            <option key={period} value={period}>
                              {period}
                            </option>
                          ))}
                        </Field>
                      </div>
                      <ErrorMessage
                        name="shifts[0].endHour"
                        component="div"
                        className="text-danger"
                      />
                      <ErrorMessage
                        name="shifts[0].endMinute"
                        component="div"
                        className="text-danger"
                      />
                      <ErrorMessage
                        name="shifts[0].endPeriod"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                  </div>

                  <div className="mt-3 d-flex justify-content-center border-top pt-2">
                    <button
                      type="submit"
                      className="button_design pri_bg text-white py-1"
                    >
                      Check availability
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        )}

        {/* Show details after submission */}
        {showDetails && submittedData && (
          <div>
            <div className="d-flex flex-wrap gap-3 date-time-check-ava">
              <p className="mb-0 ">
                {`${formatDate(submittedData.startDate)} -> ${formatDate(
                  submittedData.endDate
                )} `}
              </p>

              <p className="mb-0 ">
                {" "}
                {`  ${submittedData.startTime} -> ${submittedData.endTime}`}
              </p>
            </div>
            {checkSeats?.length > 0 ? (
              <ListGroup className="mt-3">
                {checkSeats?.map((seat, index) => (
                  <ListGroup.Item
                    key={index}
                    className="d-flex justify-content-between align-items-center p-2 px-3"
                  >
                    <span className="seat-text-check-seatAvai">
                      <span className="text_lightGray ">Seat Number:</span>{" "}
                      {seat.seatNo}
                    </span>
                    <span className="floor-text-check-seatAvai">
                      {seat.floor}
                    </span>
                  </ListGroup.Item>
                ))}
              </ListGroup>
            ) : (
              <p>No Data Found</p>
            )}
            <div className="pt-3">
              <button
                className="btn button_design  d-flex  m-auto outline-none shadow-none d-flex align-items-center gap-2"
                onClick={() => setShowDetails(false)}
              >
                <span className="material-symbols-outlined icon_dimention">
                  arrow_back
                </span>
                <span>Clear filter</span>
              </button>
            </div>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
}

export default ModalSeatAvaComponent;
