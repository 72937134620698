import React, { useState, useRef } from "react";
import { Button, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { logoutAction } from "../../action/login/loginAction";
import CustomForm from "./studentAddForm";
import { useNavigate } from "react-router-dom";
import QRCode from "react-qr-code";
import { toPng } from "html-to-image";
import { getBaseURL } from "../../util/utilsFunction";
import libLogo from "../../asset/image/librify_logo.svg";
import ModalSeatAvaComponent from "./modalSeatAvaComponent";
import { generateSeatNames } from "../../util/checkSeatAvaFunction";
import CustomShiftAddStudent from "./customShiftAddStudent";
import HeaderProfileComponent from "./headerProfileComponent";

const HeaderComponentDashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const newUserDetail = useSelector((state) => state.newUserDetail);
  const members = newUserDetail?.data?.members;
  const library_details = newUserDetail?.data?.library;
  const seats = generateSeatNames(newUserDetail?.data?.library?.floors);
  const library_name = newUserDetail?.data?.library?.libraryName;

  localStorage.setItem("library_name", library_name);
  const user_uid = localStorage.getItem("user_uid");
  const domainUrl = getBaseURL(window.location);
  const baseUrl = encodeURI(
    `${domainUrl}student-form/${library_name}/${user_uid}`
  );
  const [showModal, setShowModal] = useState(false);
  const [showQrModal, setShowQrModal] = useState(false);
  const [showModalSeatAva, setShowModalSeatAva] = useState(false);
  const [showModalCustomStu, setShowModalCustomStu] = useState(false);

  const qrRef = useRef(null);

  const handleLogout = () => {
    dispatch(logoutAction(() => navigate("/login")));
    localStorage.clear();
  };

  const handleOpenModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const handleOpenModalSeatAva = () => setShowModalSeatAva(true);
  const handleCloseModalSeatAva = () => setShowModalSeatAva(false);

  const handleOpenModalCustomStu = () => setShowModalCustomStu(true);
  const handleCloseModalCustomStu = () => setShowModalCustomStu(false);

  const handleQrModalOpen = () => setShowQrModal(true);
  const handleQrModalClose = () => setShowQrModal(false);

  const handleQrGenerate = () => {
    handleQrModalOpen();
  };

  const handleDownloadQR = () => {
    if (qrRef.current) {
      toPng(qrRef.current)
        .then((dataUrl) => {
          const link = document.createElement("a");
          link.href = dataUrl;
          link.download = "librify-qr-code.png";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
        .catch((err) => {
          console.error("Failed to download QR code", err);
        });
    }
  };

  const handleNavigateWeb = () => {
    if (user_uid) {
      const encodedLibraryName = encodeURIComponent(library_name);
      navigate(`/student-form/${encodedLibraryName}/${user_uid}`);
    }
  };
  return (
    <div className="dashboar_header_container">
      <div className="d-flex align-items-center justify-content-between px-md-5 px-2 py-3">
        <h4 className="heading_four text_black mb-0">
          <img className="logo-width" src={libLogo} alt="" />
        </h4>
        <nav className="nav-header-landing d-flex align-items-center  gap-3 gap-md-4  space_menu_900">
          {/* <button
            className={`d-flex align-items-center add-student-btn-header p-0 ${
              !newUserDetail.data.approval && "disable_button"
            }`}
            onClick={handleOpenModalSeatAva}
            disabled={!newUserDetail.data.approval}
          >
            <span className="material-symbols-outlined icon_dimension ">
              chair
            </span>
          </button> */}
          <button
            className={`d-flex align-items-center  add-student-btn-header p-0 ${
              !newUserDetail.data.approval && "disable_button"
            }`}
            onClick={handleOpenModal}
            disabled={!newUserDetail.data.approval}
          >
            <span className="material-symbols-outlined icon_dimension ">
              person_add
            </span>
          </button>
          {/* <button
            className={`d-flex align-items-center  add-student-btn-header p-0 ${
              !newUserDetail.data.approval && "disable_button"
            }`}
            onClick={handleOpenModalCustomStu}
            disabled={!newUserDetail.data.approval}
          >
            <span className="material-symbols-outlined icon_dimension ">
              group_search
            </span>
          </button> */}
          <button
            disabled={!newUserDetail.data.approval}
            className={`d-flex gap-1 align-items-center p-0 text_gray  ${
              !newUserDetail.data.approval && "disable_button"
            }`}
            onClick={handleQrGenerate}
          >
            <span className="material-symbols-outlined icon_dimension">
              qr_code
            </span>
          </button>
          <HeaderProfileComponent handleLogout={handleLogout} branchData={newUserDetail.data}/>
        </nav>
      </div>

      <Modal
        show={showQrModal}
        onHide={handleQrModalClose}
        backdrop="static"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="heading_four text_gray">
            Download your QR code
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <div ref={qrRef} className="qr-code-container">
            <div className="qr-code-header">
              <h2 className="qr-library-name">Librify</h2>
              <p className=" qr-company-name">{library_name}</p>
            </div>
            <div className="qr-code-content">
              <QRCode value={baseUrl} />
            </div>
            <div className="qr-code-footer">
              <p>Scan to Join {library_name}</p>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="col-12 d-flex border-0 gap-2 outline-0 justify-content-between my-2 ">
            <Button
              className="button_design w-50 d-flex align-items-center justify-content-center shadow-none"
              variant="outline"
              onClick={handleNavigateWeb}
            >
              <span>Website</span>
              <span className="material-symbols-outlined icon_dimention">
                north_east
              </span>
            </Button>

            <Button
              className="pri_bg button_design w-50 shadow-none"
              variant="secondary"
              type="submit"
              onClick={handleDownloadQR}
            >
              Download QR
            </Button>
          </div>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showModal}
        onHide={handleCloseModal}
        backdrop="static"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Student</Modal.Title>
        </Modal.Header>
        {}
        <Modal.Body>
          <CustomForm
            onClose={handleCloseModal}
            dropdownData={newUserDetail?.data?.options}
            libraryData={newUserDetail?.data}
            seats={seats}
          />
        </Modal.Body>
      </Modal>

      <Modal
        show={showModalCustomStu}
        onHide={handleCloseModalCustomStu}
        backdrop="static"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Student (Custom shift)</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CustomShiftAddStudent
            onClose={handleCloseModalCustomStu}
            dropdownData={newUserDetail.data.options}
            library_details={library_details}
            members={members}
            seats={seats}
          />
        </Modal.Body>
      </Modal>

      <ModalSeatAvaComponent
        handleOpenModalSeatAva={handleOpenModalSeatAva}
        handleCloseModalSeatAva={handleCloseModalSeatAva}
        showModalSeatAva={showModalSeatAva}
        members={members}
        library_details={library_details}
        seats={seats}
      />
    </div>
  );
};

export default HeaderComponentDashboard;
