import React, { useState, useEffect } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { isActive } from "../../util/utilsFunction";
import wordsToNumbers from "words-to-numbers";

const TableStudentComponent = ({ data, onView, onEdit }) => {
  const [rowData, setRowData] = useState(data);
  const [sortedData, setSortedData] = useState([]);
  const [sortConfig, setSortConfig] = useState({
    key: "seatNo",  
    direction: "ascending",  
  });

  useEffect(() => {
    setRowData(data);
  }, [data]);

  useEffect(() => {
    if (rowData) {
      let sortedArray = [...rowData];
      if (
        sortConfig.key !== null &&
        sortConfig.key !== "seatNo" &&
        sortConfig.direction !== null
      ) {
        sortedArray.sort((a, b) => {
               // Custom sorting logic for specific columns
          switch (sortConfig.key) {
            case "seatNo":
              return compareSeatNumbers(
                activeStudent(a)?.seatNo, 
                activeStudent(b)?.seatNo, 
                sortConfig.direction
              );
            case "endDate":
              return compareDates(
                activeStudent(a)?.endDate, 
                activeStudent(b)?.endDate, 
                sortConfig.direction
              );
            default:
                  // Default sorting for other columns
              if (a[sortConfig.key] < b[sortConfig.key]) {
                return sortConfig.direction === "ascending" ? -1 : 1;
              }
              if (a[sortConfig.key] > b[sortConfig.key]) {
                return sortConfig.direction === "ascending" ? 1 : -1;
              }
              return 0;
          }
        });
      } else {
        sortedArray.sort((a, b) => {
          let subArrLenA = a?.subscription.length;
          let subArrLenB = b?.subscription.length;

          if (
            a?.subscription[subArrLenA - 1][sortConfig.key] +
              a?.subscription[subArrLenA - 1]["shift"][0] <
            b?.subscription[subArrLenB - 1][sortConfig.key] +
              b?.subscription[subArrLenB - 1]["shift"][0]
          ) {
            return sortConfig.direction === "ascending" ? -1 : 1;
          }
          if (
            a?.subscription[subArrLenA - 1][sortConfig.key] +
              a?.subscription[subArrLenA - 1]["shift"][0] >
            b?.subscription[subArrLenB - 1][sortConfig.key] +
              b?.subscription[subArrLenB - 1]["shift"][0]
          ) {
            return sortConfig.direction === "ascending" ? 1 : -1;
          }
          return 0;
        });
      }
      setSortedData(sortedArray);
    }
  }, [rowData, sortConfig]);

  // Custom sorting function for seat numbers
  const compareSeatNumbers = (seatA, seatB, direction) => {
    if (!seatA) return direction === "ascending" ? 1 : -1;
    if (!seatB) return direction === "ascending" ? -1 : 1;
    
    const compareValue = seatA.localeCompare(seatB);
    return direction === "ascending" ? compareValue : -compareValue;
  };

  // Custom sorting function for dates
  const compareDates = (dateA, dateB, direction) => {
    if (!dateA) return direction === "ascending" ? 1 : -1;
    if (!dateB) return direction === "ascending" ? -1 : 1;
    
    const dateValueA = new Date(dateA);
    const dateValueB = new Date(dateB);
    
    const compareValue = dateValueA - dateValueB;
    return direction === "ascending" ? compareValue : -compareValue;
  };

  const handleSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    } else if (
      sortConfig.key === key &&
      sortConfig.direction === "descending"
    ) {
      direction = null;
    }
    setSortConfig({ key, direction });
  };

  function activeStudent(row) {
    let activeSubStu = row.subscription?.[row.subscription?.length - 1];

    row.subscription?.forEach((sub) => {
      if (isActive(sub)) {
        activeSubStu = sub;
      }
    });
    return activeSubStu;
  }

  return (
    <div className="mt-4">
      <div 
        className="overflow-y-auto table-scroll pri_radious" 
        style={{ 
          maxHeight: "calc(12 * 46px)",  
        }}
      >
        <table className="table custom-table-dashboard w-full">
          <thead className="sticky top-0 bg-white z-10">
            <tr>
              <th className="header-cell pri_border_bottom text-nowrap">
                <p className="d-flex border-0 bg-transparent m-0 p-0 fw-bold text_gray">
                  #
                </p>
              </th>
              <th
                className="header-cell pri_border_bottom text-nowrap"
                onClick={() => handleSort("memberID")}
              >
                <button className="d-flex border-0 bg-transparent m-0 p-0 fw-bold text_gray">
                  Student ID
                  <span className="sort-icon ms-0">
                    {sortConfig.key === "memberID" &&
                    sortConfig.direction === "ascending" ? (
                      <span className="material-symbols-outlined icon_dimention">
                        keyboard_arrow_down
                      </span>
                    ) : sortConfig.key === "memberID" &&
                      sortConfig.direction === "descending" ? (
                      <span className="material-symbols-outlined icon_dimention">
                        keyboard_arrow_up
                      </span>
                    ) : (
                      <span className="material-symbols-outlined icon_dimention"></span>
                    )}
                  </span>
                </button>
              </th>
              <th
                className="header-cell pri_border_bottom text-nowrap"
                onClick={() => handleSort("name")}
              >
                <button className="d-flex border-0 bg-transparent m-0 p-0 fw-bold text_gray">
                  Name
                  <span className="sort-icon">
                    {sortConfig.key === "name" &&
                    sortConfig.direction === "ascending" ? (
                      <span className="material-symbols-outlined icon_dimention">
                        keyboard_arrow_down
                      </span>
                    ) : sortConfig.key === "name" &&
                      sortConfig.direction === "descending" ? (
                      <span className="material-symbols-outlined icon_dimention">
                        keyboard_arrow_up
                      </span>
                    ) : (
                      <span className="material-symbols-outlined icon_dimention"></span>
                    )}
                  </span>
                </button>
              </th>
              <th
                className="header-cell pri_border_bottom text-nowrap"
                onClick={() => handleSort("mobile")}
              >
                <button className="d-flex border-0 bg-transparent m-0 p-0 fw-bold text_gray">
                  Number
                  <span className="sort-icon">
                    {sortConfig.key === "mobile" &&
                    sortConfig.direction === "ascending" ? (
                      <span className="material-symbols-outlined icon_dimention">
                        keyboard_arrow_down
                      </span>
                    ) : sortConfig.key === "mobile" &&
                      sortConfig.direction === "descending" ? (
                      <span className="material-symbols-outlined icon_dimention">
                        keyboard_arrow_up
                      </span>
                    ) : (
                      <span className="material-symbols-outlined icon_dimention"></span>
                    )}
                  </span>
                </button>
              </th>
              <th
                className="header-cell pri_border_bottom text-nowrap"
                onClick={() => handleSort("seatNo")}
              >
                <button className="d-flex border-0 bg-transparent m-0 p-0 fw-bold text_gray">
                  Seat No.
                  <span className="sort-icon">
                    {sortConfig.key === "seatNo" &&
                    sortConfig.direction === "ascending" ? (
                      <span className="material-symbols-outlined icon_dimention">
                        keyboard_arrow_down
                      </span>
                    ) : sortConfig.key === "seatNo" &&
                      sortConfig.direction === "descending" ? (
                      <span className="material-symbols-outlined icon_dimention">
                        keyboard_arrow_up
                      </span>
                    ) : (
                      <span className="material-symbols-outlined icon_dimention"></span>
                    )}
                  </span>
                </button>
              </th>
              <th
                className="header-cell pri_border_bottom text-nowrap"
                onClick={() => handleSort("endDate")}
              >
                <button className="d-flex border-0 bg-transparent m-0 p-0 fw-bold text_gray">
                  End Date
                  <span className="sort-icon">
                    {sortConfig.key === "endDate" &&
                    sortConfig.direction === "ascending" ? (
                      <span className="material-symbols-outlined icon_dimention">
                        keyboard_arrow_down
                      </span>
                    ) : sortConfig.key === "endDate" &&
                      sortConfig.direction === "descending" ? (
                      <span className="material-symbols-outlined icon_dimention">
                        keyboard_arrow_up
                      </span>
                    ) : (
                      <span className="material-symbols-outlined icon_dimention"></span>
                    )}
                  </span>
                </button>
              </th>
              <th className="header-cell pri_border_bottom text-nowrap">
                <p className="d-flex border-0 bg-transparent m-0 p-0 fw-bold text_gray">
                  Action
                </p>
              </th>
            </tr>
          </thead>
          <tbody>
            {sortedData && sortedData?.length > 0 ? (
              sortedData?.map((row, index) => (
                <tr key={row.memberID}>
                  <td className="table_row_cell text-nowrap">{index + 1}</td>
                  <td className="table_row_cell text-nowrap">{row.memberID}</td>
                  <td className="table_row_cell text-nowrap">{row.name}</td>
                  <td className="table_row_cell text-nowrap">{row.mobile}</td>
                  <td className="table_row_cell text-nowrap">
                    {(() => {
                      const activeSubStu = activeStudent(row);
                      return activeSubStu ? (
                        <>
                          {activeSubStu.seatNo} (
                          {Array.isArray(activeSubStu.shift)
                            ? activeSubStu.shift
                                ?.map((shift) => shift.split(" (")[0])
                                .sort((a, b) => {
                                  const aWord = a
                                    .toLowerCase()
                                    ?.replace("shift ", "");
                                  const bWord = b
                                    .toLowerCase()
                                    ?.replace("shift ", "");

                                  const aNum = wordsToNumbers(aWord);
                                  const bNum = wordsToNumbers(bWord);

                                  return aNum - bNum;
                                })
                                .join(", ")
                            : activeSubStu.shift || ""}
                          )
                        </>
                      ) : (
                        "No active subscription"
                      );
                    })()}
                  </td>

                  <td className="table_row_cell text-nowrap">
                    {new Date(activeStudent(row)?.endDate).toLocaleDateString(
                      "en-GB",
                      {
                        day: "2-digit",
                        month: "long",
                      }
                    )}
                  </td>
                  <td className="d-flex gap-1 table_row_cell text-nowrap">
                 
                    <button
                        className="border-0 bg-transparent d-flex align-items-center gap-1"
                        onClick={() => onView(row)}
                      >
                        <span className="material-symbols-outlined icon_dimention">
                          visibility
                        </span>
                        <p className="table_row_cell text-nowrap mb-0">View</p>
                      </button>

                    <button
                      className="border-0 bg-transparent d-flex align-items-center gap-1"
                      onClick={() => onEdit(row)}
                    >
                      <span className="material-symbols-outlined icon_dimention">
                        Edit
                      </span>
                      <p className="table_row_cell text-nowrap mb-0">Edit</p>
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="7" className="text-center">
                  No Data Available
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {/* Total count section */}
      <div 
        className="mt-2 p-2 border-top d-flex justify-content-end align-items-center student-count-table"
      >
        <span className="fw-bold">Total Students - </span>
        <span> {sortedData?.length || 0}</span>
      </div>
    </div>
  );
};

export default TableStudentComponent;