import React from "react";
import { useNavigate } from "react-router-dom";
import FooterLanding from "./footer";
import deepakImage from "../../asset/image/deepak.svg";
import rishiImage from "../../asset/image/rishi.svg";
import chiradeepImage from "../../asset/image/chiradeep.svg";

const features = [
  // Features array remains the same...
];

const AboutPageComponent = () => {
  const navigate = useNavigate();

  const handleNavigateJoin = () => {
    navigate("/login");
  };

  return (
    <div>
      {/* Hero Section */}
      <section className="hero-section animate__animated animate__fadeIn">
        <header>
          <h1 className="hero-title">About Us</h1>
          <p className="hero-subtitle">Our Journey, Team, and Values</p>
        </header>
      </section>
      {/* Who We Are Section */}
      <section className="container who-we-are-section mt-5">
        <div className="row text-center animate__animated animate__fadeInUp">
          <div className="col-12 section-head mb-4">
            <h4>
              <span>Who</span> We Are
            </h4>
          </div>
          <div className="col-md-6 mb-4 animate__animated animate__fadeInLeft">
            <div className="who-we-are-description p-4 bg-white d-flex flex-column align-items-start">
              <span className="material-symbols-outlined icon_about_us_page">
                favorite
              </span>
              <h5>About Us</h5>
              <p className="text-start mb-0">
                We are three passionate engineers from IIT and NIT who aim to
                solve real challenges for fellow Indians. While working in top
                MNCs, we dream of creating meaningful solutions that truly
                resonate. Our mission is to deliver products that provide
                quality experiences, empowering millions across our great
                nation.
              </p>
            </div>
          </div>
          <div className="col-md-6 mb-4 animate__animated animate__fadeInRight">
            <div className="who-we-are-description p-4 bg-white d-flex flex-column align-items-start">
              <span className="material-symbols-outlined icon_about_us_page">
                grass
              </span>
              <h5>Our Mission</h5>
              <p className="text-start mb-0">
                To craft innovative products that tackle genuine problems faced
                by users, creating strong connections and a sense of value. As
                an Indian startup, we aspire to showcase our unique solutions
                globally, making India proud while positively impacting
                countless lives and enriching communities in the process.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Our Team Section */}
      <section className="container m-auto row about-us-our-team my-5 gap-y-3 mb-5 pb-5">
        <div className="col-12 section-head text-center mb-4 mt-5 animate__animated animate__fadeInDown">
          <h4>
            <span>Our</span> Team
          </h4>
          <p className="section-subtitle">Get to Know Our Team</p>
        </div>
        {[
          {
            name: "Deepak Shukla",
            description:
              "Software Engineer at a Top MNC<br>MNIT Jaipur Graduate",
            imageUrl: deepakImage,
          },
          {
            name: "Rishi Sethia",
            description:
              "Software Engineer at a Top MNC<br>IIT Roorkee Graduate",
            imageUrl: rishiImage,
          },
          {
            name: "Chiradeep Saha Roy",
            description:
              "Software Developer at a Top MNC<br>IIT Roorkee Graduate",
            imageUrl: chiradeepImage,
          },
        ]?.map((teamMember, index) => (
          <div
            className="col-md-4 col-12 animate__animated animate__zoomIn"
            key={index}
          >
            <article className="w-100 text-center">
              <figure className="w-100">
                <img
                  src={teamMember.imageUrl}
                  alt={`${teamMember.name} profile`}
                  className="img-fluid"
                />
              </figure>
              <h2 className="name_about">{teamMember.name}</h2>
              <p
                className="name_para"
                dangerouslySetInnerHTML={{ __html: teamMember.description }}
              />
            </article>
          </div>
        ))}
      </section>

      <FooterLanding handleNavigateJoin={handleNavigateJoin} />
    </div>
  );
};

export default AboutPageComponent;
