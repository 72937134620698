import { useState } from "react";
import { Popover, OverlayTrigger, Tooltip } from "react-bootstrap";
import ModalStudentDetailComponent from "../../components/studentsDetails/modalStudentDetailComponent";
import { checkBookingStatus } from "../../util/seatStatusCalculationFunction";
import { dynamicCssForSeats } from "../../util/utils";
import { checkBufferBookingStatus } from "../../util/utilsFunction";

const PopoverSeatDetail = ({ seat }) => {
  const [modalData, setModalData] = useState(null);
  const [visiblePopover, setVisiblePopover] = useState(null); // Track the currently visible popover

  const handlePopoverToggle = (seatNumber) => {
    setVisiblePopover(seatNumber);
  };

  const handleModalOpen = (member) => {
    setModalData(member);
    setVisiblePopover(null);
  };

  const popover = (
    <Popover
      id={`popover-seat-${seat.seatNumber}`}
      className="seat-detail-container"
    >
      <div className="seat-detail-header d-flex align-items-center justify-content-between">
        <span className="seat-detail-seatno font_family">{`Seat No : ${seat.seatNumber}`}</span>
        <span className="font_family seat-detail-floorname">
          Floor : {`${seat.seatNumber.slice(0, 1)}`}
        </span>
      </div>
      <div className="seat-detail-body font_family">
        {seat?.shifts?.map((shift, index) => (
          <div
            key={index}
            className={`shift-details ${
              seat?.shifts?.length - 1 !== index && "padding-bottom-12"
            }`}
          >
            <div className="d-flex align-items-center justify-content-between">
              <p className="shift-detail-shift-name mb-0">{`${shift.shiftType}`}</p>
              <p
                onClick={() => handleModalOpen(shift?.member)}
                className={`shift-detail-shift-status-common mb-0 ${
                  shift.bookingStatus === "Booked"
                    ? "shift-detail-shift-status-booked"
                    : shift.bookingStatus === "Available"
                    ? "shift-detail-shift-status-available"
                    : "shift-detail-shift-status"
                }`}
              >{`${shift.bookingStatus}`}</p>
            </div>
            <div className="d-flex align-items-center justify-content-between">
              <p className="shift-detail-user-name mb-0">
                <span className="fw-medium">Name : </span>
                {shift.userName ? `${shift.userName}` : "-"}
              </p>
              <p className="mb-0 shift-detail-start-end-date">
                {`${shift.startTime.toLowerCase()} - ${shift.endTime.toLowerCase()}`}
              </p>
            </div>
          </div>
        ))}
      </div>
    </Popover>
  );

  const seatStatus = checkBookingStatus(seat);
  const bookedArray = seatStatus.bookedArray;
  const seatStatusOverAll = seatStatus.status;

  const dynamicCss = dynamicCssForSeats(seatStatusOverAll, bookedArray);

  const checkBufferSeat = checkBufferBookingStatus(seat);

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Subscription ended recently.
    </Tooltip>
  );

  return (
    <>
      {modalData && (
        <ModalStudentDetailComponent
          student={modalData}
          onClose={() => setModalData(null)}
        />
      )}
      <OverlayTrigger
        trigger="click"
        placement="bottom"
        overlay={popover}
        rootClose
        onToggle={() => {
          if (visiblePopover === seat.seatNumber) setVisiblePopover(null);
          else setVisiblePopover(seat.seatNumber);
        }}
        show={visiblePopover == seat.seatNumber}  
      >
        <div className="position-relative">
          {checkBufferSeat && (
            <OverlayTrigger
              placement="right"
              delay={{ show: 250, hide: 400 }}
              overlay={renderTooltip}
            >
              <span className="material-symbols-outlined notification-buffer-days">
                notifications
              </span>
            </OverlayTrigger>
          )}
          <button
            className={`${dynamicCss} seat_container ${
              checkBufferSeat && "buffer-seat-design"
            }`}
            onClick={() => handlePopoverToggle(seat.seatNumber)}
          >
            {seat.seatNumber}
          </button>
        </div>
      </OverlayTrigger>
    </>
  );
};

export default PopoverSeatDetail;
