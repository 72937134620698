import { convertShiftsToOptions } from "./utilsFunction";

export function createShiftMap(shifts) {
  const shiftMap = {};

  if (!Array.isArray(shifts) || shifts?.length === 0) {
    return shiftMap;
  }

  for (let i = 0; i < shifts?.length; i++) {
    const {
      shiftName,
      startMinute,
      startHour,
      startPeriod,
      endMinute,
      endHour,
      endPeriod,
    } = shifts[i];

    shiftMap[shiftName] = {
      startMinute,
      startHour,
      startPeriod,
      endMinute,
      endHour,
      endPeriod,
    };
  }

  return shiftMap;
}

function shiftsMap(seats, members, subscriptionData) {
  const shiftMap = new Map();
  for (let i = 0; i < seats?.length; i++) {
    let arr = [];
    shiftMap.set(seats[i], arr);
  }

  const todayDate = new Date();
  subscriptionData?.map((subscription) => {
    if (subscription.active === true) {
      let arr = [];
      subscription?.shift?.map((subShift) => {
        if (subShift == "Morning Shift") {
          arr.push({
            shiftName: "MorningShift",
            startDate: new Date(subscription?.startDate),
            endDate: new Date(subscription?.endDate),
          });
        }
        if (subShift == "Evening Shift") {
          arr.push({
            shiftName: "EveningShift",
            startDate: new Date(subscription?.startDate),
            endDate: new Date(subscription?.endDate),
          });
        }
        if (subShift == "Night Shift") {
          arr.push({
            shiftName: "NightShift",
            startDate: new Date(subscription?.startDate),
            endDate: new Date(subscription?.endDate),
          });
        }
        if (subShift == "Full Day Shift" || subShift == "FulDayShift") {
          arr.push({
            shiftName: "FullDayShift",
            startDate: new Date(subscription?.startDate),
            endDate: new Date(subscription?.endDate),
          });
        }
      });
      if (shiftMap.get(subscription?.seatNo)?.length > 0) {
        let arr1 = shiftMap.get(subscription?.seatNo);
        shiftMap.set(subscription?.seatNo, [...arr1, ...arr]);
      } else shiftMap.set(subscription?.seatNo, arr);
    }
  });

  return shiftMap;
}

function addBufferDaysToEndDates(data, bufferDays) {
  const millisecondsPerDay = 24 * 60 * 60 * 1000;

  return data?.map((item) => {
    const updatedItem = { ...item };
    const endDate = new Date(item.endDate);
    updatedItem.endDate = new Date(
      endDate.getTime() + bufferDays * millisecondsPerDay
    ).toISOString();
    return updatedItem;
  });
}

// function to display seats based on input start date and end date
export function formSeats2(
  library_details,
  members,
  seats,
  startDate,
  endDate,
  desiredShift,
  oldSubscriptionData
) {
  const subscriptionData =  library_details.bufferTime ? addBufferDaysToEndDates(
    oldSubscriptionData,
    library_details.bufferTime
  ) : oldSubscriptionData;

  let shiftMap = shiftsMap(seats, members, subscriptionData);
  let availableSeats = [];
  shiftMap.forEach((value, key) => {
    let shiftArr = [];
    if (desiredShift === "Full Day Shift" || desiredShift === "FullDayShift")
      shiftArr = value;
    else
      shiftArr = value?.filter(
        (shift) =>
          shift.shiftName == desiredShift || shift.shiftName == "FullDayShift"
      );
    if (shiftArr.length == 0) availableSeats.push(key);
    else {
      let isSeatAvailable = Array(shiftArr.length).fill(0);
      shiftArr?.map((shift, i) => {
        if (startDate > shift?.startDate && startDate > shift?.endDate)
          isSeatAvailable[i] = 1; //this shift of selected seat is available
        else isSeatAvailable[i] = 0; //this shift of selected seat is not available

        if (endDate < shift?.startDate && endDate < shift?.endDate) {
          if (isSeatAvailable[i] == 0) isSeatAvailable[i] = 1; //available
        } else {
          if (isSeatAvailable[i] == 0) isSeatAvailable[i] = 0; // not available
        }
        // if (shift?.startDate > endDate || shift?.endDate < startDate) {
        //   availableSeats.push(key);
        // }
      });
      if (isSeatAvailable.includes(0) == false) availableSeats.push(key);
    }
  });
  return getSeatDetails(availableSeats);
}

export function generateSeatNames(floors) {
  let seatNames = [];

  floors?.forEach((floor) => {
    const floorName = floor.floorName;
    const totalSeats = floor.totalSeats;

    for (let i = 1; i <= totalSeats; i++) {
      seatNames.push(`${floorName}-${i}`);
    }
  });
  return seatNames;
}

export function getSeatDetails(seats) {
  return seats?.map((seat) => {
    const [floorLetter, seatNo] = seat;
    return {
      seatNo: seat,
      floor: `Floor-${floorLetter}`,
    };
  });
}

export function multipleShifts(
  library_details,
  members,
  seats,
  startDate,
  endDate,
  shifts,
  subscriptionData
) {
  if (shifts?.length == 0) return [];
  if (shifts?.length == 1)
    return formSeats2(
      library_details,
      members,
      seats,
      startDate,
      endDate,
      shifts[0]?.shift,
      subscriptionData
    );
  else {
    let commonSeats = [];
    for (let i in shifts) {
      let shiftSeats = formSeats2(
        library_details,
        members,
        seats,
        startDate,
        endDate,
        shifts[i]?.shift,
        subscriptionData
      );
      commonSeats.push(shiftSeats);
    }
    let commonSeatNos = commonSeats[0]?.map((obj) => obj.seatNo);
    for (let i = 1; i < commonSeats.length; i++) {
      let currentSeatNos = commonSeats[i]?.map((obj) => obj.seatNo);
      commonSeatNos = commonSeatNos?.filter((seatNo) =>
        currentSeatNos.includes(seatNo)
      );
    }
    let commonObjects = commonSeats[0]?.filter((obj) =>
      commonSeatNos.includes(obj.seatNo)
    );
    return commonObjects;
  }
}
