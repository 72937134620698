import {
  UPDATE_SUBSCRIPTIONS_STATUS,
  UPDATE_SUBSCRIPTIONS_SUCCESS,
  UPDATE_SUBSCRIPTIONS_ERROR,
} from "../actionType";
import { db, auth } from "../../firebase/firebase.auth.config";
import { doc, writeBatch } from "firebase/firestore";

// Action Creators
export const updateSubscriptionStatus = (status) => ({
  type: UPDATE_SUBSCRIPTIONS_STATUS,
  payload: status,
});

export const updateSubscriptionSuccess = (subscriptionIds) => ({
  type: UPDATE_SUBSCRIPTIONS_SUCCESS,
  payload: subscriptionIds,
});

export const updateSubscriptionError = (error) => ({
  type: UPDATE_SUBSCRIPTIONS_ERROR,
  payload: error,
});

// Actionable Functions
export const deactivateSubscriptions =
  (subscriptionIds, onSuccess, onError) => async (dispatch) => {
    try {
      const user = auth.currentUser;
      const user_uid = localStorage.getItem("user_uid");
      if (!user) {
        throw new Error("User not authenticated");
      }

      dispatch(updateSubscriptionStatus("loading"));

      // Create batch with writeBatch
      const batch = writeBatch(db);

      // Add update operations to the batch
      subscriptionIds.forEach((id) => {
        const subscriptionRef = doc(db, "subscription", id);
        batch.update(subscriptionRef, {
          active: false,
          deactivatedAt: new Date().toISOString(),
          deactivatedBy: user_uid,
          lastModified: new Date().toISOString(),
        });
      });

      // Execute the batch write
      await batch.commit();

      dispatch(updateSubscriptionSuccess(subscriptionIds));
      if (onSuccess) onSuccess();
      return true;
    } catch (error) {
      console.error("Error deactivating subscriptions:", error);
      dispatch(updateSubscriptionError(error.message));
      if (onError) onError(error);
      return false;
    }
  };
