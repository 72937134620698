import React from "react";
import jsPDF from "jspdf";
import { format } from "date-fns";
import toast from "react-hot-toast";
import { generateSecureShareLink } from "./ReceiptTempleteComponent";

const generatePDF = (student, subscription) => {
  const doc = new jsPDF({
    orientation: "portrait",
    unit: "mm",
    format: "a4",
  });

  // Colors
  const primaryColor = [0, 167, 111];
  const textColor = [44, 62, 80];

  // Page setup
  const pageWidth = 210;
  const margin = 25;
  const lineHeight = 10;

  // Card styling
  const cardWidth = 160;
  const cardX = (pageWidth - cardWidth) / 2;
  let currentY = 30;

  // White background
  doc.setFillColor(255, 255, 255);
  doc.roundedRect(cardX, currentY, cardWidth, 220, 3, 3, "F");

  // Header
  doc.setFillColor(...primaryColor);
  doc.rect(cardX, currentY, cardWidth, 15, "F");

  // Library name
  doc.setTextColor(255, 255, 255);
  doc.setFont("helvetica", "bold");
  doc.setFontSize(14);
  doc.text(
    `Librify x ${localStorage.getItem("library_name")}` || "Library",
    cardX + cardWidth / 2,
    currentY + 10,
    { align: "center" }
  );

  currentY += 25;

  // Student Details header
  doc.setTextColor(...primaryColor);
  doc.setFontSize(12);
  doc.text("Student Details", cardX + 10, currentY);

  // Blue line under section header
  doc.setDrawColor(...primaryColor);
  doc.setLineWidth(0.5);
  doc.line(cardX + 10, currentY + 2, cardX + cardWidth - 10, currentY + 2);

  currentY += 15;

  // Content
  doc.setTextColor(...textColor);
  doc.setFontSize(10);
  const content = [
    { label: "Name:", value: student.name },
    { label: "Student ID:", value: student.memberID },
    { label: "", value: "" }, // Spacing
    { label: "Subscription Details", value: "" },
    {
      label: "Start Date:",
      value: format(new Date(subscription.startDate), "dd/MM/yyyy"),
    },
    {
      label: "End Date:",
      value: format(new Date(subscription.endDate), "dd/MM/yyyy"),
    },
    { label: "Shift:", value: subscription.shift.join(", ") },
    { label: "Seat No:", value: subscription.seatNo },
    { label: "Fee:", value: `₹${subscription.fee}` },
  ];

  content.forEach((item) => {
    if (item.label === "Subscription Details") {
      doc.setTextColor(...primaryColor);
      doc.setFont("helvetica", "bold");
      doc.text(item.label, cardX + 10, currentY);
      doc.line(cardX + 10, currentY + 2, cardX + cardWidth - 10, currentY + 2);
      doc.setTextColor(...textColor);
      doc.setFont("helvetica", "normal");
    } else {
      doc.setFont("helvetica", "bold");
      doc.text(item.label, cardX + 10, currentY);
      doc.setFont("helvetica", "normal");
      doc.text(item.value, cardX + cardWidth - 60, currentY);
    }
    currentY += lineHeight;
  });

  // Footer
  doc.setFontSize(8);
  doc.setTextColor(150);
  doc.text(
    "Generated on: " + format(new Date(), "dd/MM/yyyy HH:mm"),
    cardX + cardWidth / 2,
    currentY + 10,
    { align: "center" }
  );

  return doc; // Return the doc object directly
};

const shortenURL = async (longURL) => {
  try {
    const response = await fetch(
      `https://tinyurl.com/api-create.php?url=${encodeURIComponent(longURL)}`
    );
    if (!response.ok) throw new Error("URL shortening failed");
    return await response.text();
  } catch (error) {
    console.error("URL shortening failed:", error);
    return longURL;
  }
};

const createWhatsAppMessage = (student, subscription, shareLink) => {
  const libraryName = localStorage.getItem("library_name") || "Library";

  return `Dear ${student.name},

Thank you for joining ${libraryName}.

Receipt Details:
Name: ${student.name}
Member ID: ${student.memberID}
Shift: ${subscription.shift.join(", ")}
Start Date: ${format(new Date(subscription.startDate), "dd/MM/yyyy")}
End Date: ${format(new Date(subscription.endDate), "dd/MM/yyyy")}
Seat No: ${subscription.seatNo}
Fee: ₹${subscription.fee}/-

View Receipt: ${shareLink}

Best Regards,
${libraryName} Team`;
};

const handleShare = async (student, subscription) => {
  const shareData = {
    libraryName: localStorage.getItem("library_name") || "Library",
    studentName: student.name,
    studentId: student.memberID,
    duration: `${format(
      new Date(subscription.startDate),
      "dd/MM/yyyy"
    )} to ${format(new Date(subscription.endDate), "dd/MM/yyyy")}`,
    startDate: format(new Date(subscription.startDate), "dd/MM/yyyy"),
    endDate: format(new Date(subscription.endDate), "dd/MM/yyyy"),
    shift: subscription.shift.join(", "),
    seatNo: subscription.seatNo,
    fee: subscription.fee,
  };

  const secureShareLink = generateSecureShareLink(shareData);
  const shortLink = await shortenURL(secureShareLink);

  const message = createWhatsAppMessage(student, subscription, shortLink);
  const waNumber = student.whatsapp?.replace(/\D/g, "") || "";

  if (!waNumber) {
    toast.error("WhatsApp number not found");
    return;
  }

  window.open(
    `https://wa.me/+91${waNumber}?text=${encodeURIComponent(message)}`,
    "_blank"
  );
  toast.success("Opening WhatsApp...");
};

const handleDownload = async (student, subscription) => {
  try {
    const doc = generatePDF(student, subscription);
    const fileName = `${student.name}_${format(
      new Date(),
      "dd-MM-yyyy"
    )}.pdf`.replace(/[<>:"/\\|?*]+/g, "_");

    // Save the PDF directly
    doc.save(fileName);

    toast.success("Receipt downloaded successfully!");
  } catch (error) {
    console.error("Error:", error);
    toast.error("Failed to download receipt");
  }
};

const ReceiptButtons = ({ student, subscription }) => (
  <div className="d-flex gap-2 mt-2">
    <button
      className="pri_radious border-none select_duration_btn d-flex align-items-center gap-2 px-3"
      onClick={() => handleShare(student, subscription)}
    >
      <span className="material-symbols-outlined">share</span>
      Share
    </button>
    <button
      className="pri_radious border-none select_duration_btn d-flex align-items-center gap-2 px-3"
      onClick={() => handleDownload(student, subscription)}
    >
      <span className="material-symbols-outlined">download</span>
      Download
    </button>
  </div>
);

export default ReceiptButtons;
