import React, { useState, useEffect } from "react";
import { Formik, Field, Form, FieldArray } from "formik";
import * as Yup from "yup";
import { Button, Form as BootstrapForm, Modal } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { useDispatch } from "react-redux";
import {
  fetchAllMemberDetailAction,
  updateMemberDetailAction,
} from "../../action/newMember/newMemberDetailAction";
import { fetchUserDetailAction } from "../../action/newUser/newUserDetailAction";
import {
  customStyles,
  getOptions,
  handleImageCompress,
  qualificationOptions,
  transformShiftsOptions,
} from "../../util/utils";
import {
  multipleShifts,
  generateSeatNames,
} from "../../util/checkSeatAvaFunction";
import { fetchAllSubscriptionAction } from "../../action/newSubscription/newSubscriptionDetailAction";
import { deactivateSubscriptions } from "../../action/subscription/subscriptionRemoveAction";
import { uploadImage } from "../../firebase/firebase.auth.config";

const ModalEditStudentDetailComponent = ({
  student,
  onClose,
  dropdownData,
  newUserDetail,
  newSubsDetail,
}) => {
  const dispatch = useDispatch();
  const userUid = localStorage.getItem("user_uid");

  // States
  const [loader, setLoader] = useState(false);
  const [seatOptionsMap, setSeatOptionsMap] = useState({});
  const [localSubsDetail, setLocalSubsDetail] = useState(
    newSubsDetail?.data || []
  );
  const [selectedIds, setSelectedIds] = useState([]);
  const [imagePreview, setImagePreview] = useState(
    student.profileImage || null
  );

  // Constants
  const shifts = transformShiftsOptions(newUserDetail?.data?.library?.shifts);
  let formFields = newUserDetail?.data?.formFields;
  const goalOptions = getOptions("Goals", dropdownData);
  const animatedComponents = makeAnimated();
  const seats = generateSeatNames(newUserDetail?.data?.library?.floors);

  // Initial values
  const initialValues = {
    name: student.name || "",
    mobile: student.mobile || "",
    whatsapp: student.whatsapp || "",
    email: student.email || "",
    gender: student.gender || "",
    aadhar_number: student.aadhar_number || "",
    dateOfBirth: student.dateOfBirth || null,
    fatherName: student.fatherName || "",
    qualification: student.qualification || "",
    emergencyContact: student.emergencyContact || "",
    paymentMethod: student.paymentMethod || "",
    remarks: student.remarks || "",
    // profileImage: student.profileImage || null,
    // aadharCardDoc: student.aadharCardDoc || null,
    subscription: student.subscription || [
      {
        seatNo: "",
        shift: [],
        startDate: null,
        endDate: null,
        fee: "",
        member: "",
        id: "",
        library: "",
      },
    ],
    address: student.address || "",
    goal: student.goal || "",
    memberID: student.memberID || "",
    approved: true,
    id: student.id,
  };

  // Validation schema
  const validationSchema = Yup.object({
    name:
      formFields?.name?.required && Yup.string().required("Name is required"),
    mobile:
      formFields?.contactNum?.required &&
      Yup.string()
        .matches(/^\d+$/, "Mobile number must be numeric")
        .min(10, "Mobile number must be at least 10 digits")
        .max(15, "Mobile number must be at most 15 digits")
        .required("Mobile number is required"),
    whatsapp:
      formFields?.whatsappNum?.required &&
      Yup.string()
        .matches(/^\d+$/, "WhatsApp number must be numeric")
        .min(10, "WhatsApp number must be at least 10 digits")
        .max(15, "WhatsApp number must be at most 15 digits")
        .notRequired(),
    email:
      formFields?.email?.required &&
      Yup.string().email("Invalid email address").required("Email is required"),
    gender:
      formFields?.gender?.required &&
      Yup.string().required("Gender is required"),
    address:
      formFields?.address?.required &&
      Yup.string().required("Address is required"),
    goal: formFields?.goal?.required && Yup.string(),
    memberID: Yup.string(),
    subscription: Yup.array().of(
      Yup.object().shape({
        seatNo: Yup.string().required("Seat number is required"),
        shift: Yup.array()
          .of(Yup.string().required("Shift is required"))
          .required("At least one shift is required")
          .min(1, "At least one shift is required"),
        startDate: Yup.date().required("Start date is required"),
        endDate: Yup.date()
          .required("End date is required")
          .min(Yup.ref("startDate"), "End date must be after start date"),
        fee: Yup.number()
          .required("Fee is required")
          .min(0, "Fee must be a positive number"),
      })
    ),
    dateOfBirth:
      formFields?.dateOfBirth?.required &&
      Yup.date()
        .required("Date of birth is required")
        .max(new Date(), "Date of birth cannot be in the future"),
    fatherName:
      formFields?.fatherName?.required &&
      Yup.string().required("Father's name is required"),
    qualification:
      formFields?.qualification?.required &&
      Yup.string().required("Qualification is required"),
    emergencyContact:
      formFields?.emergencyContact?.required &&
      Yup.string()
        .matches(/^\d+$/, "Emergency contact must be numeric")
        .length(10, "Emergency contact must be exactly 10 digits")
        .required("Emergency contact is required"),
    profileImage:
      formFields?.profielPic?.required &&
      Yup.mixed().required("Profile image is required"),
    aadharCardDoc:
      formFields?.aadharImage?.required &&
      Yup.mixed().required("Aadhar card PDF is required"),
    paymentMethod:
      formFields?.paymentMethod?.required &&
      Yup.string().required("Payment Mode is required"),
    remarks:
      formFields?.remarks?.required &&
      Yup.string().required("Remarks are required"),
  });

  const formatDateToISO = (date) => {
    return date ? new Date(date).toISOString() : "";
  };

  const handleFormSubmit = async (values) => {
    setLoader(true);

    // Combined success callback for both operations
    const handleSuccess = () => {
      dispatch(fetchUserDetailAction());
      dispatch(fetchAllMemberDetailAction());
      dispatch(fetchAllSubscriptionAction());
      onClose();
      setLoader(false);
      setSelectedIds([]);
    };

    // Combined error callback
    const handleError = (error) => {
      console.error("Operation failed:", error);
      setLoader(false);
    };

    try {
      let profileImageUrl = student?.profileImage || "";
      let aadharDocUrl = student?.aadharCardDoc || "";
      if (values?.profileImage != null || values?.profileImage != undefined) {
        profileImageUrl = await uploadImage(values.profileImage).catch(
          (err) => {
            console.error("Profile image upload failed:", err);
            return null; // Or a placeholder URL
          }
        );
      }

      if (values?.aadharCardDoc != null || values?.aadharCardDoc != undefined) {
        aadharDocUrl = await uploadImage(
          values.aadharCardDoc,
          "aadhar_docs"
        ).catch((err) => {
          console.error("Aadhar document upload failed:", err);
          return null; // Or a placeholder URL
        });
      }
      const formattedValues = {
        ...values,
        subscription: values.subscription?.map((sub) => ({
          ...sub,
          startDate: formatDateToISO(sub.startDate),
          endDate: formatDateToISO(sub.endDate),
        })),
        dateOfBirth: values.dateOfBirth
          ? formatDateToISO(values.dateOfBirth)
          : null,
        profileImage: profileImageUrl,
        aadharCardDoc: aadharDocUrl,
      };
      // console.log(formattedValues);
      // Create a promise for the member update
      const memberUpdatePromise = new Promise((resolve, reject) => {
        dispatch(
          updateMemberDetailAction(
            formattedValues,
            userUid,
            () => resolve(true),
            (error) => reject(error)
          )
        );
      });

      // // Create a promise for subscription deactivation if needed
      let subscriptionDeactivatePromise = Promise.resolve(true);
      if (selectedIds.length > 0) {
        subscriptionDeactivatePromise = new Promise((resolve, reject) => {
          dispatch(
            deactivateSubscriptions(
              selectedIds,
              () => resolve(true),
              (error) => reject(error)
            )
          );
        });
      }

      // Wait for both operations to complete
      await Promise.all([memberUpdatePromise, subscriptionDeactivatePromise]);

      // If we get here, both operations succeeded
      handleSuccess();
    } catch (error) {
      // If either operation failed, we'll catch it here
      handleError(error);
      setLoader(false);
    }
  };
  // Function to get available seats
  const getAvailableSeats = (subscription, index, allSubscriptions) => {
    if (
      !subscription?.startDate ||
      !subscription?.endDate ||
      !subscription?.shift?.length
    ) {
      return [];
    }
    const startDate = new Date(subscription.startDate);
    const endDate = new Date(subscription.endDate);

    const formattedShifts = subscription.shift?.map((shiftValue) => {
      const shiftName =
        typeof shiftValue === "string" ? shiftValue : shiftValue.shift;
      return (
        shifts.find((s) => s.value.startsWith(shiftName)) || {
          shift: shiftName,
          value: shiftName,
        }
      );
    });

    const otherSubscriptions = localSubsDetail?.filter(
      (sub) => sub.id !== subscription.id
    );

    let availableSeats = multipleShifts(
      newUserDetail?.data?.library,
      newUserDetail?.data?.members,
      seats,
      startDate,
      endDate,
      formattedShifts,
      otherSubscriptions
    );

    // Include current seat if exists
    if (
      subscription.seatNo &&
      !availableSeats?.some((seat) => seat.seatNo === subscription.seatNo)
    ) {
      availableSeats = [
        ...(availableSeats || []),
        { seatNo: subscription.seatNo },
      ];
    }

    return (
      availableSeats?.map(({ seatNo }) => ({
        label: seatNo,
        value: seatNo,
      })) || []
    );
  };

  // Effect to update local subscription state
  useEffect(() => {
    setLocalSubsDetail(newSubsDetail?.data || []);
  }, [newSubsDetail?.data]);

  useEffect(() => {
    initialValues.subscription.forEach((sub, index) => {
      if (sub.startDate && sub.endDate && sub.shift?.length) {
        updateSubscriptionSeatOptions(index, initialValues);
      }
    });
  }, []); // Run once on component mount

  const handleSubscriptionChange = (values, index) => {
    const updatedSubs = values.subscription?.map((sub) => ({
      ...sub,
      id: sub.id || `temp-${Math.random().toString(36).substr(2, 9)}`,
      library: userUid,
      member: student.id,
      shift: sub.shift?.map((shiftObj) => shiftObj.shift || shiftObj),
    }));

    setLocalSubsDetail((prev) => {
      const filtered = prev?.filter((sub) => sub.member !== student.id);
      return [...filtered, ...updatedSubs];
    });

    updateSubscriptionSeatOptions(index, {
      ...values,
      subscription: updatedSubs,
    });
  };

  // Function to update seat options
  const updateSubscriptionSeatOptions = (subscriptionIndex, values) => {
    const subscription = values.subscription[subscriptionIndex];
    const availableSeats = getAvailableSeats(
      subscription,
      subscriptionIndex,
      values.subscription
    );

    setSeatOptionsMap((prev) => ({
      ...prev,
      [subscriptionIndex]: availableSeats,
    }));
  };

  const handleContinue = (push, prevSub) => {
    if (!prevSub.endDate) return;
    const newStartDate = new Date(prevSub.endDate);
    newStartDate.setDate(newStartDate.getDate() + 1);

    const newEndDate = new Date(newStartDate);
    newEndDate.setMonth(newEndDate.getMonth() + 1);

    push({
      seatNo: "",
      shift: prevSub.shift,
      startDate: newStartDate,
      endDate: newEndDate,
      fee: prevSub.fee,
    });
  };

  const NumericField = ({ field, form, ...props }) => (
    <input
      {...field}
      {...props}
      onChange={(e) => {
        const value = e.target.value?.replace(/[^0-9]/g, "");
        form.setFieldValue(field.name, value);
      }}
    />
  );

  return (
    <Modal show={true} onHide={onClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Edit Student</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleFormSubmit}
        >
          {({ setFieldValue, errors, touched, values }) => (
            <Form className="row gap-3">
              {/* Basic Details Form Fields */}
              <BootstrapForm.Group className="col-12">
                <p className="fw-semibold text_black col-12 px-0 mb-2 mt-0">
                  Basic Details
                </p>
                <BootstrapForm.Label className="label_text col-12 px-0">
                  Name
                </BootstrapForm.Label>
                <Field
                  name="name"
                  type="text"
                  className="formBodyControlModal form-control input_feild"
                  placeholder="Enter your name"
                />
                {errors.name && touched.name && (
                  <div className="text-danger">{errors.name}</div>
                )}
              </BootstrapForm.Group>

              {/* Mobile, WhatsApp, Email fields */}
              {formFields?.contactNum?.display && (
                <BootstrapForm.Group className="col-12">
                  <BootstrapForm.Label className="label_text">
                    Mobile
                  </BootstrapForm.Label>
                  <Field
                    name="mobile"
                    type="text"
                    className="formBodyControlModal form-control input_feild"
                    placeholder="Enter your mobile number"
                  />
                  {errors.mobile && touched.mobile && (
                    <div className="text-danger">{errors.mobile}</div>
                  )}
                </BootstrapForm.Group>
              )}

              {formFields?.whatsappNum?.display && (
                <BootstrapForm.Group className="col-12">
                  <BootstrapForm.Label className="label_text">
                    WhatsApp
                  </BootstrapForm.Label>
                  <Field
                    name="whatsapp"
                    type="text"
                    className="formBodyControlModal form-control input_feild"
                    placeholder="Enter your WhatsApp number"
                  />
                  {errors.whatsapp && touched.whatsapp && (
                    <div className="text-danger">{errors.whatsapp}</div>
                  )}
                </BootstrapForm.Group>
              )}

              {/* Date of Birth */}
              {formFields?.dateOfBirth?.display && (
                <BootstrapForm.Group>
                  <BootstrapForm.Label className="label_text">
                    Date of Birth{" "}
                    {!formFields?.dateOfBirth?.required && (
                      <span className="pri_text"> (Optional) </span>
                    )}
                  </BootstrapForm.Label>
                  <DatePicker
                    selected={values.dateOfBirth}
                    onChange={(date) => setFieldValue("dateOfBirth", date)}
                    dateFormat="dd/MM/yyyy"
                    maxDate={new Date()}
                    showYearDropdown
                    scrollableYearDropdown
                    yearDropdownItemNumber={100}
                    placeholderText="Select Date of Birth"
                    className={`form-control input_feild ${
                      errors.dateOfBirth && touched.dateOfBirth
                        ? "is-invalid"
                        : ""
                    }`}
                  />
                  {errors.dateOfBirth && touched.dateOfBirth && (
                    <div className="text-danger">{errors.dateOfBirth}</div>
                  )}
                </BootstrapForm.Group>
              )}

              {/* Father's Name */}
              {formFields?.fatherName?.display && (
                <BootstrapForm.Group className="">
                  <BootstrapForm.Label className="label_text">
                    Father's Name{" "}
                    {!formFields?.fatherName?.required && (
                      <span className="pri_text"> (Optional) </span>
                    )}
                  </BootstrapForm.Label>
                  <Field
                    name="fatherName"
                    type="text"
                    className="formBodyControlModal form-control input_feild"
                    placeholder="Enter father's name"
                  />
                  {errors.fatherName && touched.fatherName && (
                    <div className="text-danger">{errors.fatherName}</div>
                  )}
                </BootstrapForm.Group>
              )}

              {/* Qualification */}
              {formFields?.qualification?.display && (
                <BootstrapForm.Group className="">
                  <BootstrapForm.Label className="label_text">
                    Qualification
                    {!formFields?.qualification?.required && (
                      <span className="pri_text"> (Optional) </span>
                    )}
                  </BootstrapForm.Label>
                  <Select
                    options={qualificationOptions}
                    className="formBodyControlModal"
                    components={animatedComponents}
                    placeholder="Select Qualification"
                    onChange={(option) =>
                      setFieldValue("qualification", option.value)
                    }
                    styles={customStyles}
                  />
                  {errors.qualification && touched.qualification && (
                    <div className="text-danger">{errors.qualification}</div>
                  )}
                </BootstrapForm.Group>
              )}

              {/* Emergency Contact */}
              {formFields?.emergencyContact?.display && (
                <BootstrapForm.Group className="">
                  <BootstrapForm.Label className="label_text">
                    Emergency Contact
                    {!formFields?.emergencyContact?.required && (
                      <span className="pri_text"> (Optional) </span>
                    )}
                  </BootstrapForm.Label>
                  <Field
                    name="emergencyContact"
                    component={NumericField}
                    className="formBodyControlModal form-control input_feild"
                    placeholder="Enter emergency contact number"
                    maxLength="10"
                  />
                  {errors.emergencyContact && touched.emergencyContact && (
                    <div className="text-danger">{errors.emergencyContact}</div>
                  )}
                </BootstrapForm.Group>
              )}

              {/* Profile Image Upload */}
              {formFields?.profielPic?.display && (
                <BootstrapForm.Group className="">
                  <BootstrapForm.Label className="label_text">
                    Profile Image{" "}
                    {!formFields?.profielPic?.required && (
                      <span className="pri_text"> (Optional) </span>
                    )}
                  </BootstrapForm.Label>
                  <input
                    type="file"
                    accept="image/*"
                    onChange={async (event) => {
                      const file = event.currentTarget.files[0];
                      if (file) {
                        const compressedFile = await handleImageCompress(
                          file,
                          setImagePreview
                        );
                        setFieldValue("profileImage", compressedFile);
                      }
                    }}
                    className="form-control"
                  />
                  {imagePreview && (
                    <img
                      src={imagePreview}
                      alt="Preview"
                      className="mt-2"
                      style={{ maxWidth: "200px" }}
                    />
                  )}
                  {errors.profileImage && touched.profileImage && (
                    <div className="text-danger">{errors.profileImage}</div>
                  )}
                </BootstrapForm.Group>
              )}

              {formFields?.aadharNumber?.display && (
                <BootstrapForm.Group>
                  <BootstrapForm.Label className="label_text">
                    Aadhar number
                    {!formFields?.aadharNumber?.required && (
                      <span className="pri_text"> (Optional) </span>
                    )}
                  </BootstrapForm.Label>
                  <Field
                    name="aadhar_number"
                    className="formBodyControlModal form-control input_feild"
                    placeholder="Enter aadhar"
                    maxLength="14"
                    onChange={(e) => {
                      const rvalue = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
                      const formattedValue =
                        rvalue
                          .match(/.{1,4}/g) // Split into groups of 1 to 4 digits
                          ?.join(" ") // Join groups with spaces
                          .trim() || ""; // Trim any trailing spaces
                      setFieldValue("aadhar_number", formattedValue);
                      e.target.value = formattedValue; // Update the input value
                    }}
                  />
                </BootstrapForm.Group>
              )}

              {formFields?.aadharImage?.display && (
                <BootstrapForm.Group className="">
                  <BootstrapForm.Label className="label_text">
                    Aadhar Card PDF{" "}
                    {!formFields?.aadharImage?.required && (
                      <span className="pri_text"> (Optional) </span>
                    )}
                  </BootstrapForm.Label>
                  <input
                    type="file"
                    accept="image/*, application/pdf"
                    onChange={async (event) => {
                      const file = event.currentTarget.files[0];
                      if (file) {
                        const compressedFile = await handleImageCompress(
                          file,
                          setImagePreview
                        );
                        setFieldValue("aadharCardDoc", compressedFile);
                      }
                    }}
                    className="form-control"
                  />
                  {errors.aadharCardDoc && touched.aadharCardDoc && (
                    <div className="text-danger">{errors.aadharCardDoc}</div>
                  )}
                </BootstrapForm.Group>
              )}

              {formFields?.email?.display && (
                <BootstrapForm.Group className="col-12">
                  <BootstrapForm.Label className="label_text">
                    Email{" "}
                    {!formFields?.email?.required && (
                      <span className="pri_text"> (Optional) </span>
                    )}
                  </BootstrapForm.Label>
                  <Field
                    name="email"
                    type="email"
                    className="formBodyControlModal form-control input_feild"
                    placeholder="Enter your email"
                  />
                  {errors.email && touched.email && (
                    <div className="text-danger">{errors.email}</div>
                  )}
                </BootstrapForm.Group>
              )}

              {/* Gender */}
              {formFields?.gender?.display && (
                <BootstrapForm.Group className="col-12">
                  <BootstrapForm.Label className="label_text">
                    Gender
                  </BootstrapForm.Label>
                  <div className="d-flex gap-4">
                    <label className="mb-0 d-flex gap-2">
                      <Field type="radio" name="gender" value="Male" /> Male
                    </label>
                    <label className="mb-0 d-flex gap-2">
                      <Field type="radio" name="gender" value="Female" /> Female
                    </label>
                  </div>
                  {errors.gender && touched.gender && (
                    <div className="text-danger">{errors.gender}</div>
                  )}
                </BootstrapForm.Group>
              )}

              {formFields?.address?.display && (
                <BootstrapForm.Group className="col-12">
                  <BootstrapForm.Label className="label_text">
                    Address
                    {!formFields?.address?.required && (
                      <span className="pri_text"> (Optional) </span>
                    )}
                  </BootstrapForm.Label>
                  <Field
                    name="address"
                    as="textarea"
                    className="formBodyControlModal form-control input_feild"
                    placeholder="Enter your address"
                  />
                  {errors.address && touched.address && (
                    <div className="text-danger">{errors.address}</div>
                  )}
                </BootstrapForm.Group>
              )}

              {formFields?.paymentMethod?.display && (
                <BootstrapForm.Group className="col-12">
                  <BootstrapForm.Label className="label_text">
                    Payment Mode
                    {!formFields?.paymentMethod?.required && (
                      <span className="pri_text"> (Optional) </span>
                    )}
                  </BootstrapForm.Label>
                  <Field
                    name="paymentMethod"
                    type="text"
                    className="formBodyControlModal form-control input_feild"
                    placeholder="Payment Mode"
                  />
                  {errors?.paymentMethod && touched?.paymentMethod && (
                    <div className="text-danger">{errors.paymentMethod}</div>
                  )}
                </BootstrapForm.Group>
              )}
              {formFields?.remarks?.display && (
                <BootstrapForm.Group className="col-12">
                  <BootstrapForm.Label className="label_text">
                    Any Remarks
                    {!formFields?.remarks?.required && (
                      <span className="pri_text"> (Optional) </span>
                    )}
                  </BootstrapForm.Label>
                  <Field
                    name="remarks"
                    type="text"
                    className="formBodyControlModal form-control input_feild"
                    placeholder="Remarks"
                  />
                  {errors?.remarks && touched?.remarks && (
                    <div className="text-danger">{errors?.remarks}</div>
                  )}
                </BootstrapForm.Group>
              )}

              {/* Goal */}
              {formFields?.goal?.display && (
                <BootstrapForm.Group className="col-12">
                  <BootstrapForm.Label className="label_text">
                    Goal
                    {!formFields?.goal?.required && (
                      <span className="pri_text"> (Optional) </span>
                    )}
                  </BootstrapForm.Label>
                  <Select
                    options={goalOptions}
                    className="formBodyControlModal"
                    components={animatedComponents}
                    placeholder="Select Goal"
                    onChange={(option) => setFieldValue("goal", option.value)}
                    styles={customStyles}
                    value={goalOptions.find(
                      (option) => option.value === values.goal
                    )}
                  />
                </BootstrapForm.Group>
              )}

              {/* Subscription Details */}
              <BootstrapForm.Group className="col-12">
                <BootstrapForm.Label className="fw-semibold mt-3 text_black mb-2">
                  Subscription Details
                </BootstrapForm.Label>
                <FieldArray name="subscription">
                  {({ insert, remove, push }) => (
                    <div>
                      {values.subscription?.map((sub, index) => (
                        <div
                          key={index}
                          className="mb-3 border pri_radious py-3"
                        >
                          <div className="d-flex align-items-center justify-content-between px-3">
                            <p className="fw-semibold mb-2">
                              Subscription - {index + 1}
                            </p>
                            {values.subscription.length > 1 && (
                              <button
                                className="border-0 bg-transparent d-flex align-items-center gap-1"
                                onClick={() => {
                                  setSelectedIds([...selectedIds, sub.id]);
                                  remove(index);
                                  handleSubscriptionChange(
                                    {
                                      ...values,
                                      subscription: values.subscription?.filter(
                                        (_, i) => i !== index
                                      ),
                                    },
                                    index
                                  );
                                }}
                                disabled={values.subscription?.length === 1}
                              >
                                <span className="material-symbols-outlined icon_dimention">
                                  delete
                                </span>
                                <p className="table_row_cell text-nowrap mb-0">
                                  delete
                                </p>
                              </button>
                            )}
                          </div>

                          {/* Start Date */}
                          <BootstrapForm.Group className="col-12">
                            <BootstrapForm.Label className="label_text w-100">
                              Start Date
                            </BootstrapForm.Label>
                            <DatePicker
                              selected={sub.startDate}
                              onChange={(date) => {
                                setFieldValue(
                                  `subscription.${index}.startDate`,
                                  date
                                );
                                setFieldValue(
                                  `subscription.${index}.seatNo`,
                                  ""
                                );
                                handleSubscriptionChange(
                                  {
                                    ...values,
                                    subscription: values.subscription?.map(
                                      (s, i) =>
                                        i === index
                                          ? {
                                              ...s,
                                              startDate: date,
                                              seatNo: "",
                                            }
                                          : s
                                    ),
                                  },
                                  index
                                );
                              }}
                              selectsStart
                              startDate={sub.startDate}
                              endDate={sub.endDate}
                              dateFormat="dd/MM/yyyy"
                              placeholderText="Start Date"
                              minDate={
                                index > 0
                                  ? new Date(
                                      values.subscription[index - 1].endDate
                                    ).setDate(
                                      new Date(
                                        values.subscription[index - 1].endDate
                                      ).getDate() + 1
                                    )
                                  : null
                              }
                              className={`form-control input_feild ${
                                errors.subscription?.[index]?.startDate &&
                                touched.subscription?.[index]?.startDate
                                  ? "is-invalid"
                                  : ""
                              }`}
                            />
                            {errors.subscription?.[index]?.startDate &&
                              touched.subscription?.[index]?.startDate && (
                                <div className="text-danger">
                                  {errors.subscription[index].startDate}
                                </div>
                              )}
                          </BootstrapForm.Group>

                          {/* End Date */}
                          <BootstrapForm.Group className="col-12">
                            <BootstrapForm.Label className="label_text w-100">
                              End Date
                            </BootstrapForm.Label>
                            <DatePicker
                              selected={sub.endDate}
                              onChange={(date) => {
                                setFieldValue(
                                  `subscription.${index}.endDate`,
                                  date
                                );
                                setFieldValue(
                                  `subscription.${index}.seatNo`,
                                  ""
                                );
                                handleSubscriptionChange(
                                  {
                                    ...values,
                                    subscription: values.subscription?.map(
                                      (s, i) =>
                                        i === index
                                          ? { ...s, endDate: date, seatNo: "" }
                                          : s
                                    ),
                                  },
                                  index
                                );
                              }}
                              selectsEnd
                              startDate={sub.startDate}
                              endDate={sub.endDate}
                              minDate={sub.startDate}
                              dateFormat="dd/MM/yyyy"
                              placeholderText="End Date"
                              className={`form-control input_feild ${
                                errors.subscription?.[index]?.endDate &&
                                touched.subscription?.[index]?.endDate
                                  ? "is-invalid"
                                  : ""
                              }`}
                            />
                            {errors.subscription?.[index]?.endDate &&
                              touched.subscription?.[index]?.endDate && (
                                <div className="text-danger">
                                  {errors.subscription[index].endDate}
                                </div>
                              )}
                          </BootstrapForm.Group>

                          {/* Shift Selection */}
                          <BootstrapForm.Group className="col-12">
                            <BootstrapForm.Label className="label_text">
                              Shift
                            </BootstrapForm.Label>
                            <Select
                              isMulti
                              options={shifts}
                              value={sub.shift
                                ?.map((shiftValue) => {
                                  // Handle both string and array formats
                                  const shiftName =
                                    typeof shiftValue === "string"
                                      ? shiftValue
                                      : shiftValue.shift;
                                  return (
                                    shifts.find((s) =>
                                      s.value.startsWith(shiftName)
                                    ) || null
                                  );
                                })
                                ?.filter(Boolean)}
                              onChange={(selectedOptions) => {
                                // Handle "Full Day Shift" logic
                                // if (
                                //   selectedOptions.some((option) =>
                                //     option.value.includes("Full Day Shift")
                                //   )
                                // ) {
                                //   selectedOptions = selectedOptions?.filter(
                                //     (option) =>
                                //       option.value.includes("Full Day Shift")
                                //   );
                                // }
                                if (
                                  selectedOptions[0]?.shift === "FullDayShift"
                                )
                                  selectedOptions?.splice(
                                    1,
                                    selectedOptions?.length
                                  );
                                if (
                                  selectedOptions[selectedOptions.length - 1]
                                    ?.shift === "FullDayShift"
                                )
                                  selectedOptions?.splice(
                                    0,
                                    selectedOptions?.length - 1
                                  );

                                // Store simplified shift names
                                const processedShifts = selectedOptions?.map(
                                  (option) => option.value.split("(")[0].trim()
                                );

                                setFieldValue(
                                  `subscription.${index}.shift`,
                                  processedShifts
                                );
                                setFieldValue(
                                  `subscription.${index}.seatNo`,
                                  ""
                                );

                                handleSubscriptionChange(
                                  {
                                    ...values,
                                    subscription: values.subscription?.map(
                                      (s, i) =>
                                        i === index
                                          ? {
                                              ...s,
                                              shift: processedShifts,
                                              seatNo: "",
                                            }
                                          : s
                                    ),
                                  },
                                  index
                                );
                              }}
                              placeholder="Select shifts"
                              components={animatedComponents}
                              styles={customStyles}
                            />

                            {errors.subscription?.[index]?.shift &&
                              touched.subscription?.[index]?.shift && (
                                <div className="text-danger">
                                  {errors.subscription[index].shift}
                                </div>
                              )}
                          </BootstrapForm.Group>

                          {/* Seat Selection */}
                          <BootstrapForm.Group className="col-12">
                            <BootstrapForm.Label className="label_text">
                              Seat No
                            </BootstrapForm.Label>
                            <Select
                              options={seatOptionsMap[index] || []}
                              value={
                                sub.seatNo
                                  ? { label: sub.seatNo, value: sub.seatNo }
                                  : null
                              }
                              onChange={(option) => {
                                setFieldValue(
                                  `subscription.${index}.seatNo`,
                                  option?.value || ""
                                );
                              }}
                              isDisabled={
                                !sub.startDate ||
                                !sub.endDate ||
                                !sub.shift?.length
                              }
                              placeholder={
                                !sub.startDate ||
                                !sub.endDate ||
                                !sub.shift?.length
                                  ? "Select dates & shifts first"
                                  : "Select seat"
                              }
                              components={animatedComponents}
                              styles={customStyles}
                            />
                            {errors.subscription?.[index]?.seatNo &&
                              touched.subscription?.[index]?.seatNo && (
                                <div className="text-danger">
                                  {errors.subscription[index].seatNo}
                                </div>
                              )}
                          </BootstrapForm.Group>

                          {/* Fee Input */}
                          <BootstrapForm.Group className="col-12">
                            <BootstrapForm.Label className="label_text">
                              Fee
                            </BootstrapForm.Label>
                            <Field
                              name={`subscription.${index}.fee`}
                              type="number"
                              className={`form-control input_feild ${
                                errors.subscription?.[index]?.fee &&
                                touched.subscription?.[index]?.fee
                                  ? "is-invalid"
                                  : ""
                              }`}
                              placeholder="Enter fee amount"
                            />
                            {errors.subscription?.[index]?.fee &&
                              touched.subscription?.[index]?.fee && (
                                <div className="text-danger">
                                  {errors.subscription[index].fee}
                                </div>
                              )}
                          </BootstrapForm.Group>
                        </div>
                      ))}

                      {/* Subscription Actions */}
                      <div className="d-flex justify-content-between align-items-center">
                        <button
                          type="button"
                          className="border-0 bg-transparent d-flex align-items-center gap-1"
                          onClick={() =>
                            push({
                              seatNo: "",
                              shift: [],
                              startDate: null,
                              endDate: null,
                              fee: "",
                            })
                          }
                        >
                          <span className="material-symbols-outlined icon_dimention">
                            add
                          </span>
                          <p className="table_row_cell text-nowrap mb-0">
                            Add subscription
                          </p>
                        </button>
                      </div>
                    </div>
                  )}
                </FieldArray>
              </BootstrapForm.Group>

              {/* Form Actions */}
              <div className="col-12 text-center border-top pt-2">
                <div className="col-12 d-flex border-0 gap-2 outline-0 justify-content-between my-2">
                  <Button
                    className="button_design w-50"
                    variant="outline"
                    onClick={onClose}
                    disabled={loader}
                  >
                    Cancel
                  </Button>
                  <Button
                    className="pri_bg button_design w-50"
                    variant="secondary"
                    type="submit"
                    disabled={loader}
                  >
                    {loader ? (
                      <div
                        className="spinner-border spinner-border-btn"
                        role="status"
                      >
                        <span className="sr-only">Loading...</span>
                      </div>
                    ) : (
                      "Update Student"
                    )}
                  </Button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

export default ModalEditStudentDetailComponent;
