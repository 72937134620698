export function isActive(sub) {
  const currentDate = new Date();
  let currentDateMinusOne = new Date();
  currentDateMinusOne.setDate(currentDateMinusOne.getDate() - 1);
  return (
    new Date(sub.endDate) >= currentDateMinusOne &&
    new Date(sub.startDate) <= currentDate
  );
}

export const agGridFilterData = (
  students,
  searchTerm,
  selectedShift,
  activeStatus
) => {
  const today = new Date();
  let todayMinusOne = new Date();
  todayMinusOne.setDate(todayMinusOne.getDate() - 1);
  const upcomingExpiryDays = 7;
  const filtered = students?.filter((student) => {
    // Filter by search term
    const matchesSearch =
      student.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      student.memberID.toLowerCase().includes(searchTerm.toLowerCase()) ||
      student.mobile.toLowerCase().includes(searchTerm.toLowerCase());

    // Filter by shift
    const matchesShift =
      selectedShift === "All" ||
      student.subscription?.some((sub) => {
        for (let s of sub.shift) {
          if (s === selectedShift) {
            return true;
          }
        }
      });

    // Filter by active/inactive status

    // const latestSubscription = student.subscription?.reduce(
    //   (latest, sub) =>
    //     new Date(sub.endDate) > new Date(latest.endDate) ? sub : latest,
    //   student.subscription[0]
    // );
    let latestSubscription = null;
    for (const sub of student?.subscription) {
      if (isActive(sub)) {
        latestSubscription = sub;
        break;
      }
    }
    const isActiveSub = latestSubscription
      ? today >= new Date(latestSubscription.startDate) &&
        todayMinusOne <= new Date(latestSubscription.endDate)
      : false;

    const statusMatches =
      activeStatus === "All" ||
      (activeStatus === "Active" && isActiveSub) ||
      (activeStatus === "Inactive" && !isActiveSub);

    latestSubscription =
      student?.subscription[student.subscription?.length - 1];
    // Filter by upcoming expiry
    const upcomingExpiry = latestSubscription
      ? (new Date(latestSubscription.endDate) - today) / (1000 * 60 * 60 * 24) <
          upcomingExpiryDays && today <= new Date(latestSubscription.endDate)
      : false;

    // Return if all conditions are met
    return matchesSearch && matchesShift && statusMatches;
  });

  return filtered;
};
