import {
  ADD_BRANCH_REQUEST,
  ADD_BRANCH_SUCCESS,
  ADD_BRANCH_FAILURE,
} from "../../action/actionType";

const initialState = {
  loading: false,
  branches: [],
  error: null,
};

export const branchReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_BRANCH_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ADD_BRANCH_SUCCESS:
      return {
        ...state,
        loading: false,
        branches: [...state.branches, action.payload],
      };
    case ADD_BRANCH_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
