import React, { useState, useEffect } from "react";
import { Formik, Field, ErrorMessage, Form as FormikForm } from "formik";
import * as Yup from "yup";
import ModalComponent from "./modalComponent";
import { useDispatch } from "react-redux";
import DOMPurify from "dompurify";
import { updateLibraryTermsAction } from "../../action/newUser/newLibraryRuleAction";
import { fetchUserDetailAction } from "../../action/newUser/newUserDetailAction";

const TermsAndConditionForm = ({ show, handleClose, terms, setShowTerms }) => {
  const [loader, setLoader] = useState(false);
  const dispatch = useDispatch(); 
  const validationSchema = Yup.object({
    content: Yup.string().required("Content is required"),
  });

  const successCbTerms = (data) => {
    dispatch(fetchUserDetailAction());
    setShowTerms(false);
    handleClose();
    setLoader(false);
  };

  const errorCb = (error) => {
    console.log(error);
    setLoader(false);
  };

  const handleCreate = (values, actions) => {
    const sanitizedContent = DOMPurify.sanitize(values.content, {
      ALLOWED_TAGS: ["b", "u", "ul", "ol", "li", "p", "br", "strong"],
      ALLOWED_ATTR: [],
    });

    setLoader(true);
    const finalValues = {
      ...values,
      content: sanitizedContent,
    };

    dispatch(updateLibraryTermsAction(finalValues, successCbTerms, errorCb));
  };

  const initialValues = {
    content: terms || "",
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleCreate}
      enableReinitialize
    >
      {({ handleSubmit, setFieldValue, values, isSubmitting }) => (
        <ModalComponent
          show={show}
          handleClose={handleClose}
          title={"Update Terms & Condition"}
          body={
            <ModalContent
              handleSubmit={handleSubmit}
              isSubmitting={isSubmitting}
              setFieldValue={setFieldValue}
              values={values}
              terms={terms}
            />
          }
          handleSubmit={handleSubmit}
          isSubmitting={loader}
        />
      )}
    </Formik>
  );
};

const ModalContent = ({ handleSubmit, setFieldValue, values, terms }) => {
  const [activeFormats, setActiveFormats] = useState({
    bold: false,
    underline: false,
    bulletList: false,
    numberList: false,
  });

  const editorRef = React.useRef(null);

  // Initialize editor content
  useEffect(() => {
    if (editorRef.current && terms?.content) {
      // Sanitize the content and set it properly in the editor
      const sanitizedContent = DOMPurify.sanitize(terms.content, {
        ALLOWED_TAGS: ["b", "u", "ul", "ol", "li", "p", "br", "strong"],
        ALLOWED_ATTR: [],
      });

      // Set the sanitized HTML content to the editor
      editorRef.current.innerHTML = sanitizedContent;

      // Also set the sanitized content in Formik field to keep the form state updated
      setFieldValue("content", sanitizedContent);
    }
  }, [terms, setFieldValue]);

  const handleFormat = (command) => {
    document.execCommand(command, false, null);

    // Update active states
    const selection = window.getSelection();
    if (selection.rangeCount > 0) {
      setActiveFormats((prev) => ({
        ...prev,
        bold: document.queryCommandState("bold"),
        underline: document.queryCommandState("underline"),
        bulletList: document.queryCommandState("insertUnorderedList"),
        numberList: document.queryCommandState("insertOrderedList"),
      }));
    }
  };

  const handleContentChange = (event) => {
    const content = event.target.innerHTML;
    setFieldValue("content", content);
  };

  return (
    <FormikForm className="row gap-3" onSubmit={handleSubmit}>
      <div className="editor-container">
        <div className="toolbar">
          <div className="toolbar-buttons">
            <button
              type="button"
              className={`format-btn ${activeFormats.bold ? "active" : ""}`}
              onClick={() => handleFormat("bold")}
              title="Bold"
            >
              <span className="material-symbols-outlined">format_bold</span>
            </button>
            <button
              type="button"
              className={`format-btn ${
                activeFormats.underline ? "active" : ""
              }`}
              onClick={() => handleFormat("underline")}
              title="Underline"
            >
              <span className="material-symbols-outlined">
                format_underlined
              </span>
            </button>
            <div className="divider"></div>
            <button
              type="button"
              className={`format-btn ${
                activeFormats.bulletList ? "active" : ""
              }`}
              onClick={() => handleFormat("insertUnorderedList")}
              title="Bullet List"
            >
              <span className="material-symbols-outlined">
                format_list_bulleted
              </span>
            </button>
            <button
              type="button"
              className={`format-btn ${
                activeFormats.numberList ? "active" : ""
              }`}
              onClick={() => handleFormat("insertOrderedList")}
              title="Number List"
            >
              <span className="material-symbols-outlined">
                format_list_numbered
              </span>
            </button>
          </div>
        </div>
        <div
          ref={editorRef}
          className="editor-content"
          contentEditable={true}
          onInput={handleContentChange}
          style={{
            minHeight: "200px",
            maxHeight: "400px",
            overflowY: "auto",
            whiteSpace: "pre-wrap",
            wordWrap: "break-word",
            cursor: "text",
            textAlign: "left",
            direction: "ltr",
            unicodeBidi: "plaintext",
            marginBottom: "10px",
            borderBottomLeftRadius: "8px",
            borderBottomRightRadius: "8px",
            border: "1px solid rgb(233, 233, 233)",
            padding: "16px",
          }}
        />
        <ErrorMessage
          name="content"
          component="div"
          className="text-danger mt-1"
        />
      </div>
    </FormikForm>
  );
};

export default TermsAndConditionForm;
