import React, { useState, useRef, useEffect } from "react";

const MessageNotificationScreen = () => {
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const messageListRef = useRef(null);
  const messageInputRef = useRef(null);

  const handleSendMessage = () => {
    if (message.trim() !== "") {
      setMessages([
        ...messages,
        { text: message, sender: "user", timestamp: new Date() },
        {
          title: "Message Notifications - Beta",
          text: "Thanks for your interest! Our new messaging feature is coming soon. Stay tuned for updates.",
          sender: "system",
          timestamp: new Date(),
        },
      ]);
      setMessage("");
      if (messageListRef.current) {
        messageListRef.current.scrollTop = messageListRef.current.scrollHeight;
      }
      if (messageInputRef.current) {
        messageInputRef.current.focus();
      }
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSendMessage();
    }
  };

  useEffect(() => {
    if (messageListRef.current) {
      messageListRef.current.scrollTop = messageListRef.current.scrollHeight;
    }
    if (messageInputRef.current) {
      messageInputRef.current.focus();
    }
  }, []);

  const formatTime = (date) => {
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    return `${hours}:${minutes}`;
  };

  return (
    <div className="message-container">
      {messages.length === 0 ? (
        <div className="coming-soon-section">
          <div className="coming-soon-content">
            <h1 className="coming-soon-title">Coming Soon</h1> 
            <p className="coming-soon-text">
            Join the first 100 to get our premium notifications!
            </p>
            <p className="coming-soon-subtext">
            Keep students updated with instant library notifications. Send updates, reminders, and alerts effortlessly.
            </p>
          </div>
        </div>
      ) : (
        <div className="message-list" ref={messageListRef}>
          {messages?.map((msg, index) => (
            <div key={index} className={`message ${msg.sender}`}>
              <div className="message-content">
                {msg.sender === "system" && (
                  <div className="message-title">{msg.title}</div>
                )}
                <div className="message-text">{msg.text}</div>
                <div className="message-time">
                  {formatTime(msg.timestamp)}
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
      <div className="message-input-area-container">
        <div className="message-input-area">
          <input
            type="text"
            className="message-input"
            placeholder="Type your message..."
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            onKeyDown={handleKeyDown}
            ref={messageInputRef}
          />
          <button
            className="send-button"
            onClick={handleSendMessage}
            disabled={message.trim() === ""}
          >
            <span className="material-symbols-outlined">send</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default MessageNotificationScreen;